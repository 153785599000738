@import "../../Variables";

.timesheet-canvas {
  height: 100%;
  width: 100%;
  position: relative;
  left: 0px;

  * {
    user-select: none;
  }

  &.is-dragging-block,
  &.is-resizing-block {
    .timesheet-block {
      transition: unset;
    }

    .timesheet-block,
    .timesheet-block * {
      pointer-events: none;
    }

    .empty-hours-container {
      display: none;
    }
  }

  .empty-hours-container {
    width: 100%;

    .empty-hour-container {
      height: 59px;
      width: calc(100% - 4px);
      cursor: pointer;
      transition: 200ms all;
      border: 1px solid $accent;
      border-radius: $borderRadius * 2;
      position: absolute;
      left: 2px;
      opacity: 0;
      display: flex;
      align-items: center;
      justify-content: center;

      .anticon {
        color: $accent;
      }

      &:hover {
        opacity: 1;
      }
    }
  }

  .clock-in-out-view {
    padding-top: 2rem;
  }
}
