@import "../../Variables";

.accessed-task-list {
  width: 22rem;
  padding: 0 0.3rem;
  padding-bottom: 0.2rem;
  padding-top: 0;
  flex-shrink: 0;
  background-color: $background;
  border-top-left-radius: $borderRadius;
  border-bottom-left-radius: $borderRadius;
  border-radius: $borderRadius;
  overflow-y: auto;
  height: 100%;
  border: 1px solid $neutral;

  @media (max-width: 1270px) {
    width: 18rem;
  }

  @media (max-width: 1175px) {
    display: none;
  }

  &.read-only {
    opacity: 0.4;
  }

  .task-id .task-id-tag {
    margin-right: 0 !important;
    top: 1px !important;
    transform-origin: 100% 50%;
    transform: scale(0.85);
  }

  .tab-container {
    z-index: 1;
    position: sticky;
    top: 0px;
    height: 2.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $background;
    padding: 0 0.3rem;

    .tab {
      font-size: 0.8rem !important;
      font-weight: 500;
      opacity: 0.6;
      border-bottom: 2px solid transparent;
      cursor: pointer;
      width: 100%;
      // background-color: $standardText;

      &.selected {
        color: $standardText;
        font-weight: 700;
        opacity: 1;
      }
    }

    .separator {
      height: calc(100% - 9px);
      width: 1px;
      background-color: $neutralDark;
      margin: 0 0.2rem;
    }
  }

  div:nth-child(2) .task-item-container {
    padding-top: 1px !important;
    .task-item {
      cursor: grab !important;
    }
  }
}
