@import "../../Variables";

.task-actions {
  border: 1px solid $bad;
  box-shadow: unset;

  .ant-card-body {
    padding-bottom: 1.5rem;
  }

  .main-label {
    font-size: 1rem;
    font-weight: 500;
    margin-bottom: 0;
  }

  .description {
    margin-bottom: 0;
  }

  button {
    width: 12rem;
  }

  .ant-btn-danger {
    background-color: transparent;
    color: #cb2431;

    border-color: rgba(27, 31, 35, 0.15);
    text-shadow: unset;

    .span {
      text-shadow: unset;
    }
  }
}
