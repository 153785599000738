@import "../Variables";

.pdf-renderer {
  position: relative;
  width: $reportWidth;
  height: 842px;
  transform-origin: 0 0;

  .react-pdf__Page__svg {
    background-color: white;
  }

  .page-scale-3 {
    canvas {
      transform: scale(0.5);
      transform-origin: 0 0;
    }
  }

  .page-scale-6 {
    canvas {
      transform: scale(0.25);
      transform-origin: 0 0;
    }
  }

  .page-scale-12 {
    canvas {
      transform: scale(0.125);
      transform-origin: 0 0;
    }
  }

  .page-placeholder {
    border: 1px solid $border;
    background-color: $white;
  }

  .page-viewer {
    position: absolute;
    left: 0;
  }

  .scrolling-pdf-page-inner-container {
    opacity: 0;
    transition: all 500ms;
    transition-delay: 500ms;

    &.loaded {
      opacity: 1;
    }
  }

  .pdf-loader,
  .pdf-error {
    position: absolute;
    top: 0;
    left: 0;
    width: $reportWidth;
    height: 842px;
    background-color: $white;
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    opacity: 0;
    pointer-events: none;
    transition: all 400ms;

    &.visible {
      transition: unset;
      opacity: 1;
    }

    .anticon {
      font-size: 3rem;
      color: $accent;
    }
  }

  .pdf-error {
    .anticon {
      color: desaturate(lighten($bad, 10), 30);
    }

    .message {
      font-size: 1.5rem;
      // color: desaturate(lighten($bad, 10), 30);
      color: $neutralDark;
      display: block;
    }
    .reason {
      font-size: 1.2rem;
      color: $neutralDark;
      // color: desaturate(darken($bad, 10), 30);
      display: block;
    }
  }

  .pagination {
    position: absolute;
    top: 2.5rem;
    z-index: 1;
    transform: translateX(-50%);
    left: 50%;
    // background-color: $white;
  }
}
