@import "../Variables";

.account-page {
  margin-right: -0.5rem;

  .account-details {
    display: flex;
    gap: 1rem;
    max-width: 40rem;
    margin: 0 auto;
    justify-content: space-between;
  }

  @media (max-width: 550px) {
    .account-details {
      flex-direction: column;
    }
  }

  .column {
    width: 100%;
  }

  .info-item {
    width: 100%;

    .ant-select {
      width: 100%;
    }
  }

  .account-page-title-card {
    .card-header {
      .ant-space-item:first-child {
        width: 100%;
        .card-title {
          width: 100%;
          justify-content: center;
        }
      }
    }
  }
}
