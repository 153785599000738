@import "../../Variables";

.create-task-status-modal {
  width: 400px !important;

  .ant-space-item {
    width: 100%;
  }

  label {
    width: 80px;
    text-align: right;
    display: inline-block;
  }

  .submit-container {
    text-align: center;
  }
}
