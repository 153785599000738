@import "../Variables.scss";

.document-output {
  .pdf-renderer {
    position: relative;
    width: $reportWidth;
    height: calc(842px + 2.3rem);
    transform-origin: 0 0;

    .react-pdf__Document {
      position: relative;

      & > div {
        background-color: unset !important;
      }
    }

    .pagination {
      background-color: rgba($hover, 1);
      width: 100%;
      border-radius: 0;
      border-bottom: 2px solid $neutral;
      position: sticky;
      top: 0;
      z-index: 1;
      left: 0;
      transform: unset;
    }
  }
}
