.dynamic-section-list {
  .field-label {
    font-weight: unset !important;

    .field-inline-description {
      font-weight: 300 !important;
    }
  }

  & > .info-item {
    margin-bottom: -1rem !important;
  }
}
