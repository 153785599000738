.form-section {
  .form-section-heading {
    .section-heading-scroll-handle {
      position: absolute;
      top: -1rem;
      left: 0;
      visibility: hidden;
    }
  }
}
