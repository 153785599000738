@import "../../Variables";

.draw-area {
  transform-origin: 0 0;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;

  &.disable-events {
    .annotation-rectangle,
    .annotation-ellipse,
    .annotation-arrow,
    .annotation-line,
    .annotation-textbox,
    .annotation-leader-line,
    .drawing {
      pointer-events: none;
      user-select: none;

      * {
        pointer-events: none;
        user-select: none;
      }
    }
  }

  .annotation-rectangle,
  .annotation-ellipse,
  .annotation-arrow,
  .annotation-textbox {
    position: absolute;
    transform-origin: 0 0;
    border: 1px solid $standardText;
    transition: unset;
    cursor: pointer;
  }

  .annotation-rectangle,
  .annotation-ellipse,
  .annotation-arrow {
    &:hover {
      border-color: $accent;
      // background-color: rgba($neutral, 0.5);
    }
  }

  .annotation-rectangle,
  .annotation-ellipse {
    border: 1px solid $accent;
  }

  .textbox-background {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: $borderRadius;
    opacity: 0.5;
  }

  .leader-line-target {
    position: absolute;
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background-color: var(--background-color);

    &::before {
      content: "";
      position: absolute;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      cursor: pointer;
      top: -2px;
      left: -2px;
      background-color: var(--background-color);
      opacity: 0.2;
    }
    &:hover {
      opacity: 1;
      &::before {
        opacity: 0.5;
      }
    }
  }

  .arrow-origin-drag-handle {
    position: absolute;
    width: 8px;
    height: 8px;
    transform: translate(-50%, -50%);
    background-color: rgba($accent, 0.5);
    top: 1px;
    left: 0.5px;
    border-radius: 50%;
    opacity: 0;
  }

  .arrow-head-drag-handle {
    position: absolute;
    width: 8px;
    height: 8px;
    transform: translate(-50%, -50%);
    background-color: rgba($accent, 0.5);
    top: 0px;
    left: -2px;
    border-radius: 50%;
    opacity: 0;
  }

  .annotation-leader-line {
    .textarea,
    .annotation-textbox {
      border-radius: $borderRadius;
    }
  }

  .annotation-textbox {
    cursor: text;
    min-width: 50px;
    border: none !important;
    border-radius: $borderRadius;

    &:hover {
      .textbox-background {
        border-color: $accent;
        background-color: rgba($neutral, 0.5);
        opacity: 1;
      }
    }

    .textarea-scroll-to-marker {
      position: absolute;
      height: 0;
      top: -20vh;
      left: 0;
    }

    .textarea {
      padding: 3px;
      background-color: rgba(white, 0.7);
      resize: none;
      transform-origin: 0 0;
      overflow: hidden;
      font-size: 5px;
      width: 100%;
      height: 100%;
      border-width: 1px;
      border-style: solid;
      border-radius: $borderRadius;

      &:focus {
        outline: 0;
      }
    }

    &.highlight {
      border: unset;

      .textarea {
        border-color: $bad !important;
        color: white !important;
        background-color: $bad !important;
      }
    }

    .resize-area {
      position: absolute;
      top: 0;
      right: 0px;
      width: 5px;
      height: 100%;
      cursor: ew-resize;
      transform-origin: 100% 0;
      z-index: 2;
    }

    .drag-area-left {
      position: absolute;
      top: 2px;
      left: -1px;
      width: 4px;
      height: calc(100% - 4px);
      cursor: grab;
      transform-origin: 0 0;
      z-index: 1;
      background-color: $bad;
      border-radius: $borderRadius;
      opacity: 0;

      &::before {
        content: "";
        position: absolute;
        top: -2px;
        left: -3px;
        width: 6px;
        height: calc(100% + 4px);
      }

      &:hover {
        opacity: 1;
      }
    }
    .drag-area-top {
      position: absolute;
      top: -1px;
      left: 0px;
      width: 100%;
      height: 5px;
      cursor: grab;
      transform-origin: 0 0;
      z-index: 2;
    }
    .drag-area-bottom {
      position: absolute;
      bottom: -3px;
      left: 0px;
      width: 100%;
      height: 5px;
      cursor: grab;
      transform-origin: 0 0;
      z-index: 2;
    }
  }

  .annotation-arrow {
    border: unset;
    transform-origin: 0 50%;

    .arrow-body {
      width: 100px;
      height: 1.5px;
      transform: scaleY(0.5);
    }

    .arrow-head {
      position: absolute;
      right: -1.5px;
      top: -2px;

      width: 0;
      height: 0;
      border-top: 3px solid transparent;
      border-bottom: 3px solid transparent;
      border-left: 3px solid $standardText;
    }

    &.selected,
    &:hover {
      .arrow-origin-drag-handle,
      .arrow-head-drag-handle {
        opacity: 1;
      }
    }
  }

  .annotation-ellipse {
    border-radius: 50%;
  }

  .drawing {
    .svg-annotation-line,
    path {
      pointer-events: all;
      cursor: pointer;

      &:hover {
        stroke: $accent;
      }
    }

    .svg-annotation-line-hit-area {
      stroke: $standardText;
      opacity: 0;
      stroke-width: 4;
      cursor: pointer;
    }

    .svg-leader-line {
      stroke-width: 1;
    }

    .line-start-drag-handle,
    .line-end-drag-handle {
      fill: rgba($accent, 0.5);
      cursor: pointer;
      opacity: 0;
      z-index: 2;
    }

    .svg-line {
      &.selected,
      &:hover {
        .line-start-drag-handle,
        .line-end-drag-handle {
          opacity: 1;
        }
      }
    }
  }

  .author-marker {
    font-size: 0.35rem;
    z-index: 1;
    position: absolute;
    bottom: -0.6rem;
    right: -0.1rem;
    color: darken($neutralDark, 20);
    background-color: rgba(white, 0.7);
    padding: 0 0.2rem;
    border-radius: 2px;
    user-select: none;
  }
}

.drawing-context-menu {
  .delete {
    color: $bad;
  }
  .resolve {
    color: $good;
  }
}

.ant-tooltip {
  &.text-options-container,
  &.annotation-options-container {
    z-index: 3 !important;

    .ant-tooltip-inner {
      background-color: $white !important;
      width: 250px;
      * {
        color: $standardText !important;
      }

      .inner-container {
        width: 100%;
      }
    }

    .ant-tooltip-arrow {
      .ant-tooltip-arrow-content {
        background-color: $white;
      }
    }

    .text-options-panel,
    .annotation-options-panel {
      padding: 0.3rem 0.3rem;
      text-align: center;

      .inner-container {
        display: inline-flex;
        flex-direction: column;
        margin: 0 auto;
      }

      .buttons-section {
        border-top: 1px solid $border;
        padding-top: 1rem;
        display: flex;
        justify-content: space-between;
      }

      .text-option-item {
        display: flex;
        align-items: center;
        margin: 0.5rem auto;
        width: 11rem;

        .label {
          margin-right: 0.5rem;
          color: $standardText;
          width: 10rem;
          text-align: left;
        }

        .ant-input-number {
          width: 80px;
        }

        .color-box {
          width: 22px;
          height: 22px;
          border: 2px solid $neutral;
          position: relative;
          border-radius: $borderRadius;
          box-sizing: border-box;
          cursor: pointer;

          &.transparent {
            &::after {
              content: "";
              width: 2px;
              height: 100%;
              background-color: $bad;
              transform: rotate(45deg);
              position: absolute;
              top: 0;
              left: 50%;
            }
          }
        }

        .twitter-picker {
          position: absolute !important;
          top: 35px;
          left: -10px;
          z-index: 1;

          input {
            display: none;
          }

          div[style*="color: rgb(152, 161, 164);"] {
            display: none !important;
          }

          div[title="#ffffff"] {
            border: 2px solid $neutral;
          }
          div[title="#123456"] {
            border: 2px solid $neutral;
            background-color: white !important;

            &::after {
              content: "";
              width: 2px;
              height: 100%;
              background-color: $bad;
              transform: rotate(45deg);
              position: absolute;
              top: 0;
              left: 50%;
            }
          }
        }
      }
    }
  }
}
