@import "../../../Variables.scss";

.message-for-form {
  border-radius: $borderRadius;
  padding: 0.3rem 0.5rem;
  border: 1px solid $neutral;
  margin-bottom: 1rem;
  margin-top: -0.5rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;

  .message-content {
    display: flex;
    gap: 0.5rem;

    .message-title {
      font-weight: bold;
      margin-bottom: -0.2rem;
    }
    .message-body {
      white-space: pre-wrap;
    }
  }
}
