@import "../../Variables.scss";

.form-text-button {
  display: block;
  margin-top: 0.2rem;
  color: $accent;
  cursor: pointer;
  font-weight: 500;
  font-size: 0.8rem;
  border: unset;
  padding: 0;
  background-color: unset;
  font-size: 0.8rem;
  font-weight: bold;
  margin-top: 0.3rem;
}
