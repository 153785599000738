@import "../../Variables.scss";

.tabs {
  margin-top: 0.5rem;

  .tabs-header {
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
    padding-bottom: 0.3rem;
    overflow-x: auto;
    overflow-y: hidden;

    .tab-titles-container {
      display: flex;
      gap: 0rem;
    }

    .tabs-bottom-border {
      height: 1px;
      background-color: $neutral;
      width: 100%;
    }

    .extra-content-right {
      margin-left: auto;
      margin-bottom: 0.3rem;
    }

    .tab-title {
      padding: 0.5rem 0.7rem;
      cursor: pointer;
      transition: all 0.2s;
      color: rgba($standardText, 0.8);
      position: relative;
      font-size: $smallFontSize;
      flex-shrink: 0;

      &::after {
        content: "";
        position: absolute;
        bottom: -1px;
        left: 0;
        width: 100%;
        height: 2px;
        background-color: transparent;
        transition: all 0.2s;
      }

      &:hover {
        color: $accent;
      }

      &.active {
        color: $standardText;
        font-weight: bold;
      }
    }
  }
}
