@import "../../Variables";

.create-supplier-modal {
  .ant-space {
    width: 100%;
  }

  .submit-container {
    padding-left: 33%;
    button {
      width: 140px;
    }
  }
}
