@import "../../Variables";

.quote-actions-container {
  flex-shrink: 0;
  background-color: $background;

  @media (max-width: 900px) {
    position: relative;
  }

  @media (max-height: 500px) {
    position: relative;
  }

  .quote-actions {
    .ant-card-body {
      padding-top: 0.3rem;
      padding-bottom: 1rem;
    }

    .actions-container {
      flex-shrink: 0;
      gap: 1rem;
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      align-items: center;
      margin-bottom: -0.7rem;
      justify-content: space-between;

      .left-buttons {
        gap: 1rem;
        display: flex;
        flex-wrap: wrap;
      }

      .download-and-send {
        display: flex;
        flex-wrap: wrap;
        gap: 1rem;
      }
    }
  }
}
