@import "../../Variables";

.unplanned-task-list {
  width: 22rem;
  padding: 0 0.3rem;
  padding-bottom: 0.2rem;
  padding-top: 0;
  flex-shrink: 0;
  background-color: $background;
  border-top-left-radius: $borderRadius;
  border-bottom-left-radius: $borderRadius;
  border-radius: $borderRadius;
  border: 1px solid $neutral;
  position: relative;
  height: calc(100vh - 107px);

  .content {
    overflow-y: auto;
    height: calc(100vh - 105px);
  }

  .toggle {
    position: absolute;
    left: 0rem;
    top: 3px;
    padding: 0.4rem 0.15rem;
    z-index: 20;
    cursor: pointer;

    .anticon {
      color: $neutralDark;
    }

    &:hover {
      background-color: $hover;
    }
  }

  &.is-closed {
    width: 1rem;
    height: calc(100vh - 107px);

    .toggle {
      left: -3px;
    }
  }

  .task-id .task-id-tag {
    margin-right: 0 !important;
    top: 1px !important;
    transform-origin: 100% 50%;
    transform: scale(0.85);
  }

  .tab-container {
    z-index: 1;
    position: sticky;
    top: 0px;
    height: 2.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $background;
    padding: 0 0.3rem;

    .tab {
      font-size: 0.8rem !important;
      font-weight: 500;
      opacity: 0.6;
      border-bottom: 2px solid transparent;
      cursor: pointer;
      width: 100%;

      &.selected {
        color: $standardText;
        font-weight: 700;
        opacity: 1;
      }
    }

    .separator {
      height: calc(100% - 9px);
      width: 1px;
      background-color: $neutralDark;
      margin: 0 0.2rem;
    }
  }

  .no-tasks-to-plan {
    background-color: $white;
    height: calc(100% - 2.5rem);
    width: 100%;
    border-radius: $borderRadius;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .party-icon {
      display: block;
      font-size: 3rem;
      color: $good;
    }

    .message {
      font-weight: 500;
      color: $good;
      font-size: 1rem;
    }
  }

  div:nth-child(2) .task-item-container {
    padding-top: 1px !important;
  }
}
