@import "../Variables.scss";

.client-contact-picker {
  display: flex;
  gap: 0.5rem;

  .add-button {
    margin-right: 2px;
  }

  .ant-select {
    width: 100%;
  }
}

.client-contact-details-popover-content {
  .info-item {
    margin-bottom: 0;
  }
}

.client-contact-details-popover-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  button {
    margin-right: -0.5rem;
  }
}
