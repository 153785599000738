@import "../../Variables";

.timesheet-block {
  position: absolute;
  left: 0;
  padding: 0.5rem 0.5rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  z-index: 1;
  flex-direction: column;
  transition: all 300ms;
  overflow: hidden;
  border: 1px solid $white;
  border-radius: $borderRadius;

  &:hover {
    z-index: 2;
  }

  .background {
    border-radius: $borderRadius;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.7;
    transition: all 300ms;
  }

  .block-drag-start,
  .block-drag-end {
    position: absolute;
    opacity: 0;
    pointer-events: none;
    left: 0;
    height: 12px;
    background-color: rgba(black, 0.3);
    width: 100%;
    transition: opacity 250ms;
    z-index: 1;
    svg {
      transform: rotate(90deg) scale(0.6) translateX(-4px);
    }
  }

  &:hover {
    .drag-handle {
      display: block;
    }

    .block-drag-start,
    .block-drag-end {
      opacity: 1;
      pointer-events: unset;
    }
  }

  .drag-handle {
    display: none;
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    height: calc(100% - 1.55rem);
    width: 100%;
    z-index: 2;
    cursor: grab;
  }

  .block-drag-start {
    top: 0px;
    cursor: n-resize;
    border-top-left-radius: $borderRadius;
    border-top-right-radius: $borderRadius;
  }

  .block-drag-end {
    bottom: 0px;
    cursor: s-resize;
    border-bottom-left-radius: $borderRadius;
    border-bottom-right-radius: $borderRadius;
  }

  .delete-button {
    position: absolute;
    right: 0.2rem;
    top: 0.2rem;
    border: unset;
    background: unset;
    box-shadow: unset;
    color: black;
    opacity: 0.7;
    z-index: 2;

    &:hover {
      background: unset;
      transform: scale(1.3);
      color: black;
      opacity: 0.7;
    }
  }

  .details {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 100%;
    width: calc(100% - 1rem);
    overflow: hidden;

    .project-and-task {
      display: flex;
      flex-direction: column;
      font-size: $extraSmallFontSize;
    }

    .project-title {
      font-weight: 500;
      color: black;
      opacity: 0.5;
      margin-bottom: -0.2rem;
      text-align: left;
      white-space: nowrap;
    }

    .task-title {
      font-weight: 500;
      color: black;
      opacity: 0.8;
      text-align: left;
    }

    .task-id {
      color: black;
      font-weight: 500;
      opacity: 0.85;
    }

    .description {
      color: black;
      opacity: 0.6;
      font-weight: 500;
      margin-top: 0rem;
      text-align: left;
      line-height: 1.5;
      font-size: $extraSmallFontSize;
    }

    .tags {
      margin-top: 0.3rem;
      display: flex;

      .ant-tag {
        font-size: 0.7rem;
        height: 1.1rem;
        display: inline-flex;
        align-items: center;
      }
      // margin-top: auto;
    }
  }

  .ant-tag {
    background-color: rgba(black, 0.5);
    color: white;
    border: unset;
    padding-left: 0.7rem;
    padding-right: 0.7rem;
  }

  .billable-tag {
    background-color: white;
    color: black;
  }

  &.compact {
    padding: 0.1rem 0.5rem;

    .details {
      .description {
        display: none;
      }

      .tags {
        margin-top: 0rem;
      }

      &.has-no-task {
        .description {
          display: block;
        }
      }
    }
  }

  &.super-compact {
    padding: 0.2rem 0.5rem;

    .project-and-task {
      gap: 0.5rem;
      flex-direction: row;

      .task-title {
        white-space: nowrap;
        overflow: hidden;
      }
    }

    .delete-button {
      top: -0.2rem;
    }

    .tags {
      display: none;
    }
  }

  @media (max-width: 600px) {
    .delete-button {
      display: none;
    }
  }
}
