@import "../../Variables";

.purchase-order-review-summary {
  .actions {
    .section {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      gap: 0.5rem;
    }
  }

  .content {
    display: flex;
    gap: 1rem;
    justify-content: space-between;
    flex-wrap: wrap;

    .review-metadata-item {
      display: flex;
      flex-direction: column;
      min-width: 12rem;
      &:nth-child(1) {
        min-width: 20rem;
      }

      .item-label {
        font-weight: 500;
      }

      .item-value {
        min-height: 2.3rem;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .ant-tag {
          display: block;

          &:not(:last-child) {
            margin-bottom: 0.5rem;
          }
        }
      }
    }
  }
}
