@import "../../../../Variables.scss";

.task-list-modal {
  width: 1300px !important;

  .task-list-container {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    margin-top: -1rem;

    .task-list-item-container {
      display: flex;
      gap: 0.5rem;
      align-items: center;
    }
  }

  .ant-spin {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-bottom: 1rem;
  }

  .task-list-item {
    box-shadow: unset !important;
    border: 1px solid $border !important;
    margin-bottom: 0;

    &:hover {
      &::after {
        display: none !important;
      }
    }
  }
}
