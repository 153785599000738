@import "../Variables";

.signature {
  position: relative;
  display: inline-block;
  user-select: none;
  transition: all 200ms;
  vertical-align: middle;

  .symbol {
    color: white;
    text-align: center;
    overflow: hidden;
    background-size: cover;
    border-radius: $borderRadius;
    display: inline-block;
    vertical-align: middle;
    display: flex;
    align-items: center;
    position: relative;
    line-height: 1.7rem;
    padding: 5px;
  }

  .signature-name {
    color: $standardText;
    display: inline-block;
    padding: 5px;
    border-radius: $borderRadius;
    height: 100%;
    line-height: 100%;
  }

  .signature-image {
    height: 100%;
    transition: all 300ms;
    background-color: #fff;
    padding: 5px;
    border-radius: $borderRadius;
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
  }

  &.large {
    .symbol {
      height: 60px;

      .signature-name {
        line-height: 60px;
      }
    }
  }

  &.normal {
    .symbol {
      height: 140px;
    }
  }

  .symbol {
    position: relative;
  }

  &.selected {
    transform: translateY(-8px) scale(1.2);
    z-index: 2;
    border-color: $white !important;
    background-color: white !important;
    color: $standardText;
    border-radius: 50%;
  }
}
