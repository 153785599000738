@import "../../Variables.scss";

.request-details-page {
  max-width: 100vw !important;
  display: flex;
  justify-content: center;

  .request-details-page-inner-content {
    width: 1600px;
    overflow-x: hidden;
    display: flex;
    gap: 0.5rem;
    padding-top: 0.5rem;
    margin-right: -0.5rem !important;
    justify-content: center;

    @media (max-width: 900px) {
      flex-direction: column;
    }
  }

  & > .ant-spin {
    height: 90vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
