@import "../../../Variables.scss";

.financials-tab {
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  .analytics-financials-chart-card {
    opacity: 0;
    animation: fadeIn 0.2s ease-in-out;
    animation-fill-mode: forwards;
    animation-delay: 0.3s;

    .actions {
      .action:not(:last-child) {
        margin-right: 1rem;
      }
    }
    &.ant-card,
    .card-title {
      color: $standardText !important;
    }
  }

  .charts-container {
    display: flex;
    gap: 2rem;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }

  @media (max-width: 1000px) {
    .charts-container {
      flex-direction: column;

      .chart-wrapper {
        min-width: unset;
        width: 100%;
      }
    }
  }

  .chart-wrapper {
    text-align: center;
    font-weight: 500;
    font-size: 1.2rem;
    min-width: 800px;
    width: calc(50% - 1rem);
    border: 1px solid $neutral;
    border-radius: $borderRadius;
    padding: 0.7rem;

    .chart {
      margin-top: 1rem;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 2rem;
      width: 100%;
    }

    .chart-title {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 1rem;
      font-size: $regularFontSize;
      margin-bottom: -0.5rem;
    }

    .chart {
      display: flex;
      position: relative;

      .preloader {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgba($white, 0.7);
        z-index: 1;
      }
    }
  }
}

.chart-simple-tooltip {
  padding: 0.5rem;
  font-size: $smallFontSize;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
