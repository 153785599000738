.invite-user-modal {
  .ant-space {
    width: 100%;
  }

  .cat-level {
    .ant-radio-wrapper {
      display: block !important;
    }

    .ant-form-item-label > label::after {
      margin-left: 5px;
    }
  }

  .submit-container {
    text-align: center;
  }
}
