@import "../Variables";

.client-logo {
  max-width: 100%;
  max-height: 100%;
  color: $standardText;
  position: relative;

  &.small {
    max-height: 2rem;
    max-width: 4rem;

    @media (max-width: 1700px) {
      max-width: 3.5rem;
    }
  }

  &.medium {
    max-height: 3.5rem;
    max-width: 7rem;
  }
}

.client-logo-link {
  display: flex;

  align-items: center;

  &.with-image {
    justify-content: center;
  }

  .client-logo.client-logo-no-image span {
    color: $accent;
    &:hover {
      opacity: 0.7;
    }
  }
}
