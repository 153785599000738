@import "../../Variables.scss";

.project-sidebar {
  margin-top: 0.55rem !important;

  .ant-card-body {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .top-buttons {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 0.5rem;

    margin-top: 1rem;
    margin-bottom: 1rem;
    justify-content: center;

    button {
      max-width: 280px !important;
      width: 100%;
      margin: 0;
    }
  }

  .info-item {
    padding-right: 0;
  }

  .action-buttons {
    margin-bottom: 0.5rem;
  }

  .client-name,
  .assigned-to-name,
  .project-name {
    font-size: 0.85rem;
    font-weight: normal;
    color: darken($neutralDark, 5);
    margin-top: 0.5rem;
    display: inline-block;
  }

  .team-picker {
    margin-bottom: 0 !important;
  }

  button.request-review,
  button.go-to-review {
    margin-bottom: 1rem;
    width: 100%;
  }

  .mark-as-finished,
  .mark-as-not-finished {
    width: 100%;
    margin-bottom: 1rem;
  }

  .mark-as-not-finished {
    margin-top: 1rem;
  }

  &.split-layout {
    .action-buttons {
      display: flex;

      button,
      a {
        width: 100%;
        margin-bottom: 0;

        &:not(:last-child) {
          margin-right: 1rem;
        }
      }
    }
  }

  .stat {
    margin-left: 0rem;
    width: calc(100% - 1rem);
    width: calc(100% + 1rem);
    display: block;
    padding-bottom: 0.5rem !important;

    &:not(:first-child) {
      padding-top: 0.5rem !important;
    }

    padding-left: 1rem;
    margin-left: -1rem;
    margin-bottom: 0.5rem;
    break-inside: avoid-column;

    &.static {
      input {
        margin-left: -0.4rem;
      }
    }

    &.clickable {
      cursor: pointer;
      width: calc(100% + 2rem);

      &:hover {
        background-color: $hover;
      }
    }

    .stat-label {
      display: flex;
      align-items: center;
      font-weight: 500;
      margin-bottom: -0.2rem;

      .anticon-copy {
        margin-left: 0.5rem;

        &:hover {
          color: $accent;
        }
      }
    }

    .stat-value {
      position: relative;

      &.stat-value-block {
        display: block;
      }

      div.input {
        margin-left: -0.6rem;
        transition: all 300ms;

        &.show-border {
          margin-left: -0.2rem;
          margin-top: 0.2rem;
        }
      }

      .ant-input-number {
        width: 100%;
        padding-left: 0.5rem;
      }

      .value-prefix {
        position: absolute;
        left: 0.5rem;
        top: -0.15rem;
        z-index: 1;
      }
      .edit-icon {
        font-size: 1rem;
      }

      .ant-input-number {
        margin-top: 0.3rem;
      }

      input.input {
        margin-left: -0.4rem;
        width: calc(100% + 0.8rem);
      }

      .ant-switch {
        margin-top: 0.5rem;
      }

      .ant-radio-group {
        margin-top: 0.3rem;
      }

      .ant-select,
      .ant-picker {
        width: 100%;
      }

      .assigned-to-picker {
        margin-left: -0.2rem;
      }

      &.created-by {
        margin-left: 0.2rem;

        .avatar {
          margin-left: -0.3rem;
          margin-right: 0.2rem;
        }
      }
    }

    .ant-picker {
      margin-left: -0.1rem;
      margin-top: 0.2rem;
    }
  }

  .stat-label {
    .ant-checkbox-wrapper {
      border: 1px solid transparent;
      padding: 0.3rem 0.5rem;

      .ant-checkbox {
        &::after {
          border-color: darken($accent, 10);
        }
      }

      .ant-checkbox-checked {
        .ant-checkbox-inner {
          background-color: $accent;
          border-color: $accent;
        }

        & + span {
          color: darken($accent, 10);
          font-weight: 500;
        }
      }
    }
  }
  .stat-value {
    .ant-checkbox-group {
      margin-top: 0.2rem;
    }
  }

  .created-by-container {
    .avatar {
      top: 5px;
    }
  }
}

.cannot-finish-project-modal {
  width: 600px !important;

  .cannot-finish-project-modal-content {
    max-height: 400px;
    overflow-y: auto;
  }
}
