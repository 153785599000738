@import "../../Variables";

.target-date {
  display: flex;
  gap: 0rem;
  align-items: center;
  margin-left: auto;

  .target-date-label {
    font-weight: 500;
    font-size: $largeFontSize;
    width: 8.5rem;
    letter-spacing: -0.5px;
  }
}
