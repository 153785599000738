@import "../../Variables";

.notification-settings {
  margin-top: 1rem !important;

  .notification-class {
    width: 400px;
    max-width: 100%;
    margin: 0 auto;
    margin-bottom: 2.5rem;

    .setting {
      display: flex;
      justify-content: space-between;
      margin-bottom: 0.8rem;

      &.is-title {
        border-bottom: 1px solid $neutral;
        padding-bottom: 0.5rem;
        margin-bottom: 0.5rem;

        .label {
          font-weight: 500;
          font-size: 0.9rem;
        }
      }
    }
  }
}
