@import "../Variables";

.file-details-page {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-right: 0.5rem;

  &.no-preview {
    max-width: 900px;
    margin: 0 auto;
  }

  .inner-page-content {
    display: flex;
    gap: 0.5rem;

    & > .main-content {
      height: 100%;
      display: flex;
      flex-direction: column;
      overflow-y: auto;
      width: 100%;
      padding-bottom: 0.5rem;
    }
  }

  .ant-alert-error {
    margin-bottom: 0.5rem;
  }

  .card {
    .card-header {
      padding-bottom: 1rem;

      & > .ant-divider {
        display: none;
      }

      .ant-space {
        flex-wrap: wrap;
        .ant-space-item {
          display: flex;
          .card-title,
          .card-actions {
            display: flex;
            flex-wrap: wrap;
          }
        }
      }
    }

    .ant-card-body {
      display: flex;
      flex-direction: column;

      .tabs {
        height: 100%;
        display: flex;
        border-top: 1px solid $neutral;

        .sheet-names {
          width: 15rem;
          flex-shrink: 0;
          text-align: center;
          border-right: 1px solid $neutral;
          overflow-y: auto;
          display: flex;
          flex-direction: column;

          .edit-sheets,
          .add-sheet {
            margin: 0.5rem;
          }
        }
      }
    }

    .sheet-names-mobile {
      display: none;
    }

    @media (max-width: $mobileBreakpoint) {
      .sheet-names-desktop {
        display: none !important;
      }
      .sheet-names-mobile {
        display: flex;
      }
    }

    .sheet-names-mobile {
      gap: 0.5rem;
      flex-direction: column;
      width: 100%;
      max-width: 400px;

      button {
        max-width: unset;
      }

      .sheet-list-item {
        border: 1px solid $border;
        border-radius: $borderRadius;
        width: 100%;
        text-align: center;
        align-items: center;

        &.selected {
          &::after {
            display: none;
            // content: "";
            // position: absolute;
            // top: 100%;
            // left: 0;
            // z-index: 2;
            // width: 100%;
            // height: 2px;
            // background-color: $accent;
          }
        }
      }
    }
  }

  .sheets {
    .tab-title-bar {
      margin-top: 1rem;
      margin-bottom: 1rem;
    }
    .card-header > .ant-space {
      justify-content: center;

      & > .ant-space-item {
        width: 100%;

        .card-title {
          width: 100%;
          justify-content: center;
        }
      }
    }
  }
}

.ant-dropdown-menu {
  .file-version-item {
    color: darken($neutralDark, 20) !important;
    font-family: Arial;
    font-size: 0.8rem;

    .size {
      margin-left: 2rem;
      color: darken($neutralDark, 20) !important;
    }
  }
}

.sheet-diff-alert,
.sheet-diff-resolution-options .ant-dropdown-menu-item {
  .alert-title {
    font-size: 1rem;
    margin-bottom: 1rem;
  }

  .card-title {
    color: $bad;
  }

  table {
    max-width: 70rem;
    margin: 0 auto;
    margin-bottom: 2rem;
  }

  .sheet-item {
    padding: 0.2rem;

    &.missing {
      background-color: rgba($bad, 0.2);
      color: $bad;
      font-weight: 700;
    }

    &.closest-match {
      background-color: rgba($accent, 0.2);
      color: $accent;
      font-weight: 700;
    }
  }
}
