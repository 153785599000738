@import "../../Variables";

.timeline-block {
  z-index: 2;

  &.is-on-top {
    z-index: 3 !important;
    opacity: 0.7 !important;
  }

  &.is-resizing {
    z-index: 3;
  }

  &.is-cypress {
    .block-drag-start,
    .block-drag-end,
    .drag-handle {
      pointer-events: all !important;
    }
  }

  &.is-interactive {
    pointer-events: all;
  }

  &.not-interactive {
    pointer-events: none;

    .drag-handle,
    .block-drag-start,
    .block-drag-end,
    .drag-to-copy-marker-down {
      display: none;
    }
  }

  &.is-renaming {
    .drag-handle,
    .block-drag-start,
    .block-drag-end,
    .drag-to-copy-marker-down {
      display: none;
    }

    &.pseudo-task {
      .background {
        background-color: saturate(darken($standardText, 0), 100) !important;
        background-color: $white !important;
        border: 1px solid $neutralDark;
      }
    }
  }

  &.is-restricted {
    &.PENDING {
      .background {
        opacity: 0.4;
      }
    }
  }

  &.is-out-of-working-hours {
    height: calc(100% + 0px) !important;
    top: 0 !important;
    animation-duration: 0ms !important;

    .empty-background {
      background-color: $hover !important;
      width: calc(100% - 1px) !important;
      left: 1px !important;
      z-index: -1;
      height: 100%;
      top: 0;
      position: absolute;
    }
  }

  &.is-fixed {
    .background {
      border: 2px solid $standardText;
    }

    .fixed-block-icon {
      position: absolute;
      top: -13px;
      right: -1px;
      font-size: 0.8rem;
      color: $standardText;
      z-index: 5;
    }

    .project-title {
      width: calc(100% - 16px);
      transform: translateX(0);
      left: 0;
    }
  }

  .drag-to-copy-marker-down,
  .drag-to-copy-marker-up {
    top: unset !important;
    bottom: 0;
    left: 0.75rem !important;
    z-index: 2 !important;
    width: calc(100% - 1.5rem + 1px) !important;
    height: 0.75rem !important;
    cursor: s-resize !important;
    pointer-events: all;

    .icon {
      transform: rotate(90deg) translate(-50%, -50%) !important;
      transform-origin: 50% 50% !important;
      top: 0% !important;
      left: calc(50% - 0.6rem) !important;
    }
  }

  .drag-to-copy-marker-up {
    top: 0 !important;
    bottom: unset !important;
    cursor: n-resize !important;
  }

  .drag-to-copy-box {
    position: absolute;
    left: 0;
    width: 100%;
    border: 1px dashed $accent;
    border-radius: $borderRadius * 2;
    pointer-events: none;
    transition: all 300ms;
  }

  .input.task-id {
    border: unset;
    border-radius: 0;
    color: $neutralDark !important;
    width: calc(100% - 1rem);
    margin: 0 auto;
    background-color: unset !important;

    input {
      text-align: center !important;
      font-weight: 500;
    }

    &:hover,
    &:focus {
      background-color: rgba($white, 0.1);
    }
  }
}

.timeline-block {
  position: absolute;
  height: calc(var(--timeline-day-cell-height) - 1rem);
  color: $white;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  overflow: visible;
  bottom: 1px;
  opacity: 1;
  transform: scale(1);
  transition: all $transition;
  transform-origin: 50% 50%;

  &.disable-events {
    pointer-events: none;
  }

  &.new {
    opacity: 0;
    transform-origin: 0 50%;
    transform: scaleX(0);
    animation-name: block-appear;
    animation-duration: $transition;
    animation-fill-mode: forwards;
    animation-iteration-count: 1;
  }

  &.deleted {
    opacity: 1;
    transform: scale(1);
    animation-name: block-disappear;
    animation-duration: $transition;
    animation-fill-mode: forwards;
    animation-iteration-count: 1;
  }

  .background {
    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: $borderRadius * 2;
      opacity: 0.3;
      display: none;
    }
  }

  &.unconfirmed {
    .background {
      background-color: #ccc !important;
    }
  }

  &.default {
    .background {
      background-color: $accent;
    }
  }

  &.under-review {
    .background {
      &::after {
        background: repeating-linear-gradient(-55deg, #aaa, #aaa 8px, #ddd 8px, #ddd 16px);
        background-size: 200% 100%;
        background-position-x: -50px;
        display: block;
      }
    }
  }

  &.overdue {
    .background {
      background-color: $bad;
    }
  }

  &.due-on-the-day {
    .background {
      background-color: #8b60f1;
    }
  }

  &.archived {
    .background {
      background-color: #ddd;
    }
  }

  &.finished {
    .background {
      background-color: $good;
    }
  }

  &.pseudo-task {
    // top: 0;
    // height: calc(var(--timeline-day-cell-height) - 1px);

    .background {
      // background-color: saturate(darken($standardText, 0), 100) !important;
      background-color: saturate(darken($standardText, 0), 100);
    }
  }

  &.old {
    .task-title,
    .approval-status {
      opacity: 0.3 !important;
    }

    .background {
      opacity: 0.4;
    }
  }

  @keyframes block-appear {
    from {
      transform: scaleX(0);
      opacity: 0;
    }

    to {
      transform: scaleX(1);
      opacity: 1;
    }
  }

  @keyframes block-disappear {
    from {
      transform: scale(1);
      opacity: 1;
    }

    to {
      transform: scale(0);
      opacity: 0;
    }
  }

  .background {
    z-index: -1;
    content: "";
    background-color: $accent;
    border-radius: $borderRadius * 2;
    width: calc(100% - 1px);
    height: 100%;
    top: 0;
    left: 1px;
    position: absolute;
  }

  .block-drag-start,
  .block-drag-end,
  .drag-to-copy-marker-down,
  .drag-to-copy-marker-up {
    opacity: 0;
    position: absolute;
    top: 0;
    height: 100%;
    width: 0.7rem;

    background-color: rgba(black, 0.3);
    transition: opacity var(--timeline-transition-duration);
    z-index: 1;
    transition: all $transition;

    .icon {
      position: absolute;
      transform: translate(-50%, -50%);
      width: 4px;
      top: 50%;
      left: 50%;
    }
  }

  .block-drag-start {
    left: 1px;
    cursor: w-resize;
    border-top-left-radius: $borderRadius * 2;
    border-bottom-left-radius: $borderRadius * 2;
  }

  .block-drag-end {
    right: 0px;
    cursor: e-resize;
    border-top-right-radius: $borderRadius * 2;
    border-bottom-right-radius: $borderRadius * 2;
  }

  .project-title,
  .task-id-and-title {
    display: block;
    word-break: keep-all;
    white-space: nowrap;
    font-size: 0.7rem;
    font-weight: 500;
    position: absolute;
    transform: translateX(-50%);
    left: 50%;
    overflow: hidden;
  }

  .project-title {
    width: calc(100% - 8px);
    color: desaturate(lighten($standardText, 30), 80);
    top: -0.8rem;
    line-height: 1;
    pointer-events: none;
    font-size: calc(var(--timeline-day-cell-task-id) / 1);
    transition: all $transition;
    overflow-x: hidden;
    background-color: $white;
  }

  .drag-handle {
    opacity: 0;
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    width: calc(100% - 2.2rem);
    height: 100%;
    z-index: 2;
    cursor: pointer;
  }

  .task-id-and-title {
    top: 50%;
    white-space: normal;
    display: block;
    height: 100%;
    width: calc(100% - 0.3rem);
    transform: translate(-50%, -50%);
    top: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .task-id {
      white-space: nowrap;
      display: block;
      color: $white;
      font-size: var(--timeline-day-cell-task-id);
      transition: all $transition;

      &.small {
        white-space: nowrap;
        position: relative;
        top: 0.03rem;
        overflow: hidden;
        width: 100%;
      }
    }

    .task-title,
    .approval-status {
      line-height: 0.7rem;
      display: block;
      max-height: 1.5rem;
      font-size: calc(var(--timeline-day-cell-task-id) / 1);
      transition: all $transition;
      overflow: hidden;
      opacity: 0.7;
      color: black;

      &.small {
        white-space: nowrap;
        position: relative;
        top: 0.03rem;
        overflow: hidden;
        width: 100%;
      }
    }

    .approval-status {
      text-transform: lowercase;
      color: $white;
      position: relative;
      top: -1px;
      line-height: 0.8rem;
    }
  }

  &:hover {
    padding: 0 0.5rem;

    .drag-handle {
      opacity: 1;
      display: block;
    }

    .block-drag-start,
    .block-drag-end,
    .drag-to-copy-marker-down,
    .drag-to-copy-marker-up {
      opacity: 1;
    }
  }

  &.without-project-title {
    height: calc(100% - 2px);
  }
}

.timeline-block-color-modal {
  width: 300px !important;

  .color-box {
    width: 22px;
    height: 22px;
    border: 2px solid $neutral;
    position: relative;
    border-radius: $borderRadius;
    box-sizing: border-box;
    cursor: pointer;

    &.transparent {
      &::after {
        content: "";
        width: 2px;
        height: 100%;
        background-color: $bad;
        transform: rotate(45deg);
        position: absolute;
        top: 0;
        left: 50%;
      }
    }
  }
  .twitter-picker {
    position: absolute !important;
    top: 85px;
    left: 97px;
    z-index: 1;

    input {
      display: none;
    }

    div[style*="color: rgb(152, 161, 164);"] {
      display: none !important;
    }

    div[title="#ffffff"] {
      border: 2px solid $neutral;
    }
    div[title="#123456"] {
      border: 2px solid $neutral;
      background-color: white !important;

      &::after {
        content: "";
        width: 2px;
        height: 100%;
        background-color: $bad;
        transform: rotate(45deg);
        position: absolute;
        top: 0;
        left: 50%;
      }
    }
  }
}

.timeline-block-tooltip {
  z-index: 4 !important;

  .ant-tooltip-arrow {
    &::before {
      background-color: $white;
    }
  }

  .ant-tooltip-inner {
    background-color: $white !important;
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 0.2rem;
    align-items: center;
    width: 10rem;

    * {
      color: $standardText !important;
      word-break: break-word;
    }

    .menu-section,
    .info-section {
      * {
        color: $standardText !important;
      }
    }

    .ant-tag {
      background-color: $standardText !important;
      color: $white !important;
    }

    .info-section {
      margin-top: 1rem;
      display: flex;
      flex-direction: column;
      gap: 0.3rem;
    }

    .menu-section {
      display: flex;
      flex-direction: column;
      align-items: center;

      .menu-item {
        border-bottom: 1px solid $border;
        padding: 0.4rem 0.5rem;
        width: 100%;
        cursor: pointer;
        font-weight: 500;
        display: flex;
        justify-content: flex-start;
        gap: 0.5rem;

        .anticon {
          font-size: 0.6rem;
        }

        &:hover {
          background-color: $hover;
        }
      }
    }
  }
}
