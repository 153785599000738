@import "../Variables";

.button-dark {
  background-color: $standardText;
  border-color: $standardText !important;
  color: $white !important;

  .icon {
    fill: $white !important;
  }

  &:hover,
  &:active,
  &:focus {
    background-color: lighten($standardText, 10);
    border-color: lighten($standardText, 0);
    color: $white;
  }
}
