@import "../Variables";

.address-list {
  &.with-border {
    border: 1px solid $neutral;
    margin-top: 2rem;
    box-shadow: unset;
  }

  .address {
    display: block;
    border: 1px solid $neutral;
    width: 100%;
    position: relative;
    align-items: center;
    padding: 0.3rem 1rem;
    cursor: pointer;
    background-color: $white;
    margin-bottom: 1rem;
    border-radius: $borderRadius;

    .address-id {
      color: $neutralDark;
      text-align: center;
      font-weight: 500;
      display: block;
      width: 100%;
      border-bottom: 1px solid $neutral;
      margin-bottom: 0.5rem;
      padding-bottom: 0.5rem;
      padding-top: 0.3rem;
    }

    .address-detail-item {
      display: block;

      .address-detail-item-label {
        font-weight: 500;
        margin-right: 0.5rem;
        width: 150px;
        text-align: right;
        display: inline-block;
      }
    }

    .type {
      font-size: 0.7rem;
      font-weight: 500;
      color: darken($neutralDark, 15);
      text-transform: uppercase;
      width: 5rem;
      text-align: center;
      position: relative;
      top: 1px;
      margin-right: 1rem;
    }

    &:hover {
      background-color: $hover;
    }

    .delete-address {
      border: unset;
      background: unset;
      box-shadow: unset;
      margin-left: auto;
      position: absolute;
      top: 0.3rem;
      right: 1rem;
    }

    .edit-address {
      border: unset;
      background: unset;
      box-shadow: unset;
      margin-left: auto;
      position: absolute;
      top: 0.3rem;
      right: 3rem;
    }
  }
}
