@import "../../Variables";

.review-sheet-toolbar {
  width: 100%;
  flex-shrink: 0;
  display: flex;
  flex-wrap: wrap;
  background-size: 45px 35px;
  background-position: -1px -1px;
  border-bottom: 1px solid $neutral;
  background-color: $white;
  position: relative;
  z-index: 1;

  .tool {
    height: 35px;
    font-size: 15px;
    text-align: center;
    background-color: transparent;
    transition: all 200ms;
    width: 45px;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: $standardText;
    position: relative;
    user-select: none;

    &.disabled {
      cursor: no-drop;
      pointer-events: none;
      opacity: 0.3;
    }

    .icon {
      border: 1px solid $standardText;
      margin: auto;
      transition: all 200ms;

      &.icon-page-scroll {
        border: unset;
        fill: $standardText;
      }
    }

    .icon-diff {
      border: unset;
      fill: $standardText;
      width: 20px;
      height: 20px;
    }

    .icon-rectangle {
      width: 20px;
      height: 12px;
    }

    .ant-typography {
      font-weight: 500;
    }

    .icon-line {
      height: 17px;
      width: 0;
      box-sizing: border-box;
      border-left: unset;
      position: relative;
      left: -1px;
      transform: rotate(45deg);
      background-color: $standardText;
    }

    .icon-ellipse {
      width: 20px;
      height: 12px;
      border-radius: 50%;
    }

    &:hover {
      background-color: $hover;
      cursor: pointer;
      font-size: 22px;

      .icon {
        transform: scale(1.4);
      }
    }

    &.active {
      background-color: $standardText;
      color: $white;

      .ant-typography {
        color: $white;
      }

      .icon {
        border-color: $white;
        transform: scale(1.2);

        &.icon-page-scroll {
          fill: $white;
          transform: unset;
        }
      }

      &:hover {
        background-color: lighten($standardText, 10);

        .icon {
          transform: scale(1.4);
        }
      }
    }
  }
}
