@import "../../Variables";

.form-field-modal {
  width: 1000px !important;
  max-width: 90vw !important;

  .ant-form-item {
    margin-bottom: 1rem;
  }

  .submit-container {
    padding-left: 33%;
  }
}
