@import "../Variables";

.project-details-page {
  padding: 0.5rem;
  padding-top: 0.5rem;

  .client-contacts-list {
    .ant-checkbox-wrapper + .ant-checkbox-wrapper {
      margin-left: 0;
    }

    .client-contact-id {
      font-weight: bold !important;
    }

    .client-contact-checkbox:not(:first-child) {
      margin-top: 0.5rem;
    }
  }

  .get-share-link {
    background-color: white;
    padding: 0.5rem;
    border-radius: $borderRadius;
    margin-top: -0.5rem;
    display: block;
    padding-left: 1rem;
    text-align: center;

    button {
      margin-left: 1rem;
    }

    @include with-shadow;
  }

  .ant-alert {
    margin-bottom: 0;
  }

  .project-main-tabs {
    margin-top: -1rem;

    .ant-tabs-nav-list {
      margin-left: 0.1rem;
    }
  }

  .project-main-tabs > .ant-tabs-nav > .ant-tabs-nav-wrap > .ant-tabs-nav-list > button {
    font-size: 1rem;
  }

  .page-content {
    display: flex;

    & > div:not(:last-child) {
      margin-right: 0.5rem;
    }
  }

  .financials-content {
    display: flex;
    gap: 1rem;
  }

  @media (max-width: 2050px) {
    .financials-content {
      flex-direction: column;
      gap: 0;

      .project-budget-card {
        .info-items-column {
          justify-content: flex-start;
          gap: 3rem;
          .info-item {
            width: 200px;
          }
        }
      }
    }
  }

  @media (max-width: 600px) {
    .ant-timeline-item-label,
    .ant-timeline-item-tail,
    .ant-timeline-item-head {
      display: none;
    }
    .ant-timeline-item-content {
      margin-left: 0;
    }

    .financials-content {
      .project-budget-card {
        .info-items-column {
          flex-direction: column;
          justify-content: flex-start;
          gap: 0rem;
        }
      }
    }
  }
}
