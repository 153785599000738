@import "../../Variables";

.upload-progress-view {
  padding-top: 2rem;

  .overall-progress {
    text-align: center;

    &.warning {
      .anticon {
        svg * {
          color: $color4;
        }
      }
    }

    .message {
      margin-top: 0.5rem;
      font-size: 1rem;
      display: block;
      font-weight: 500;
      margin-bottom: 1rem;
      // padding-bottom: 1rem;

      color: $accent;

      &.success {
        color: $good;
      }

      &.warning {
        color: $color4;
      }

      &.exception {
        color: $bad;
      }
    }
  }

  .pending-file-item {
    display: flex;
    justify-content: space-between;
    padding-right: 1rem;
    padding: 0.3rem 0;
    padding-right: 1rem;
    font-weight: 500;

    &:not(:last-child) {
      border-bottom: 1px dashed $neutral;
    }

    .progress-bar {
      width: 200px;
    }
  }
}
