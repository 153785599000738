@import "../../../../Variables.scss";

.custom-field-list {
  .custom-field {
    display: block;
    border: 1px solid $neutral;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0.3rem 1rem;
    cursor: pointer;

    .name {
      text-align: left;
    }

    .type {
      font-size: 0.7rem;
      font-weight: 500;
      text-transform: uppercase;
      width: 10rem;
      text-align: center;
      position: relative;
      top: 1px;
      margin-right: 1rem;
    }

    &:hover {
      background-color: $hover;
    }

    &:not(:last-child) {
      margin-bottom: -1px;
    }

    .custom-field-actions-wrapper {
      display: flex;
      justify-content: right;
      align-content: right;
      margin-left: auto;
    }

    .edit-custom-field {
      border: unset;
      background: unset;
      box-shadow: unset;
    }

    .delete-custom-field {
      border: unset;
      background: unset;
      box-shadow: unset;
    }
  }
}
