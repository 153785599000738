@import "../Variables";

.dashboard-item-tags {
  display: block;
  flex-wrap: wrap;
  max-width: 100%;
  display: flex;
  margin: 0;
  align-items: center;

  @keyframes background-slide {
    0% {
      background-position-x: -30px;
    }
    100% {
      background-position-x: 0px;
    }
  }

  .ant-tag {
    padding: 0rem 0.5rem;
    font-size: 0.65rem;
    letter-spacing: 0.3px;
    margin-bottom: 0.2rem;
    border-radius: $borderRadius;
    position: relative;

    // &.tag-under-review {
    //   display: block;

    //   &::after {
    //     content: "";
    //     position: absolute;
    //     background: repeating-linear-gradient(-55deg, #888, #888 6px, #fff 6px, #fff 12px);
    //     background-size: 200% 100%;
    //     background-position-x: -50px;
    //     display: block;
    //     animation-name: background-slide;
    //     animation-duration: 1200ms;
    //     animation-iteration-count: infinite;
    //     animation-timing-function: linear;
    //     top: 0;
    //     left: 0;
    //     width: 100%;
    //     height: 100%;
    //     border-radius: $borderRadius;
    //     opacity: 0.1;
    //   }
    // }
  }

  a {
    height: 22px;
  }

  a > .ant-tag {
    position: relative;
    top: -1px;
  }

  &.empty {
    margin-top: 0;
    display: none;
  }
}
