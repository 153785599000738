@import "../Variables";

html body,
#root {
  @media (max-width: $mobileBreakpoint) {
    height: 100vh;
    overflow-y: auto;
    position: fixed;
    width: 100vw;
    background-color: $mainBarBaseColor !important;
  }
}

.environment-name-display {
  position: fixed;
  bottom: 5px;
  left: 5px;
  // font-size: 30px;
  color: white;
  z-index: 100;
  background-color: $standardText;
  padding: 0.2rem 0.7rem;
  border-radius: $borderRadius;
  font-weight: bold;
  font-size: 0.8rem;
}

.app {
  text-align: center;
  display: flex;
  flex-direction: column;
  height: 100vh;

  @media (max-width: $mobileBreakpoint) {
    .page-content {
      background-color: $white;
    }
  }

  &.with-background {
    background-color: $background;

    .page-content {
      background-color: $background;
    }
  }

  &.no-scroll {
    overflow-y: hidden;
    height: 100vh;

    .page-content > .inner-content {
      overflow-y: hidden;
      margin-right: 0;
      margin-top: 0;
    }
  }

  .page-content {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    display: flex;
    overflow-y: hidden;

    .inner-content {
      width: 100%;
      height: 100%;
      max-width: 100vw;
      overflow-x: hidden;
      overflow-y: auto;

      & > div {
        padding-left: 0.5rem;
      }
    }
  }

  &.logged-out {
    overflow-y: unset;
    height: unset;
    min-height: 100vh;

    .page-content {
      height: unset;
      overflow-y: unset;
    }
  }

  &.with-boxed-layout {
    .inner-content {
      padding-top: 0.5rem;
      padding-bottom: 1rem;
      padding-right: 1rem;

      & > div:first-child {
        max-width: 1300px;
        margin-left: auto;
        margin-right: auto;
      }
    }
  }

  &.with-right-margin {
    .inner-content {
      margin-right: 0.3rem;
    }
  }
}
