@import "../Variables";

.create-project-modal {
  width: 700px !important;
  max-width: 100vw !important;

  .loading-button {
    width: 75px;
  }
}
