@import "../Variables.scss";

.sprint-picker {
  width: 300px !important;
}

.ant-select-item-option-content,
.ant-select-selection-item {
  display: flex !important;
  justify-content: space-between !important;
}
