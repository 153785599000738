@import "../../Variables.scss";

.project-picker-form {
  display: flex;
  gap: 0.5rem;
  align-items: center;

  .project-selector {
    width: 100%;
  }
}
