@import "../Variables.scss";

.analytics-page {
  color: $standardText !important;

  &.hide-header {
    .tabs-header,
    .page-actions {
      display: none;
    }
  }
}

.organisation-details {
  display: flex;
  justify-content: space-between;

  .ant-card {
    flex-basis: 24%;
  }

  .organisation-details-list-item {
    display: flex;
    justify-content: space-between;

    .organisation-detail-list-item-amount {
      font-weight: 500;
    }
  }
}
