@import "../../Variables";

.quote-review-summary {
  & > .ant-card-body > .card-header {
    margin-bottom: -0.7rem;

    .ant-space {
      display: flex;
      flex-wrap: wrap !important;

      .card-actions .actions .section {
        display: flex;
        gap: 0.5rem;
        flex-wrap: wrap;
      }

      button {
        display: inline-block;
        margin-left: 0;
      }
    }
  }
  .content {
    display: flex;
    gap: 1rem;
    justify-content: space-between;
    flex-wrap: wrap;

    .review-metadata-item {
      display: flex;
      flex-direction: column;
      min-width: 12rem;
      &:nth-child(1) {
        min-width: 20rem;
      }

      .item-label {
        font-weight: 500;
      }

      .item-value {
        min-height: 2.3rem;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .ant-tag {
          display: block;

          &:not(:last-child) {
            margin-bottom: 0.5rem;
          }
        }
      }
    }
  }
}
