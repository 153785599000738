@import "../../Variables.scss";

.timeline-legend {
  display: flex;
  gap: 1rem;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  height: 50px;
  padding-top: 0.5rem;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;

  .timeline-legend-item {
    display: flex;
    gap: 0.3rem;
    align-items: center;

    .timeline-legend-item-label {
      font-size: $smallFontSize;
    }

    .timeline-legend-item-color {
      width: 1.2rem;
      height: 1.2rem;
      border-radius: $borderRadius;
      position: relative;

      &.unconfirmed {
        background-color: #ccc !important;
      }

      &.default {
        background-color: $accent;
      }

      &.under-review {
        &::after {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          opacity: 0.3;

          border-radius: $borderRadius;
          background: repeating-linear-gradient(-55deg, #aaa, #aaa 4px, #ddd 4px, #ddd 8px);
          background-size: 200% 100%;
          background-position-x: -50px;
          display: block;
        }
      }

      &.overdue {
        background-color: $bad;
      }

      &.due-on-the-day {
        background-color: #8b60f1;
      }

      &.archived {
        background-color: #ddd;
      }

      &.finished {
        background-color: $good;
      }
    }
  }

  @media (max-width: 768px) {
    gap: 0.5rem;

    .timeline-legend-item {
      gap: 0.1rem;

      .timeline-legend-item-label {
        font-size: $extraSmallFontSize;
      }

      .timeline-legend-item-color {
        width: 1rem;
        height: 1rem;
      }
    }
  }

  @media (max-width: 370px) {
    display: none;
  }
}
