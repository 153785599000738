@import "../Variables.scss";

.timesheet-blocks-table {
  tbody {
    tr {
      cursor: pointer;

      .ant-checkbox-wrapper {
        justify-content: center;
      }
    }
  }

  .timesheet-block-tags {
    margin-top: 0.2rem;
    .ant-tag {
      margin-bottom: 0.2rem;
    }
  }
}
