@import "../../Variables";

.create-custom-label-modal {
  width: 400px !important;

  .ant-space-item {
    width: 100%;
  }

  .submit-container {
    text-align: center;
  }
}
