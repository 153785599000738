.quickbooks-tab {
  .quickbooks-status {
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
  }

  .quickbooks-actions {
    display: flex;
    gap: 1rem;
  }
}
