$accent: #19aae8;
$bad: #ff4d4f;
$good: #4cc23a;
$color4: #ffbc0d;

$standardText: #002f44;
$lightText: #2f94bd;
$white: #ffffff;

$neutralLight: #e7f1f5;
$neutral: darken($neutralLight, 6);
$neutralDark: desaturate(darken($neutralLight, 25), 20);

$background: lighten(#e0eef4, 0);
$hover: #edf4f6;
$border: #e7f1f5;
$borderDark: $neutral;

$mainBarBaseColor: darken($accent, 37);
$mainBarAccentColor: desaturate(lighten($mainBarBaseColor, 8), 20);

$borderRadius: 4px;

$boxedLayoutMaxWidth: 1400px;
$mobileBreakpoint: 850px;
$navigationBarHeight: 49px;
$reportWidth: 596px;

$reviewTopBarHeight: 6.5rem;
$reviewPageMobileBreakpoint: 1100px;
$reviewPageMobileNavigationBarHeight: 3.5rem;

@mixin with-shadow($shadowColor: saturate(lighten($neutralDark, 2), 10), $size: 2px) {
  box-shadow: 0px 1px $size $shadowColor;
}

@mixin with-soft-shadow {
  box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
}

$extraSmallFontSize: 12px;
$smallFontSize: 13px;
$regularFontSize: 16px;
$largeFontSize: 20px;
$extraLargeFontSize: 24px;

$fontFamily: "Inter", sans-serif;

$transition-duration: 300ms;
$transition-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
// $transition: $transition-duration $transition-timing-function;
$transition: unset;
