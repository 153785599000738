@import "../Variables";

.user-timesheet-page {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  height: 100%;
  padding-bottom: 0.5rem;
  padding-right: 0.5rem;

  $hoursColumnWidth: 3rem;
  $plannedColumnWidth: 15rem;
  $plannedColumnWidthSmall: 10rem;
  $hourHeight: 4rem;
  $recordedColumnWidth: 250px;

  .filter-bar {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    flex-wrap: wrap;
    justify-content: flex-end;

    .task-filters {
      padding-top: 1.1rem;
      width: unset;
    }

    .ant-picker {
      width: 12.5rem;
    }
  }

  .content {
    display: flex;
    gap: 0.5rem;
    height: 1px;
    flex-grow: 1;
    justify-content: space-between;

    .timesheet-container {
      width: 100%;
      height: 100%;
      border: 1px solid $neutral;
      border-radius: $borderRadius;
      display: flex;
      flex-direction: column;
      position: relative;

      .header {
        height: 3rem;
        display: flex;
        width: 100%;
        align-items: center;
        font-weight: 500;
        position: sticky;
        top: 0;
        z-index: 3;

        &.uses-review {
          height: 5rem;
        }

        .review-status {
          display: flex;
          align-items: center;
        }

        .hours-header {
          width: $hoursColumnWidth;
          flex-shrink: 0;
          text-align: center;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          height: 100%;
          border-right: 1px solid $neutral;
          background-color: $hover;
          border-bottom: 1px solid $neutral;
          border-top-left-radius: $borderRadius;
        }

        .recorded-header {
          width: 1px;
          flex-grow: 1;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          height: 100%;
          min-width: $recordedColumnWidth;
          border-right: 1px solid $neutral;
          background-color: $hover;
          border-bottom: 1px solid $neutral;
        }

        .planned-header {
          width: $plannedColumnWidth;
          flex-shrink: 0;
          text-align: center;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          height: 100%;
          background-color: $hover;
          border-bottom: 1px solid $neutral;
        }
      }

      .canvas {
        height: 1px;
        flex-grow: 1;
        overflow-y: auto;

        .lunch-break {
          position: absolute;
          left: 0;
          width: 100%;
          background-color: $hover;
          opacity: 1;
        }

        .scrollable-container {
          display: flex;
          position: relative;
        }

        .hours {
          width: $hoursColumnWidth;
          flex-shrink: 0;
          text-align: center;
          display: flex;
          flex-direction: column;
          background-color: $hover;

          .hour-container {
            height: $hourHeight;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-end;
            border-right: 1px solid $neutral;
            border-bottom: 1px solid transparent;
            border-top: 1px solid $neutral;
            position: relative;

            &.lunch {
              &::after {
                content: "";
                position: absolute;
                left: 100%;
                top: 0;
                width: 200px;
                height: 100%;
                background-color: $hover;
                z-index: -1;
                opacity: 1;
              }
            }

            &:last-child {
              border-bottom-color: $neutral;
            }

            .label {
              position: relative;
              top: 1.5rem;
            }
          }
        }

        .recorded {
          width: 1px;
          flex-grow: 1;
          border-right: 1px solid $neutral;
          min-width: $recordedColumnWidth;
          background-image: linear-gradient($neutral 1px, transparent 1px);
          background-size: 100% $hourHeight;
          position: relative;
        }

        .planned {
          width: $plannedColumnWidth;
          flex-shrink: 0;
          text-align: center;
          position: relative;
          background-image: linear-gradient($neutral 1px, transparent 1px);
          background-size: 100% $hourHeight;
        }
      }
    }
  }

  @media (max-width: 1175px) {
    .filter-bar {
      margin-top: 0.5rem;
    }

    .task-filters {
      display: none;
    }
  }

  @media (max-width: 900px) {
    .filter-bar {
      .target-date {
        margin-left: unset;
      }
      justify-content: center;
    }
    .timesheets-dashboard-link {
      display: none;
    }
  }

  @media (max-width: 850px) {
    .planned-header {
      width: $plannedColumnWidthSmall !important;
    }
    .planned {
      width: $plannedColumnWidthSmall !important;
    }
  }

  @media (max-width: 600px) {
    .ant-picker,
    .users-filter,
    .users-filter-container {
      width: 100% !important;
      max-width: 16rem;
    }
    .target-date {
      display: none;
    }
  }
}
