@import "../../Variables.scss";

.project-invoices-card {
  margin-bottom: 1rem !important;

  .items {
    max-height: 300px;
    overflow-y: auto;
    padding-bottom: 0.2rem;
  }

  .invoice-item {
    position: relative;
    border: 1px solid $neutral;
    padding: 0.4rem 0.7rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    opacity: 1;
    background-color: $white;
    margin-bottom: -1px;

    &:hover {
      background-color: $hover;
    }

    .invoice-id-tag {
      width: 8.5rem;
      justify-content: flex-start;
      flex-shrink: 0;
      margin-right: 1rem;
    }

    .title {
      width: 100%;
      text-align: left;
      font-weight: 500;
      font-size: 0.75rem;
    }

    .status {
      text-align: right;
      margin-right: 1rem;
      color: $standardText;
      text-transform: uppercase;
      font-size: 0.65rem;
      color: $neutralDark;
      font-weight: 500;
      flex-shrink: 0;
      width: 7rem;
      text-align: center;
    }

    .total {
      font-size: 0.75rem;
      font-weight: 500;
      text-transform: uppercase;
      color: $neutralDark;
      margin-right: 1rem;
      text-align: right;
    }

    .avatar {
      margin-left: 1rem;
    }
  }
}
