.user-allowances {
  .allowance-table-container {
    margin-bottom: 2rem;
  }

  .allowance-table-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
    align-items: center;

    .allowance-table-header-title {
      font-weight: bold;
      font-size: 1rem;
    }
  }

  .allowance-actions-icon {
    border: unset;
    background: unset;
  }
}
