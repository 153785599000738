@import "../../Variables";

.link-task-modal {
  width: 1100px !important;
  max-width: 95vw !important;

  .submit-container {
    margin-left: 33%;
  }
}
