@import "../../Variables";

.send-document-modal {
  width: 1300px !important;

  .ant-modal-content {
    min-height: 940px !important;

    .ant-modal-body {
      display: flex;

      .pdf-renderer {
        width: unset;
        height: unset;
      }

      .pdf-loader {
        width: calc(892px * 0.8 + 15px) !important;
        height: calc(1262px * 0.8) !important;
      }

      .react-pdf__Document {
        margin-left: 0.5rem;
        transform-origin: 0 0;
        max-width: calc(90vw - 1.5rem);
        overflow-x: auto;

        .react-pdf__Page {
          .react-pdf__Page__canvas {
            box-shadow: unset !important;
            border: 2px solid $standardText;
            border-radius: $borderRadius;
            width: calc(892px * 0.8) !important;
            height: calc(1262px * 0.8) !important;
          }
        }
      }

      .pagination {
        top: -2.5rem;
        left: 50%;
      }
    }
  }

  .email-details {
    padding-top: 1rem;
    padding-bottom: 0.5rem;
    min-width: 300px;
  }

  .email-cc-option-list {
    list-style-type: none;
    padding: 0;

    li {
      padding: 0;
      margin: 0;
    }
  }

  .section {
    margin-bottom: 1rem;

    .section-title {
      font-weight: 500;
      display: block;
    }
  }

  .email-preview {
    margin-top: 0.3rem;
    border: 1px solid $border;
    padding: 0.6rem 1rem;
    border-radius: $borderRadius;
  }

  .buttons {
    padding-top: 1rem;
    display: flex;
    gap: 1rem;
    justify-content: center;
  }

  .email-details {
    .ant-checkbox-wrapper {
      display: flex;
      align-items: center;
      margin-bottom: 0.5rem;
    }
    .ant-checkbox + span {
      display: flex;
      flex-wrap: wrap;
      line-height: 1.3;
      flex-direction: column;
    }
  }

  @media (max-width: 1100px) {
    width: calc(892px * 0.8 + 50px) !important;
    max-width: 90vw !important;

    .ant-modal-body {
      flex-direction: column;
      align-items: center;

      .email-details {
        width: 100%;
        order: 1;
      }

      .pdf-renderer {
        margin-top: 1.5rem;
      }
    }
  }
}
