@import "../../Variables";

.create-client-modal {
  .ant-space {
    width: 100%;
  }

  .upload-box {
    width: 100% !important;
  }

  .submit-container {
    padding-left: 33%;
    button {
      width: 140px;
    }
  }

  .contact-item {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    border-top: 1px solid $border;

    &:nth-child(2) {
      margin-top: 0.7rem;
    }
  }
}
