@import "../../Variables";

.project-item {
  margin-bottom: 0.3rem !important;
  width: 100%;

  .ant-card-body {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }

  .avatar {
    margin-left: 1rem;
    margin-top: 1rem;
    position: relative;
    top: -0.5rem;
  }

  .users-filter {
    margin-left: 1rem;
    margin-top: 0.5rem;

    .avatar {
      margin-left: 0;
    }
  }

  .project-task-list {
    margin-top: -0.5rem;
    margin-bottom: 1rem;

    .dashboard-item-tags {
      margin-top: 0rem;
    }

    .project-task-list-title {
      &::before,
      &::after {
        border-color: darken($border, 1);
      }
      margin-bottom: 1rem;

      .ant-divider-inner-text {
        color: $standardText;
        font-weight: 500;
      }
    }
  }

  .project-task-title {
    font-weight: 500;
  }

  .project-details-tasks-filter .task-filters {
    height: 2rem;
    padding-top: 0;

    .avatar-list .avatar {
      margin-left: 0;
    }
  }
}
