.ant-modal-wrap .full-screen-on-mobile {
  @media (max-width: 500px), (max-height: 600px) {
    max-width: unset !important;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: unset !important;
    height: unset !important;
    margin: 0 !important;
    background-color: $white;

    .ant-modal-content {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: unset !important;
      height: unset !important;
      border-radius: 0;
      z-index: 100;
      padding: 0.5rem !important;
      margin-right: 0.15rem !important;
      padding-right: 0;
      overflow-y: auto;
      overflow-x: hidden;
      border: unset !important;
      box-shadow: unset !important;

      .ant-modal-close {
        top: 0.5rem;
        position: fixed;
        right: 1.5rem;

        .ant-modal-close-x {
          background-color: $white;
          width: 2rem;
          height: 2rem;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: $borderRadius;
        }
      }

      .ant-modal-header {
        margin-bottom: 0;

        .ant-modal-title {
          margin-bottom: 0.3rem !important;
        }
      }
    }
  }
}
