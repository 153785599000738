@import "../../Variables";

.experimental-features {
  margin-top: 1rem !important;

  .info-item {
    width: 400px;
    margin: 0 auto;
    justify-content: space-between;
  }
}
