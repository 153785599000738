@import "../Variables";

.template-editor-page {
  width: 100%;
  height: 100%;
  overflow: hidden;
  padding-left: 0 !important;
  position: relative;
  display: flex;
  flex-direction: column;

  &.invisible {
    opacity: 0;
  }

  * {
    user-select: none;
  }

  input {
    user-select: contain;
  }

  .output-editor {
    padding-left: 0 !important;
    width: 100%;
    height: 100%;
    display: flex;

    &.invisible {
      display: none;
    }
  }

  .panel {
    background-color: $white;
    width: 25rem;
    height: 100%;
    display: flex;
    flex-shrink: 0;
    flex-direction: column;
    z-index: 1;
    display: flex;
    flex-direction: column;

    text-align: left;
    overflow-y: auto;
    right: 0;

    .panel-contents {
      height: 100%;
      overflow-y: auto;
      padding: 1rem;
      padding-top: 0.6rem;
    }

    .border-drag {
      position: absolute;
      height: 100%;
      top: 0;
      position: absolute;
    }

    .ant-tabs-nav {
      margin-top: -0.5rem;
    }

    .ant-collapse {
      background-color: $white;
      border: 1px solid $neutral;

      border-bottom: 0;
      margin-left: -1rem;
      margin-right: -1rem;
      border-radius: 0;
      border-left-width: 0;
      border-right-width: 0;

      .ant-collapse-item {
        border-bottom-color: $neutral;
        border-radius: 0;

        .ant-collapse-header {
          background-color: rgba($hover, 0.7);
          border-radius: 0;
        }

        .ant-collapse-content {
          border-top-color: $neutral;
        }
      }
    }

    .section {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;

      &:not(:first-child) {
        margin-top: 1.5rem;
        padding-top: 1.5rem;
        border-top: 1px solid $border;
      }

      .title {
        font-weight: 500;
        font-size: $regularFontSize;
        padding-bottom: 0rem;
        border-bottom: 1px solid rgba($white, 0.1);
        user-select: none;
      }
    }

    .task-picker {
      font-size: 0.7rem;
    }

    .info-item {
      margin: 0;
      margin-bottom: 0.5rem;
      padding: 0;

      .label-container {
        flex-shrink: 0;
      }

      .label {
        user-select: none;
      }

      .ant-select,
      .ant-input-number,
      .ant-slider,
      .value-container {
        width: 100%;
      }
    }
  }

  &.spreadsheet-output,
  &.form-only {
    .editor-container {
      opacity: 0;
      pointer-events: none;
    }
  }

  &.form-only {
    .toolbar,
    .hierarchy-panel,
    .template-editor-canvas {
      display: none;
    }
  }

  .canvas-panel,
  .object-panel {
    transition: right 400ms ease-in-out !important;
  }

  &.form-editor-open {
    .preview-visible-text,
    .live-template-text,
    .viewing-old-version-text {
      width: calc(100% - 30rem + 3px);
    }
    .canvas-panel,
    .object-panel {
      right: calc(30rem - 2px);
    }
  }
}
