@import "../../Variables";

.canvas-panel {
  position: relative;
  border-right: unset !important;
  box-shadow: unset !important;

  .border-drag {
    left: 0;
    width: 5px;
    z-index: 2;
    top: 0;
    opacity: 1;
    cursor: col-resize;
    transition: all 300ms;
    border-left: 1px solid $neutral;

    &:hover {
      border-left: 3px solid $accent;
    }
  }

  .ant-table {
    * {
      border-radius: 0 !important;
    }

    .ant-table-header {
      border-right: 1px solid $neutral;
    }
  }

  .ant-btn {
    border-radius: $borderRadius !important;
  }

  td.ant-table-cell {
    padding-left: 2px !important;
    padding-right: 2px !important;
  }

  .preview-buttons-container {
    .value-container {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      max-width: 250px;
      margin: 0 auto;
    }
  }

  .restore-icon-container {
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: $borderRadius !important;
    margin: 0 auto;
    width: 1.5rem;
    height: 1.5rem;
    transition: all 200ms;
    display: none;

    .icon-restore {
      position: relative;
      top: -1px;
      g {
        fill: $standardText;
      }
    }

    &:hover {
      background-color: $standardText;
      .icon-restore {
        g {
          fill: $white;
        }
      }
    }
  }

  .selected-row {
    td {
      background-color: $standardText !important;
      color: $white !important;
      font-weight: 500;
    }

    &:hover {
      td.ant-table-cell {
        background-color: $standardText !important;
        opacity: 0.9 !important;
      }
    }

    .restore-icon-container {
      display: block;
      .icon-restore {
        g {
          fill: $neutralDark;
        }
      }

      &:hover {
        background-color: $white;
        .icon-restore {
          g {
            fill: $standardText;
          }
        }
      }
    }
  }

  .ant-pagination-options {
    display: none;
  }
}
