@import "../../Variables";

.review-comment-box {
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;

  &.reply-box {
    margin-bottom: 0.5rem;
  }

  .ant-mentions {
    text-align: left;
    border-color: $border;
  }

  .textarea {
    border-top: 1px solid $neutral;
    border-radius: 0 !important;
    background-color: transparent;

    .textarea-toolbar-inner {
      border-radius: 0 !important;
    }
  }

  textarea {
    padding: 0.2rem 0.7rem;
    text-align: left;

    &::placeholder {
      font-weight: normal;
      color: $neutralDark !important;
    }
  }

  [data-slate-node="element"] {
    text-align: left;
    font-size: $smallFontSize;
  }

  .buttons-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 0.5rem;
    gap: 0.5rem;
  }

  button.comment {
    margin-right: 0.5rem;
  }

  button.comment.disabled {
    border-color: $neutral;
    color: $neutralDark;
    background-color: rgba($neutralDark, 0.1);
  }

  button {
    display: inline-block;
  }

  &:not(.reply-box) {
    margin-left: -0.5rem;
    width: calc(100% + 1rem);
    padding-top: 0 !important;
    background-color: $white !important;
    margin-bottom: -0.5rem;

    .expand-collapse-button {
      position: absolute;
      top: 0.2rem;
      right: 0.3rem;
      z-index: 10;

      .anticon {
        transition: all $transition-duration $transition-timing-function;
        color: $accent;
      }
    }

    .buttons-container {
      padding: 0.5rem;
      border-top: 1px solid $border;
    }

    .ant-mentions {
      border-radius: 0;
      border: unset !important;
      transition: all $transition-duration $transition-timing-function;
      border-top: 3px solid $neutral !important;
      padding-top: 0.3rem;
      padding-right: 0.2rem !important;

      &.ant-mentions-focused {
        box-shadow: unset;
        border-top-color: $accent !important;
      }

      textarea {
        border: unset !important;
        transition: all 600ms ease-in-out;
        height: 3.3rem;
        padding-right: 2rem !important;
      }
    }

    &.is-expanded {
      .expand-collapse-button {
        .anticon {
          transform: rotate(180deg);
          transform-origin: 50% 50%;
        }
      }
      textarea {
        height: calc(1.5rem * 15);
        max-height: calc(100vh - 300px);
        min-height: 3.3rem;
      }
    }
  }

  .tooltip-icon {
    color: $standardText;
    cursor: pointer;
    font-size: 1rem;
  }
}

.review-comment-box-send-tooltip {
  .ant-tooltip-inner {
    // width: 400px;

    .ant-tag {
      color: $standardText;
    }
  }
}
