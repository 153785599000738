@import "../Variables.scss";

.switch {
  display: flex;
  gap: 0.3rem;
  font-weight: 500;
  align-items: center;
  margin-left: 1rem;

  &.small {
    label {
      font-size: 0.75rem;
    }
  }

  .ant-switch {
    margin: 0 !important;
  }
}
