@import "../Variables";

.upload-container {
  .upload-box {
    border: 1px dashed $neutral;
    background-color: $background;
    position: relative;
    color: $accent;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    overflow: hidden;
    border-radius: $borderRadius;

    &.with-placeholder {
      background-color: transparent;
    }

    label {
      cursor: pointer;
    }

    &:hover {
      border-color: $accent;

      .message {
        color: $accent;
      }
    }

    .upload-icon {
      font-size: 1.3rem;
    }

    &.small {
      width: 85px;
      height: 85px;
    }

    &.normal {
      width: 140px;
      height: 140px;
    }

    &.large {
      width: 400px;
      height: 400px;
    }
  }

  .hidden-input {
    display: none !important;
  }

  .file-list {
    text-align: center;
    flex-direction: column;
    overflow-y: auto;

    .file-item {
      margin-top: 0.5rem !important;
      display: block;
    }
  }

  .content {
    pointer-events: none;
    text-align: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .thumbnail-icon-container {
      .anticon {
        font-size: 3rem;
      }

      .file-name {
        margin-top: 1rem;
      }
    }

    .thumbnail {
      width: 100%;
    }

    .initial-message {
      z-index: 1;
      position: relative;
    }

    .placeholder {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      filter: brightness(1) saturate(1);
      opacity: 0.1;
      transform: translate(-50%, -50%);
    }

    .ant-typography {
      display: block;
    }
  }

  &.full-width {
    .upload-box {
      width: 100% !important;
    }
  }
}
