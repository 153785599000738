@import "../Variables.scss";

.stock-item-details-page {
  .stock-item-summary-card {
    .ant-space-item:nth-child(1) {
      width: 100%;
    }
    .ant-space-item:nth-child(2) {
      display: none;
    }
    .card-title {
      width: 100%;
    }
  }

  .stock-item-name {
    font-weight: 500;
    flex-shrink: 0;
    margin-left: -0.3rem;

    textarea,
    input {
      font-size: 1.2rem;
      font-weight: 500;
    }

    .anticon {
      margin-right: 0.5rem;
    }
  }
}
