@import "../../Variables.scss";

.timeline-settings {
  .ant-drawer-body {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;

    & > div {
      display: flex;
      flex-direction: column;
      gap: 0.3rem;

      .setting-label {
        font-weight: 500;
        display: flex;
        gap: 0.5rem;
      }
    }

    .info-item {
      margin-bottom: 0;
    }
  }
}
