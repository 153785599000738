@import "../../Variables.scss";

.project-address-page {
  .project-address-details {
    display: flex;
    flex-direction: column;
    margin: 1rem 0rem;
    gap: 0.2rem;
  }

  .google-maps-wrapper {
    height: 400px;
    width: 100%;
    margin: 0 auto;
  }

  .address-detail-item {
    display: block;

    .address-detail-item-label {
      font-weight: 500;
      margin-right: 0.5rem;
      width: 150px;
      text-align: right;
      display: inline-block;
    }
  }
}
