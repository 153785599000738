@import "../Variables.scss";

.budget-bar {
  width: 150px;

  .ant-progress-show-info .ant-progress-outer {
    margin: 0;
    padding: 0;
  }

  .progress-label {
    display: block;
    margin-top: -1rem;
  }

  &.budget-bar-large {
    width: 220px;
  }

  &.include-percentage {
    display: flex;
    align-items: center;

    .progress-label {
      flex-shrink: 0;
      margin-top: 0;
      font-size: $extraSmallFontSize;
      font-weight: bold;
    }

    .static-label {
      flex-shrink: 0;
      margin-right: 0.5rem;
      font-size: $extraSmallFontSize;
      font-weight: bold;
    }

    .ant-progress {
      margin-bottom: 0;
      height: 1rem;
    }
  }
}
