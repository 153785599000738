@import "../../../../Variables.scss";

.task-status-list {
  .task-status-item {
    display: block;
    border: 1px solid $neutral;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0.3rem 1rem;
    cursor: grab;

    .type {
      width: 7rem;
      text-transform: uppercase;
      font-size: 0.7rem;
      font-weight: 500;
    }

    .name {
      text-align: left;
    }

    .actions {
      margin-left: auto;
    }

    &:hover {
      background-color: $hover;
    }

    &:not(:last-child) {
      margin-bottom: -1px;
    }

    .delete-task-status,
    .edit-task-status {
      border: unset;
      background: unset;
      box-shadow: unset;
    }
  }
}
