@import "../Variables";

.landing-page {
  width: 100vw;
  overflow-x: hidden;
  margin-left: -0.5rem;

  .image-with-details,
  .image-with-details .details,
  .image-with-details .illustration-container {
    transition: all 800ms;
    transition-timing-function: ease-in-out;
  }

  .cta-container {
    text-align: center;
    margin-top: 2rem;

    button {
      font-weight: 500;
      width: 200px;
      height: 50px;
      font-size: 1rem;
    }
  }

  h2 {
    font-size: 2.5rem;
    font-weight: 500;
    margin-bottom: 1rem;
  }

  .main-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 2rem;
    padding-top: 4rem;
    position: relative;

    .page-title {
      font-size: 3rem;
      font-weight: 500;
      letter-spacing: -1px;
      line-height: 4rem;
      position: relative;
      color: $standardText;
    }

    .page-subtitle {
      font-size: 1.2rem;
      letter-spacing: -0.3px;
      line-height: 2.1rem;
      margin-top: 1rem;
      position: relative;
      opacity: 0.7;
      max-width: 700px;
      margin-left: auto;
      margin-right: auto;
      color: rgba($standardText, 0.7);
    }

    .cta-container {
      margin-bottom: 4rem;
    }
  }

  @media (max-width: 1000px) {
    .main-section {
      .page-title {
        font-size: 2.5rem;
        line-height: 1.3;
      }

      .page-subtitle {
        margin-top: 0.5rem;
      }

      .cta-container {
        margin-top: 1rem;
      }
    }

    .cta-container {
      transform: scale(0.8);
    }
  }

  @media (max-width: 550px) {
    .main-section {
      padding-top: 2rem;

      .page-title {
        font-size: 2rem;
        line-height: 1.2;
      }

      .page-subtitle {
        margin-top: 0.5rem;
      }

      .cta-container {
        margin-top: 1rem;
        margin-bottom: 0;

        button {
          height: 40px;
          width: 150px;
        }
      }

      .image-with-details {
        margin-top: 2rem;
      }
    }
  }

  @media (max-width: 430px) {
    .main-section {
      padding-top: 1rem;

      .page-title {
        font-size: 1.5rem;
        line-height: 1.3;
      }

      .page-subtitle {
        margin-top: 0rem;
        font-size: 1rem;
      }
    }
  }

  .image-with-details {
    width: 100%;
    margin-top: 2rem;
    position: relative;
    padding-top: 0;
    margin-bottom: 6rem;

    .details {
      opacity: 0;
      width: 0;
      position: absolute;
      top: 100px;
      left: -200px;
      width: calc(40% - 3rem);
      transition: all 800ms;
      transition-delay: 0ms;
      z-index: 0;

      .item {
        text-align: left;

        &:first-child {
          margin-top: 2rem;
        }

        &:not(:last-child) {
          margin-bottom: 2rem;
        }

        .title {
          font-size: 1.5rem;
          font-weight: 500;
          margin-bottom: -0.3rem;
          color: $standardText;

          &.dark {
            color: $standardText;
          }
          &.medium {
            color: rgba($standardText, 0.95);
          }
          &.light {
            color: rgba($standardText, 0.9);
          }
        }

        .description {
          opacity: 0.7;
          font-size: 1rem;
          color: rgba($standardText, 0.8);
          line-height: 1.3;
        }

        @media (max-width: 1350px) {
          &:first-child {
            margin-top: 1rem;
          }

          &:not(:last-child) {
            margin-bottom: 1.5rem;
          }
        }

        @media (max-width: 1250px) {
          &:first-child {
            margin-top: 0rem;
          }

          &:not(:last-child) {
            margin-bottom: 1.5rem;
          }
        }

        @media (max-width: 1200px) {
          &:not(:last-child) {
            margin-bottom: 1rem;
          }

          .title {
            font-size: 1.3rem;
          }

          .description {
            font-size: 1rem;
          }
        }

        @media (max-width: 1150px) {
          &:first-child {
            margin-top: -1rem;
          }

          &:not(:last-child) {
            margin-bottom: 1rem;
          }
        }

        @media (max-width: 1050px) {
          &:first-child {
            margin-top: -1rem;
          }

          .title {
            font-size: 1.2rem;
          }

          .description {
            margin-top: 0.3rem;
            line-height: 1.3;
            font-size: 1rem;
          }
        }
      }
    }

    .illustration-container {
      width: 100%;
      display: block;
      position: relative;
      margin-left: 0;
      z-index: 1;

      .illustration {
        max-width: 100%;
        width: 100%;
        box-shadow: 0px 7px 20px darken($border, 2);
        border-radius: $borderRadius * 2;
      }

      .play-icon-container {
        position: absolute;
        transform: translate(-50%, -50%) scale(1);
        transform-origin: 50% 50%;
        top: 50%;
        left: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        background-color: white;
        width: 6rem;
        height: 6rem;
        box-shadow: 2px 2px 20px rgba($accent, 0.5);
        transition: all 400ms;
        transition-timing-function: ease-in-out;

        .icon-play {
          width: 3rem;
          fill: $accent;
          transition: all 400ms;
          transition-timing-function: ease-in-out;
        }

        &.hidden {
          transform: translate(-50%, -50%) scale(0);
        }

        @media (max-width: 550px) {
          width: 4rem;
          height: 4rem;
          .icon-play {
            width: 2rem;
          }
        }
      }

      &.is-video {
        cursor: pointer;

        &:hover {
          .play-icon-container {
            background-color: $accent;

            .icon-play {
              fill: $white;
            }
          }
        }
      }
    }

    &.mobile {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 2rem;

      .details {
        position: relative;
        top: 0;
        left: 0;
        opacity: 1;
        margin-top: 2rem;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;

        .item {
          width: 500px;
          max-width: 100%;

          &:first-child {
            margin-top: 0;
          }
          &:not(:last-child) {
            margin-bottom: 1.5rem;
          }
        }
      }

      .illustration {
        box-shadow: 0px 3px 15px darken($border, 10);
      }

      @media (max-width: 600px) {
        .illustration {
          box-shadow: 0px 0px 10px darken($border, 10);
          border-radius: $borderRadius;
        }
      }
    }
  }

  .statement-block {
    color: $white;
    position: relative;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    margin-left: auto;
    margin-right: auto;

    .illustration-container {
      width: 100%;
      position: relative;
      height: 600px;
      background-size: cover;
      filter: contrast(80%);
    }

    .text-container {
      text-align: center;
      background-color: rgba(black, 0.65);
      position: relative;
      margin-top: 0rem;
      padding: 3rem;
      width: 100%;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .title {
        font-size: 3rem;
        font-weight: 500;
        color: white;
      }

      .description {
        font-size: 1.4rem;
        margin-top: 1rem;
        opacity: 0.9;
        width: 800px;
        max-width: 100%;
        margin-left: auto;
        margin-right: auto;
        color: white;
        margin-bottom: 2rem;
        margin-top: 2rem;
        text-align: left;

        ul {
          padding-left: 1rem;
          margin-top: 1rem;
          li {
            color: white !important;
          }
        }
      }
    }

    &.light {
      .text-container {
        background-color: rgba(black, 0.5);
      }
    }

    @media (max-width: 900px) {
      .text-container {
        padding: 1.5rem;

        .title {
          font-size: 2rem;
        }

        .description {
          font-size: 1rem;
          margin-bottom: 1rem;
          margin-top: 0.6rem;
        }
      }

      .illustration-container {
        height: 400px;
      }
    }

    @media (max-width: 550px) {
      .text-container {
        padding: 1rem;
        transform: unset;
        position: relative;
        top: unset;
        left: unset;

        .title {
          font-size: 1.5rem;
        }

        .description {
          font-size: 1rem;
          margin-bottom: 1rem;
          margin-top: 0.3rem;
        }
      }

      .illustration-container {
        height: 100%;
        position: absolute;
        transform: translate(-50%, -50%);
        top: 50%;
        left: 50%;
        background-size: cover;
        background-position: 50% 50%;
      }
    }
  }

  .integrations {
    .title {
      font-weight: 500;
      font-size: 3rem;
    }

    .applications {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      margin-top: 2rem;
      margin-bottom: 2rem;

      .application {
        width: calc(100% / 4);
        text-align: center;
        flex-shrink: 0;

        img {
          width: 16vw;
          max-width: 200px;
        }
      }
    }
    @media (max-width: 900px) {
      padding-bottom: 0 !important;

      .title {
        font-size: 2rem;
      }

      .applications {
        .application {
          width: 50%;
          margin-bottom: 3rem;

          img {
            width: 30vw;
          }
        }
      }
    }

    @media (max-width: 550px) {
      .title {
        font-size: 2rem;
      }
    }
  }

  .with-dots {
    background-image: radial-gradient($border 1.5px, transparent 0);
    background-size: 15px 15px;
    padding: 3rem 0;

    @media (max-width: $boxedLayoutMaxWidth) {
      background-image: radial-gradient($border 1.5px, transparent 0);
      background-size: 1.2vw 1.2vw;
    }

    @media (max-width: 900px) {
      background-image: radial-gradient($border 1.4px, transparent 0);
      background-size: 1.5vw 1.5vw;
    }

    @media (max-width: 600px) {
      background-image: radial-gradient($border 1.2px, transparent 0);
      background-size: 10px 10px;
    }

    @media (max-width: $mobileBreakpoint) {
      background-image: radial-gradient($border 1px, transparent 0);
      background-size: 7px 7px;
    }
  }

  .with-neutral-background {
    position: relative;
    margin-top: 4rem;
    background-color: $background;
    padding-top: 1rem;
    padding-bottom: 5rem;

    &::before {
      content: "";
      position: absolute;
      top: -100px;
      left: 0;
      width: 100%;
      height: 100px;
      background: linear-gradient(0deg, $border 0%, white 100%);
    }

    &::after {
      content: "";
      position: absolute;
      bottom: 0px;
      left: 0;
      width: 100%;
      height: 100px;
      background: linear-gradient(180deg, $border 0%, white 100%);
    }
  }

  .quote-container {
    .quote-title {
      font-size: 2rem;
      font-weight: 500;
    }
  }

  .feature-cloud {
    padding: 3rem 0;
    position: relative;
    background-color: $accent;
    margin-bottom: 2rem;

    .dots {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-image: radial-gradient(rgba($standardText, 0.1) 1.5px, transparent 0);
      background-size: 20px 20px;
      background-position: -19px -19px;
    }

    .boxed-layout {
      display: flex;
      justify-content: space-between;
      position: relative;

      .cloud {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 60%;

        .title {
          font-size: 2.5rem;
          font-weight: 500;
          margin-bottom: 1.5rem;
          color: white;
        }
      }

      .features {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;

        .feature {
          padding: 1.3rem 2.5rem 1.1rem;
          background-color: white;
          border-radius: $borderRadius;
          margin-right: 1rem;
          margin-bottom: 1rem;
          cursor: pointer;
          font-weight: 500;
          text-transform: uppercase;
          font-size: 0.65rem;
          color: $standardText;
          transition: all 300ms;

          &.selected {
            color: $white;
            background-color: $standardText;
          }

          &:hover {
            color: $white;
            background-color: lighten($standardText, 10);
          }
        }
      }
      .details {
        width: 40%;
        text-align: center;

        .title {
          font-size: 1.2rem;
          text-transform: uppercase;
          font-weight: 500;
          margin-bottom: 1.5rem;
          color: white;
          margin-top: 1.5rem;
        }

        .description {
          color: white;
          font-weight: 500;
          color: $white;
        }
      }
    }
  }

  .lines-illustration-container {
    margin-top: -5rem;
    margin-bottom: 7rem;
    position: relative;
    filter: hue-rotate(330deg);
  }

  .card-section {
    padding-top: 3rem;

    .card-row {
      display: flex;
      justify-content: space-between;
      margin-left: -1.5rem;
      margin-right: -1.5rem;

      .card-item {
        width: 100%;
        padding: 0 1.5rem;
        height: 100%;
        margin-bottom: 4rem;

        .title {
          font-size: 1.5rem;
          margin-top: 1rem;
        }

        .description {
          margin-top: 0.5rem;
          margin-bottom: 1rem;
          max-width: 400px;
          margin-left: auto;
          margin-right: auto;
        }

        .illustration-container {
          margin-top: auto;
          height: 250px;
          display: flex;
          align-items: center;
          justify-content: center;

          @media (max-width: $boxedLayoutMaxWidth) {
            height: 20vw;
          }

          .illustration {
            max-height: 100%;
            max-width: 100%;

            &.with-border {
              border: 1px solid $border;
              border-radius: $borderRadius;
            }
          }
        }
      }

      @media (max-width: 900px) {
        flex-wrap: wrap;
        justify-content: center;

        &:first-child {
          margin-top: -1.5rem;
        }

        &:last-child {
          margin-bottom: 2rem;
        }

        .card-item {
          width: 500px;
          max-width: calc(100% - 3rem);
          margin-bottom: 1.5rem;
          border: 1px solid $border;
          border-radius: $borderRadius;
          padding: 1rem;

          .illustration-container .illustration {
            max-height: 300px;
          }

          .title {
            margin-top: 1rem;
            margin-bottom: 0;
            font-size: 1.3rem;
          }

          .description {
            margin-top: 0;
            margin-bottom: 0;
            font-size: 1rem;
            line-height: 1.3;
          }

          .illustration-container {
            height: unset;

            img {
              max-height: 400px;
            }
          }
        }
      }
    }
  }

  .customisation-container {
    background: white;
    padding: 4rem 0;

    @media (max-width: $mobileBreakpoint) {
      padding-top: 2rem;
      // padding-bottom: 6rem;
    }

    .boxed-layout {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      max-width: 800px;

      .title {
        font-size: 2.5rem;
        font-weight: 500;
      }

      .description {
        font-size: 1rem;
        margin-top: 1rem;
        opacity: 0.9;

        ul {
          padding-left: 1rem;
          margin-top: 1rem;
          li {
            color: white !important;
          }
        }

        a {
          text-decoration: underline;
        }
      }

      @media (max-width: 900px) {
        .title {
          font-size: 2rem;
          line-height: 1.4;
        }
      }

      @media (max-width: 550px) {
        .title {
          font-size: 1.5rem;
          line-height: 1.2;
        }

        .description {
          font-size: 1rem;
          line-height: 1.3rem;
          margin-bottom: -1rem;
        }
      }
    }
  }
}
