@import "../Variables";

.stock-item-filters {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    top: -1rem;
    left: 0;
    width: 100%;
    height: calc(100% + 1rem);
    background-color: $white;
  }

  span {
    position: relative;
  }

  .range-picker-container {
    margin-left: 1.5rem;
  }

  .filter-button {
    margin-left: 1rem;
    margin-right: 0.5rem;
  }

  .filter-input {
    margin-right: 2rem;
    border: unset;
    border-radius: $borderRadius;
    width: 200px;
    border: 1px solid $neutral;

    .ant-input-prefix {
      margin-right: 0.6rem;

      .anticon {
        color: $standardText;
      }
    }

    .ant-input-affix-wrapper-focused {
      display: none !important;
      border: 1px solid $accent;
      display: inline-block;
    }

    input {
      color: $standardText;
      font-weight: 500;

      &::placeholder {
        color: $neutralDark;
        font-weight: normal;
      }
    }
  }
}

.stock-item-filters-dropdown-overlay {
  width: 20rem;

  .right-label {
    margin-left: 1rem;
  }

  .ant-dropdown-menu-title-content {
    display: flex;
    align-items: center;
  }

  .created-in-range-container {
    width: 100%;

    .ant-picker {
      width: 100% !important;
    }
  }

  .top-label {
    margin-bottom: -1rem;
    display: block;
  }

  .left-label {
    margin-right: 0.5rem;
    display: inline-block;
    flex-shrink: 0;
  }

  .ant-switch {
    width: 4rem;
  }

  .flex-menu-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .stock-item-filter-client,
  .stock-item-filter-project {
    width: 100%;
  }
}
