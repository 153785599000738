@import "../../Variables";

.review-conversation {
  margin: 0 auto;
  padding: 0 0.5rem 0.5rem;
  width: 100%;
  height: 1px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  background-color: $background;

  .activity-title {
    width: calc(100% + 2rem);
    text-align: left;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 1.5rem;
    padding-right: 1rem;
    font-size: 0.9rem;
    margin-bottom: 0rem;
    margin-left: -1rem;
    margin-right: -1rem;
    display: flex;
    background-color: $white;
    border-bottom: 1px solid $neutral;

    .include-resolved {
      margin-left: auto;

      .ant-switch-inner {
        font-size: 0.8rem;
      }
    }
  }

  .review-comment-box {
    &:not(.reply-box) {
      // margin-left: -1rem;
      // width: calc(100% + 2rem);
    }
  }
}
