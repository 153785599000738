@import "../../Variables.scss";

.user-row {
  .row-menu {
    position: absolute;
    display: flex;
    flex-direction: column;
    background-color: $white;
    @include with-soft-shadow;
    z-index: 3;
    padding: 0.2rem 0.2rem;
    animation: row-menu-appear 0.2s ease-in-out;
    animation-fill-mode: forwards;

    opacity: 0;
    transform: translateY(-5px);
    transition: opacity 0.2s ease-in-out, transform 0.2s ease-in-out;

    @keyframes row-menu-appear {
      0% {
        opacity: 0;
        transform: translateY(-5px);
      }
      100% {
        opacity: 1;
        transform: translateY(0);
      }
    }

    .menu-item {
      font-size: $smallFontSize;
      padding: 0.5rem;
      font-weight: 500;
      display: flex;
      align-items: center;
      gap: 0.5rem;
      justify-content: center;
      cursor: pointer;

      .anticon {
        font-size: 0.6rem;
      }

      &:hover {
        background-color: $hover;
      }

      &:last-child {
        border-bottom: none;
      }
    }
  }
}
