@import "../Variables";

.activity-tab {
  .comment-area {
    border-bottom: 1px solid $border;
    padding-bottom: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;

    .textarea {
      width: 100%;
    }

    .comment-buttons {
      display: flex;
      gap: 0.5rem;
      justify-content: center;
    }

    .submit-comment-button {
      width: 10rem;
    }
  }
}
