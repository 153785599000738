@import "../../../../Variables.scss";

.menus {
  .menu {
    border-radius: $borderRadius;
    border: 1px solid $neutral;
    padding: 0.5rem;

    &:not(:last-child) {
      margin-bottom: 0.5rem;
    }

    .menu-header {
      display: flex;
      gap: 0.5rem;
      justify-content: space-between;
      align-items: center;

      .menu-label {
        input {
          font-weight: 500;
        }
      }

      .menu-top-level-buttons {
        display: flex;
        gap: 0.5rem;
      }
    }

    .menu-items {
      margin: 0;
      margin-top: 0.3rem;
      padding-left: 0.4rem;
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
    }

    .menu-item {
      border-radius: $borderRadius;
      border: 1px solid $neutral;
      padding: 0 0.5rem;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .menu-item-details {
        display: flex;
        flex-direction: column;
        gap: -0.5rem;
        width: 100%;
      }

      .menu-item-label {
        margin-bottom: -0.5rem;

        input {
          font-weight: 500;
        }
      }

      .menu-item-url {
        input {
          color: $lightText;
        }
      }
    }
  }
}
