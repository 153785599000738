@import "../Variables";

.lane {
  box-sizing: border-box;
  background-color: $background;
  padding: 0.1rem 0.5rem 0.5rem;
  margin-bottom: 0.1rem;
  border-bottom-left-radius: $borderRadius;
  border-bottom-right-radius: $borderRadius;
  border: 2px solid transparent;
  border-top-left-radius: $borderRadius;
  border-top-right-radius: $borderRadius;

  .ant-empty {
    margin-bottom: 2rem;
  }

  .lane-title {
    text-align: left;
    display: block;
    font-size: 0.8rem;
    font-weight: 500;
    padding: 0.2rem 0;
    border-top-left-radius: $borderRadius;
    border-top-right-radius: $borderRadius;
    margin-left: -0.4rem;
    margin-right: -0.4rem;
  }

  .lane-title + div {
    height: 100%;
  }

  &.board {
    min-width: 18rem;
    display: flex;
    flex-direction: column;

    .lane-title {
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
      margin-top: -0.15rem;
      padding-left: 0.5rem;
    }

    .inner-list {
      height: 100%;
      padding-right: 0.3rem;
    }
  }

  &.list {
    border-radius: $borderRadius;
    margin-bottom: 2rem;

    .lane-title {
      margin-left: 0.2rem;
    }

    .lane {
      border-radius: $borderRadius;
      padding-bottom: 0.5rem;
      background-color: transparent;
      padding: 0 !important;
      margin: 0;
      border: unset;
      margin-bottom: 0.5rem;
    }
  }
}
