@import "../Variables";

.dismissable-tooltip-inner-container {
  z-index: 3;
}

.dismissable-tooltip-overlay {
  z-index: 3;

  .ant-tooltip-inner {
    text-align: center;
  }
}
