@import "../../Variables";

.daily-log,
.daily-log-placeholder {
  background-color: $hover;
  border-radius: $borderRadius;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  max-width: 50rem;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  border: 1px solid $neutral;
  width: 30rem;

  &.read-only {
    opacity: 0.4;
  }

  .time-container {
    flex-shrink: 0;
  }

  & > .title {
    font-weight: 500;
    margin-bottom: 0.5rem;
    display: block;
    display: flex;
    gap: 1rem;
    align-items: center;
    justify-content: center;
  }

  .log-items-container {
    height: 1px;
    flex-grow: 1;
    overflow-y: auto;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    margin-right: 0.5rem;
    padding-right: 0.3rem !important;
  }

  @media (max-width: 1500px) {
    width: 25rem;
  }

  @media (max-width: 1400px) {
    width: 20rem;
  }

  @media (max-width: 900px) {
    width: 17rem;

    .log-items-container {
      padding-right: 0 !important;
      margin-right: 0 !important;
    }
  }

  @media (max-width: 764px) {
    width: 15rem;
  }

  @media (max-width: 734px) {
    display: none;
  }
}
