@import "../../../Variables";

.task-revision-item {
  border: 1px solid $neutral;
  padding: 0.5rem 1rem;
  border-radius: $borderRadius;
  position: relative;

  &.is-highlighted {
    border-color: $accent;
    border-width: 3px;
  }

  &.read-only {
    .revision-basic-details * {
      color: darken($neutralDark, 5);
    }

    input {
      color: darken($neutralDark, 5) !important;
    }

    .ant-tag-green {
      color: $good;
    }
  }

  .revision-action-buttons {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    flex-direction: column;
    gap: 0.5rem;
  }

  .add-file-button,
  .approve-task-revision-button,
  .control-access-button {
    width: 8rem;
  }

  .info-item {
    margin: 0;
    padding: 0;

    &.author-item {
      margin-top: 0rem;
      margin-bottom: -0.7rem;
    }

    &.reviewer-item {
      margin-top: 0.4rem;
      margin-bottom: -0.7rem;
    }

    &.based-on {
      margin-bottom: -0.7rem;
    }

    &.created-on {
      margin-top: 0.3rem;
      margin-bottom: -0.7rem;
    }
  }

  .delete-task-revision {
    position: absolute;
    top: 0.45rem;
    right: 0.45rem;
    z-index: 2;
  }

  .revision-basic-details {
    display: flex;
    flex-direction: column;
    align-items: center;

    .revision-details {
      width: 100%;
    }

    .files {
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      width: 100%;
      margin-top: 0.5rem;

      button {
        margin-bottom: 0.5rem;
      }

      .ant-dropdown-button {
        margin-right: 0.5rem;
        width: unset;
      }
    }

    @media (max-width: 1100px) {
      flex-direction: column;
      align-items: flex-start;

      .actions-column {
        margin-top: 0.5rem;
      }
    }
  }

  .task-revision-title-info-item {
    margin-top: 0;
  }

  .request-form {
    margin-top: 0rem;
  }

  .task-revision-title {
    margin-left: -0.3rem;
    width: calc(100% - 3rem);

    input {
      font-weight: 500;
      width: calc(100% - 3rem);
    }
  }

  .review-status {
    margin-left: 0.5rem;
    color: unset;

    .icon {
      position: relative;
      vertical-align: middle;
    }

    &.review-success {
      * {
        color: $good;
      }
      .icon {
        top: 2px;
        vertical-align: unset;
      }
    }

    &.under-review {
      * {
        color: $accent;
      }
    }

    &.with-comments {
      * {
        color: $neutralDark;
      }

      .icon {
        top: 2px;
        vertical-align: unset;
      }
    }

    &.review-closed {
      * {
        color: $neutralDark;
      }

      .icon {
        top: 2px;
        vertical-align: unset;
      }
    }

    &.changes-requested {
      * {
        color: $bad;
      }

      .icon {
        display: inline-block;
        width: 15px;
        height: 15px;
        border-radius: 50%;
        background-color: transparent;
        border: 3px solid $bad;
        vertical-align: middle;
        top: -1px;
        position: relative;
        box-sizing: border-box;
      }
    }
  }
}
