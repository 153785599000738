@import "../Variables";

.info-item {
  display: flex;
  flex-direction: column;
  padding: 0.5rem;
  margin-left: -0.5rem;
  margin-top: -0.5rem;
  background-color: transparent;
  border-radius: $borderRadius;
  min-height: 2rem !important;

  &:not(:last-child) {
    margin-bottom: 1rem;
  }

  &.no-bottom-space {
    margin-bottom: 0;
  }

  .main-content {
    display: flex;
    flex-direction: column;
  }

  .label-container {
    flex-shrink: 0 !important;
  }

  .label {
    font-size: $smallFontSize;
    font-weight: 500;
  }

  &.full-width {
    width: 100%;

    .main-content {
      width: 100%;

      .value-container {
        width: 100%;

        & > * {
          width: 100%;
        }
      }
    }
  }

  .value-container {
    .static-value {
      font-size: $smallFontSize;
    }
  }

  &.inline .main-content {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    align-items: center;
  }

  &.clickable {
    cursor: pointer;

    &:hover {
      background-color: $hover;
    }
  }
}
