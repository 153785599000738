@import "../../Variables";

.document-details-modal {
  width: 1500px !important;
  max-width: 100vw;
  height: 90vh !important;
  top: 5vh !important;
  z-index: 7;

  &.document-details-modal-portrait {
    width: 650px !important;
  }

  .download-button-label {
    color: white;
  }

  .ant-modal-content {
    height: 90vh !important;

    .ant-modal-body {
      height: 100%;
      border-radius: 4px;
      padding: 1rem;
      padding-bottom: 0;
      padding-top: 0rem;

      & > .content {
        display: flex;
        flex-direction: column;
        height: 100%;
        overflow-y: hidden;
      }
    }
  }

  .content {
    .document-info {
      margin-right: 10px;
      display: flex;
      gap: 1rem;
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
      width: calc(100% - 3rem);
      height: 3.5rem;
      margin-bottom: 1rem;

      .item {
        text-align: center;
        &:not(:first-child) {
          flex-shrink: 0;
        }

        &:last-child {
          text-align: right;
        }

        .label {
          text-transform: uppercase;
          font-size: 0.65rem !important;
          color: darken($neutralDark, 10) !important;
          font-weight: 500;
          display: block;
        }
      }

      .document-title {
        font-weight: 500;
        width: 100%;
        text-align: left;
      }

      .document-details-modal-download-button {
        display: flex;
        align-items: center;
      }

      .created {
        width: 9rem;
        flex-shrink: 0;
      }

      .editable {
        display: flex;
        flex-direction: row;
        align-items: center;

        .edit {
          margin-right: 1rem;

          svg {
            width: 20px;
            height: 20px;
          }
        }
      }
    }

    .document {
      display: flex;
      flex-direction: column;
      width: 100%;
      margin-bottom: 1rem;
      overflow: auto;
      padding: 0;
      border-radius: $borderRadius;
      background-color: $background;
      border: 1px solid $border;
      height: calc(100% - 4rem);

      .document-header {
        padding: 3px;
        width: 100%;
        display: flex;
        justify-content: center;
        background-color: $background;
        height: 2.5rem;

        .pagination {
          background-color: transparent;
        }
      }

      .zoomable-container {
        flex: 1;
        overflow-y: hidden;
      }
    }
  }
}
