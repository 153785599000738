@import "../Variables.scss";

.stock-items-page {
  background-color: $background;
  padding-right: 0.5rem;

  .page-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
    padding: 0.5rem;
    margin-left: -0.5rem;
    margin-right: -0.5rem;
    background-color: $white;

    .create-request-button {
      margin-left: auto;
    }
  }

  .icon-drag-handle {
    cursor: move;
    position: relative;
    cursor: move;
    position: relative;
    width: 50px;
    height: 50px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 20px;
    padding-right: 20px;
    margin-top: -0.5rem;
    margin-bottom: -0.9rem;

    .🔹dots {
      fill: $lightText !important;
    }
  }
}

.stock-row-item.row-dragging {
  width: calc(90vw) !important;
  display: table;
  background-color: $standardText !important;

  .icon-drag-handle {
    .🔹dots {
      fill: $white !important;
    }
  }

  td {
    display: table-cell;
    background-color: $standardText !important;

    &,
    * {
      color: $white !important;
    }
  }
}
