.document-preview-container {
  position: relative;

  .backup-page {
    position: absolute !important;
    top: 0;
    left: 0;
  }

  .live-page {
    position: absolute !important;
    top: 0;
    left: 0;

    &.live-page-loading {
      opacity: 0;
    }
  }
}
