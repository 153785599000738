@import "../Variables";

.loading-screen {
  min-height: 400px;
  height: 100%;
  max-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  img {
    width: 70px;
    animation: blink 2s infinite cubic-bezier(0.64, 0.57, 0.67, 1.53); /* Adjust timing as needed */
    opacity: 0.5;
  }

  @keyframes blink {
    0% {
      opacity: 0.5;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0.5;
    }
  }

  &.with-logo {
    height: 100vh;
  }

  .loading-screen-message {
    font-size: $smallFontSize;
    display: block;
    margin-top: 1rem;
    color: $lightText;
    font-weight: 500;
  }
}
