@import "../../Variables";

.configure-computer {
  margin-top: 1rem !important;

  .steps-content {
    max-width: 600px;
    width: 100%;
    margin: 1rem auto;
    text-align: center;

    button {
      width: 100%;
    }

    .ant-checkbox-group {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 130px;
      margin-left: auto;
      margin-right: auto;

      .ant-checkbox-group-item {
        margin-bottom: 0.5rem;
      }
    }

    .run-instructions {
      width: 350px;
      text-align: left;
    }

    .ant-result {
      padding: 1rem;
      padding-bottom: 0;

      button {
        width: 200px;
      }

      .app-found {
        color: #2ecc71;
      }

      .app-searching {
        color: $accent;
      }

      .app-not-found {
        color: #ff6b6b;
      }

      .ant-result-icon {
        margin-bottom: 0.5rem;
      }
    }
  }
}
