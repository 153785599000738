@import "../../Variables";

.object-panel {
  position: relative;

  .border-drag {
    left: 0;
    width: 5px;
    z-index: 2;
    opacity: 1;
    cursor: col-resize;
    transition: all 300ms;
    border-left: 1px solid $neutral;

    &:hover {
      border-left: 3px solid $accent;
    }
  }

  .ant-select {
    width: 100%;
  }

  .upload-box {
    width: 100% !important;
  }

  .info-item.with-error {
    position: relative;
    margin-bottom: 1rem !important;

    .value-container {
      position: relative;
    }

    .validation-message {
      position: absolute;
      bottom: -1.2rem;
      left: 0;
      font-size: $extraSmallFontSize;
      color: $bad;
    }
  }

  .object-parameter-list {
    padding-left: 1rem;
    position: relative;
    &::before {
      content: "";
      position: absolute;
      top: 0.3rem;
      left: 0;
      width: 1px;
      height: calc(100% - 0.3rem);
      background-color: $neutral;
    }
  }

  .bring-to-front-send-to-back,
  .collapse-panel-duplicate-delete {
    .value-container {
      display: flex;
      gap: 0.5rem;
      button {
        width: 100%;
      }
    }
  }

  .ant-collapse-item {
    &:last-child {
      margin-bottom: 1rem !important;
    }
  }

  .collapse-panel-position {
    .info-item .label-container {
      width: 8rem;
    }
  }

  .collapse-panel-look-feel {
    .info-item .label-container {
      width: 8rem;
    }
  }

  .parent-repeat-note {
    font-weight: 500;
    margin-bottom: 0.5rem;
    // margin-top: 1rem;
    display: block;
    // opacity: 0.7;
    color: $accent;
    font-size: $smallFontSize;
  }

  .color-input-container {
    position: relative;

    .color-preview {
      width: 1.2rem;
      height: 1.2rem;
      border-radius: $borderRadius;
      background-color: $neutralLight;
      position: absolute;
      top: 50%;
      left: 0.6rem;
      transform: translateY(-50%);
      cursor: pointer;
      z-index: 1;
      box-shadow: 0px 0px 2px $neutralDark;
    }

    .color-input {
      input,
      textarea {
        padding-left: 2.2rem !important;
      }
    }
  }
}

.color-picker-overlay {
  .input {
    margin-top: 1rem;
  }
}
