@import "../../../Variables.scss";

.object-placeholder {
  width: 100%;
  height: 100%;
  display: flex !important;
  flex-direction: column;
  gap: 0.5rem;
  justify-content: center;
  align-items: center;
  border: 1px dashed transparent;
  background-color: rgba($standardText, 1);

  .anticon,
  .icon {
    font-size: 1.5rem;
    width: 1.5rem;
    color: white;
    fill: white;
  }

  .placeholder-text {
    font-size: $extraSmallFontSize;
    color: white;
    font-weight: bold;
  }

  &.small {
    gap: 0.2rem;
    .anticon,
    .icon {
      font-size: 1rem;
      width: 1rem;
    }

    .placeholder-text {
      font-size: 8px;
    }
  }

  &.very-small {
    .anticon,
    .icon {
      font-size: 1rem;
      width: 100%;
    }

    .placeholder-text {
      display: none;
    }
  }

  &.image-placeholder {
    border-color: rgba($accent, 0.3);
    border-style: solid;
    background-color: rgba($accent, 0.1);

    .placeholder-text {
      color: $accent;
    }

    .anticon,
    .icon {
      color: $accent;
      fill: $accent;
    }
  }
}
