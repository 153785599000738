@import "../../Variables";

.custom-labels-list {
  width: 1100px !important;
  max-width: 100% !important;
  margin: 0 auto !important;

  .custom-label {
    border: 1px solid $neutral;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0.3rem 1rem;
    cursor: pointer;

    .name {
      text-align: left;
      margin-left: 0.5rem;
    }

    .id {
      width: 200px;
      font-size: 0.8rem;
      font-weight: 500;
    }

    .type {
      text-align: left;
      font-size: 0.8rem;
      font-weight: 500;
      text-transform: uppercase;
      width: 5rem;
      text-align: center;
      position: relative;
      top: 1px;
      margin-right: 1rem;
    }

    &:hover {
      background-color: $hover;
    }

    &:not(:last-child) {
      margin-bottom: -1px;
    }

    .delete-custom-label {
      border: unset;
      background: unset;
      box-shadow: unset;
      margin-left: auto;
    }
  }
}
