@import "../../Variables";

.supplier-actions {
  border: 1px solid $bad;
  box-shadow: unset;

  .ant-card-body {
    padding: 1rem !important;
    padding-bottom: 1.5rem !important;
  }

  .main-label {
    font-size: 1rem;
    font-weight: 500;
    margin-bottom: 0;
  }

  .description {
    margin-bottom: 0;
  }

  button {
    width: 12rem;
  }

  .ant-btn-danger {
    background-color: transparent;
    color: #cb2431;

    border-color: rgba(27, 31, 35, 0.15);
    text-shadow: unset;

    .span {
      text-shadow: unset;
    }
  }
}

.purchase-orders-which-use-supplier {
  li {
    cursor: pointer;
    color: $accent;
    font-weight: 500;
    padding: 0.2rem 0.5rem;

    &:hover {
      background-color: $hover;
    }
  }
}
