@import "../../../Variables.scss";

.text-variables {
  padding: 0.5rem;
  border-radius: $borderRadius;
  justify-content: space-between;
  border: 1px solid $border;

  .section-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    position: sticky;
    top: -0.6rem;
    z-index: 1;
    background-color: $white;

    .section-title {
      font-size: 1rem;
      font-weight: bold;
    }
  }

  .text-variable-item {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    padding-top: 0.5rem;
    justify-content: space-between;
    padding-bottom: 0.5rem;
    position: relative;
    width: 100%;

    .variable-details {
      display: flex;
      flex-direction: column;
      width: 100%;

      .info-item:last-child {
        margin-bottom: 0;
      }

      .input {
        width: calc(100% - 2.5rem);
        input {
          width: 100%;
        }
      }
    }

    .delete-variable-button {
      position: absolute;
      top: 0.5rem;
      right: 0;
    }

    .variable-symbol {
      font-weight: bold;
    }

    &:not(:last-child) {
      border-bottom: 1px solid $border;
    }

    &:nth-child(2) {
      border-top: 1px solid $border;
      margin-top: 0.5rem;
    }
  }
}
