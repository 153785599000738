@import "../../../Variables.scss";

.request-activity {
  .ant-table-wrapper .ant-spin-container {
    overflow-x: auto;

    .ant-table {
      td {
        user-select: none;
      }

      @media (max-width: 767px) {
        min-width: 700px;
      }
    }
  }

  .review-status-approved {
    background-color: $good;
    border-color: $good;
    color: white;
  }

  .review-status-rejected {
    background-color: $bad;
    border-color: $bad;
    color: white;
  }
}
