@import "../Variables.scss";
@import "../Mixins.scss";
@import "../ReportPage/reportHelpers.scss";

.purchase-order-details-page {
  display: flex;
  gap: 0.5rem;
  padding: 1rem;
  padding-right: 0rem;
  width: 100%;
  height: 100%;

  .corrupted-message-container {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    margin: 3rem auto;

    .corrupted-title {
      font-weight: 500;
    }
  }

  .purchase-order-metadata-wrapper {
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
    margin-bottom: 1rem;

    .metadata-card {
      width: calc(70% - 0.65rem);
      flex-grow: 1;
      min-width: 20rem;
    }

    .rejection-details {
      width: calc(30% - 0.65rem);
      flex-grow: 1;
      min-width: 20rem;
    }
  }

  &.is-archived {
    opacity: 0.7;
    filter: grayscale(50%);
  }

  .user-input-container {
    width: 100%;
    max-width: unset;
    max-height: calc(100vh - $navigationBarHeight - 32px);
    position: relative;

    .user-input-scroll-container {
      border-radius: $borderRadius * 1.3;
    }

    .card {
      max-height: unset;
    }
  }

  .purchase-order-summary {
    .ant-space-item:first-child {
      width: 100%;
    }
  }

  .react-pdf__Document > div:first-child {
    background-color: unset !important;
  }

  .pdf-preview-container {
    display: none;
    height: calc(100vh - $navigationBarHeight - 32px);
    overflow-y: auto;
    width: 610px;

    .report {
      position: sticky;
      top: 1rem;
    }
  }

  .purchase-order-fields-card {
    .input-group {
      @include input-group-style;
    }
  }

  .title-and-actions {
    width: 100%;
    align-items: flex-end;
    margin-top: -0.5rem;
    display: flex;
    justify-content: space-between;
    gap: 1rem;

    .title-container {
      width: 100%;
    }
  }

  .purchase-order-title {
    input {
      font-weight: 500;
      font-size: 1.2rem;
    }

    .anticon {
      margin-right: 0.5rem;
    }
  }

  .purchase-order-description-label {
    margin: 0;
    margin-top: 0.5rem;
    margin-bottom: 0.3rem;
    font-size: 0.8rem;
  }

  .purchase-order-description {
    display: flex;
    flex-direction: column-reverse;
    font-weight: normal;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
      "Droid Sans", "Helvetica Neue", sans-serif;
    border-radius: $borderRadius;
    border: 1px solid $neutral !important;
    padding: 0rem;

    * {
      color: $standardText;
    }

    [class^="EditorToolbar"] {
      border-bottom: unset;
    }

    [class^="RichTextEditor__block"] {
      font-size: 0.8rem;
      margin: 0;
    }

    .public-DraftEditor-content {
      min-height: 5rem;
    }

    a {
      color: $accent;
      text-decoration: underline;

      * {
        color: $accent;
      }
    }

    button {
      border-color: $neutral !important;
      background: white !important;
    }

    select {
      border: 1px solid $neutral !important;
      padding-left: 0.5rem;
      padding-right: 2.5rem;
      outline: $accent;
    }
  }

  &.with-comments {
    .user-input-container .reviewable-content {
      width: calc(100% - 21rem);

      @media (max-width: 1200px) {
        width: calc(100% - 18rem);
      }

      @media (max-width: 1000px) {
        width: calc(100% - 16rem);
      }
    }
  }

  @media (min-width: 1001px) {
    &.with-preview {
      .pdf-preview-container {
        display: block;
      }
      .user-input-container {
        width: calc(100% - 38rem);
      }
    }
  }

  // &.are-comments-visible {
  //   .user-input-scroll-container {
  //     display: flex;
  //     width: 100%;
  //     gap: 1rem;

  //     .reviewable-content {
  //       width: calc(100vw - $navigationWidth - 2rem - 20rem);
  //     }
  //   }
  // }
}

.delete-line-item-modal {
  .ant-modal-content {
    width: 500px;
  }
}
