@import "../Variables";

.task-filters {
  height: 3rem;
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: 1.5rem;
  padding-bottom: 1.2rem;
  justify-content: space-between;
  position: sticky;
  top: 0;
  z-index: 1;
  gap: 0.5rem;

  &::before {
    content: "";
    position: absolute;
    top: -1rem;
    left: 0;
    width: 100%;
    height: calc(100% + 1rem);
    background-color: $white;
  }

  .left-buttons {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }

  .export {
    white-space: nowrap;
  }

  span {
    position: relative;
  }

  .filter-button {
    margin-left: 0.5rem;
    white-space: nowrap;
  }

  .filter-input {
    border: unset;
    border-radius: $borderRadius;
    max-width: 400px;
    border: 1px solid $neutral;

    .ant-input-prefix {
      margin-right: 0.6rem;

      .anticon {
        color: $standardText;
      }
    }

    .ant-input-affix-wrapper-focused {
      display: none !important;
      border: 1px solid $accent;
      display: inline-block;
    }

    input {
      color: $standardText;
      font-weight: 500;

      &::placeholder {
        color: $neutralDark;
        font-weight: normal;
      }
    }
  }

  .label-active-filters-count {
    margin-left: 0.3rem;
  }

  @media (max-width: $mobileBreakpoint) {
    .create-task,
    .export,
    .label-active-filters {
      display: none;
    }
  }
}

.task-filters-dropdown-overlay {
  width: 30rem;
  max-width: 95vw;
  max-height: 80vh;
  overflow-y: auto;
  @include with-shadow;

  &.short-overlay {
    max-height: 40vh;
  }

  .users-filter {
    width: 100%;
  }

  .ant-dropdown-menu-title-content {
    display: flex;
    align-items: center;
  }

  .right-label {
    margin-left: 1rem;
  }

  .top-label {
    margin-bottom: -1rem;
    display: block;
  }

  .left-label {
    margin-right: 0.5rem;
    display: inline-block;
    width: 6rem;
    text-align: right;
    white-space: normal;
    flex-shrink: 0;
  }

  .ant-switch {
    width: 4rem;
  }

  .flex-menu-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .ant-dropdown-menu-title-content {
    display: flex;
    gap: 0.5rem;

    .input-container {
      display: flex;
      gap: 0.5rem;
      width: 100%;
    }

    .info-item {
      .label-container {
        width: 6rem;
        justify-content: flex-end;
        display: flex;
        text-align: right;
        margin-right: 0.5rem;
      }
    }
  }

  .task-filter-client,
  .task-filter-project,
  .task-filter-sprint,
  .task-filter-status {
    width: 100%;
  }

  .due-in-range-container,
  .created-in-range-container,
  .finished-in-range-container {
    width: 100%;

    .ant-picker {
      width: 100% !important;
    }
  }
}

.task-due-date-range-picker {
  .ant-picker-preset:nth-child(2) .ant-tag {
    $color: #8b60f1;
    border-color: $color;
    background-color: $color;
    color: white;
  }
  .ant-picker-preset:nth-child(3) .ant-tag {
    border-color: $bad;
    background-color: $bad;
    color: $white;
  }
}

.task-created-in-range-picker {
  .ant-picker-preset:nth-child(2) .ant-tag {
    $color: #8b60f1;
    border-color: $color;
    background-color: $color;
    color: white;
  }
  .ant-picker-preset:nth-child(3) .ant-tag {
    border-color: $bad;
    background-color: $bad;
    color: $white;
  }
}
