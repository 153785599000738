@import "../../Variables.scss";

.form-project-picker {
  display: flex;
  gap: 0.5rem;
  align-items: center;

  .project-selector {
    width: 100%;
  }
}
