@import "../../Variables.scss";

.review-request-form {
  background-color: $background;

  .report-user-fields-container,
  .report-user-fields-container > .card {
    max-height: unset !important;
    overflow-y: visible !important;
  }

  .report-user-fields-container {
    height: 100%;
  }

  .attachments-card {
    border: unset !important;
    box-shadow: unset !important;

    .card-header {
      padding-left: 0;
      padding-right: 0 !important;
      margin-left: 0;
    }

    .ant-card-body {
      padding: 0;
    }

    .actions-container {
      display: none;
    }
  }
}
