@import "../../Variables";

.upload-avatar-modal {
  width: 700px !important;

  .ant-space-item:first-child {
    width: 100%;
  }

  .submit-container {
    margin: 0 auto;
    margin-bottom: -2rem;

    button {
      margin-right: 1rem;
      display: block;
      margin: 0 auto;
      margin-bottom: 1rem;
      margin-left: 33%;
      width: 140px;
    }
  }
}
