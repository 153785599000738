@import "../../../Variables.scss";

.add-timesheet-block-to-invoice-modal {
  width: 95vw !important;
  max-width: 1400px !important;

  .submit-container {
    margin-left: 33%;
  }
}
