@import "../Variables.scss";

.file-preview {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 6;

  @keyframes fade-in-opacity {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  animation-name: fade-in-opacity;
  animation-duration: 0.3s;
  opacity: 0;
  animation-fill-mode: forwards;

  &.file-preview-PDF {
    display: none;
  }

  .file-preview-mask {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 1;
    background-color: rgba(darken($standardText, 11), 0.85);
  }

  .main-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 90vw;
    max-height: calc(100vh - $navigationBarHeight - 100px);
    z-index: 2;

    animation-name: fade-in-opacity;
    animation-duration: 0.3s;
    opacity: 0;
    animation-fill-mode: forwards;
    animation-delay: 0.4s;
  }

  .close-file-preview-button {
    position: absolute;
    top: 4rem;
    right: 1rem;
    z-index: 3;
    border: unset !important;
    transition: unset !important;

    .icon {
      margin: 0;
      color: $white;
    }

    &:hover {
      background-color: unset !important;
      border: unset !important;

      .icon {
        color: $accent !important;
      }
    }
  }

  .file-preview-button-previous,
  .file-preview-button-next {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 3;
    cursor: pointer;
    border: unset !important;
    transition: unset !important;

    &:hover {
      background-color: unset !important;
      border: unset !important;

      .anticon {
        color: $accent !important;
      }
    }

    &:disabled {
      background-color: unset !important;
      pointer-events: all;
      cursor: default;

      .anticon {
        color: white !important;
        opacity: 0.3 !important;
      }
    }

    .anticon {
      margin: 0;
      color: $white;
      font-size: 1.7rem;
    }
  }

  .file-preview-button-previous {
    left: 1.5rem;
  }

  .file-preview-button-next {
    right: 1.5rem;
  }

  .file-preview-footer {
    position: absolute;
    bottom: 0;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.2rem;
    z-index: 3;
    background-color: darken($standardText, 11);
    padding: 0.3rem 0.5rem;
    color: white;

    .counter {
      font-weight: bold;
    }

    .selected-file-path {
      opacity: 0.7;
    }
  }

  .spinner-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 3;
  }
}
