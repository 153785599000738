@import "../Variables";

.users-page {
  margin: 0 auto;
  padding: 0.5rem;
  padding-left: 1rem !important;

  .main-actions {
    margin-bottom: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;

    .users-page-title {
      font-size: $smallFontSize;
      color: $standardText;
      font-weight: 500;
      position: relative;
      top: 1px;
    }
  }

  & > .main-content {
    background-color: $background;
    border-radius: $borderRadius;
  }

  .section-title {
    font-size: $regularFontSize;
    font-weight: bold;
    padding-bottom: 0.5rem;
    margin-bottom: 1rem !important;
    display: block;
    margin-top: 1rem !important;

    &.visible-users-title {
      margin-top: 0rem !important;
    }
  }

  .users {
    .ant-list-item {
      border: unset;
    }

    .user-creation-date {
      margin-top: 0.2rem;
    }

    button.ant-btn {
      border-color: $neutral;

      &:hover {
        background-color: $hover;
      }

      .anticon-delete {
        color: $neutralDark;
      }
    }
  }
}

.no-reenable-during-cooldown-modal {
  width: 600px !important;
}

.group-actions,
.team-actions {
  display: flex;
  gap: 0.5rem;
  justify-content: center;
}

.ant-table-wrapper:first-child {
  margin-bottom: 1rem;
}
