@import "../../../Variables.scss";

.task-setting {
  .task-setting-name {
    color: $standardText;
    font-weight: 500;
  }

  .task-setting-description {
    color: #95a9b6;
  }

  .ant-checkbox-inner {
    margin-bottom: -0.7rem;
  }
}

.task-default-sprint,
.task-default-cat-level {
  margin-top: 1rem;

  .task-default-cat-level-label,
  .task-default-sprint-label {
    font-weight: 500;
  }
}
