@import "../../Variables.scss";

.modal-manage-item-list {
  .user-list-options {
    padding-left: 0;

    .item-index {
      margin-right: 0.5rem;
      font-weight: bold;
    }

    .user-list-option-item {
      border: 1px solid $neutral;
      border-radius: $borderRadius;
      padding: 0.7rem;
      margin-bottom: 0.5rem;
      display: flex;
      align-items: flex-start;
      width: 100%;

      .item-content {
        width: 100%;
      }

      .item-action-buttons {
        display: flex;
        gap: 0.5rem;
      }

      .delete-item,
      .edit-item,
      .add-item-to-template {
        color: $accent;
      }
    }
  }
}
