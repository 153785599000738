@import "../Variables";

.review-target {
  position: relative;

  &.visible {
    input,
    textarea {
      padding-right: 1.7rem !important;
    }
  }

  .review-button-container {
    position: absolute;
    right: 0.3rem;
    top: 0.2rem;
    background-color: $white;
    width: 1.45rem;
    height: 1.45rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    cursor: pointer;
    transition: all 300ms;
    transform-origin: 50% 50%;
    font-size: 1rem;
    z-index: 1;

    .anticon {
      color: $neutralDark;
      transition: all 300ms;
    }

    &::before {
      content: "";
      position: absolute;
      transform: translate(-50% -50%);
      width: 150%;
      height: 150%;
      display: block;
      background-color: red;
      opacity: 0;
    }

    &:hover {
      .anticon {
        color: $accent;
      }
    }
  }
}
