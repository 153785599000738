@import "../Variables.scss";

.linear-indefinite-preloader {
  width: 100%;
  height: 3px;
  background-color: $background;
  overflow: hidden; /* Ensures the line doesn't go outside the container */
  position: absolute;

  left: 0;
  z-index: 10000;
  opacity: 0;
  animation-name: fadeInPreloader;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 1.5s;
  animation-delay: 1.5s;
  animation-fill-mode: forwards;

  &.top {
    top: 0;
  }

  &.bottom {
    bottom: 0;
  }

  $lineWidth: 20%; /* Adjust the width of the bouncing line here */

  .preloader-line {
    width: $lineWidth; /* Adjust the width of the bouncing line here */
    height: 100%;
    background-color: $accent; /* Change the color of the line as needed */
    position: absolute;
    animation: bounceAnimation 7s infinite cubic-bezier(0.64, 0.57, 0.67, 1.53); /* Adjust timing as needed */
    animation-delay: 2s;
  }

  @keyframes bounceAnimation {
    0%,
    100% {
      left: 0%;
    }
    50% {
      left: calc(100% - $lineWidth); /* Adjust to change the bounce distance */
    }
  }

  @keyframes fadeInPreloader {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}
