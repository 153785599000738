@import "../Variables";

.lane-container {
  width: 100%;
  height: 100%;
  padding-bottom: 0.5rem;

  &.board {
    overflow-x: auto;
  }

  .lanes {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    background-color: $white;
    padding: 0;
    border-radius: $borderRadius;
    width: 100%;
    height: 100%;

    &.board {
      display: flex;
      justify-content: space-between;
      gap: 0.5rem;
    }

    &.list {
      display: block;
    }

    .lane {
      width: 100%;
      padding: 0 0.3rem;
    }
  }
}
