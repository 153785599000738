@import "../Variables";

.navigation,
.navigation-drawer {
  flex-shrink: 0;
  display: flex;
  gap: 1.4rem;
  align-items: center;

  @media (max-width: 1250px) {
    gap: 0.7rem;

    .nav-item {
      &.selected {
        &::before {
          top: -0.2rem !important;
          left: -0.4rem !important;
          width: calc(100% + 0.8rem) !important;
          height: calc(100% + 0.4rem) !important;
        }
      }

      .navigation-item-label {
        font-size: 0.7rem !important;
        line-height: 1.2;
      }
    }
  }

  @media (max-width: $mobileBreakpoint) {
    .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
      background-color: unset !important;
    }

    .nav-item {
      .navigation-item-label {
        font-weight: normal;
        font-size: 1rem !important;
      }

      &.selected {
        .navigation-item-label {
          font-weight: 500;
        }
        &::before {
          display: none;
        }
      }
    }
  }

  .menu-button {
    display: flex;
    align-items: center;
    cursor: pointer;

    .icon {
      fill: $white;
      transition: all 300ms;
    }

    &:hover {
      .icon {
        fill: $accent;
      }
    }
  }

  .nav-item {
    position: relative;
    color: $white;
    font-weight: 500;
    display: flex;
    text-align: center;
    align-items: center;
    gap: 0.2rem;
    padding: 0.1rem 0rem;

    letter-spacing: 0.3px;
    border-bottom: 1px solid transparent;
    cursor: pointer;

    .icon-container {
      display: flex;
      align-items: center;
      display: none;
    }

    @media (hover: none) {
      &:hover,
      &:focus,
      &:active {
        color: unset;
      }
    }

    &.selected {
      opacity: 1;
      font-weight: 700;
      &::before {
        content: "";
        position: absolute;
        top: -0.4rem;
        left: -0.7rem;
        width: calc(100% + 1.4rem);
        height: calc(100% + 0.8rem);
        border-radius: $borderRadius;
        background-color: $mainBarAccentColor;
        z-index: -1;
      }
    }

    .anticon-caret-down {
      font-size: 0.7rem !important;
    }

    .navigation-item-label {
      font-size: 0.75rem;
      line-height: 1.2;
    }

    .anticon {
      font-size: 1rem;
    }

    .anticon,
    .icon {
      position: relative;
      color: $neutral;
    }

    .icon {
      fill: $neutral;
      width: 20px;
    }

    .icon-folder {
      path {
        fill: $neutral;
      }
    }
  }
}

.navigation-drawer {
  .ant-drawer-close {
    color: $standardText;
  }

  .nav-item {
    color: $standardText;
    padding-bottom: 0.6rem;
    opacity: 0.4;

    .navigation-item-label {
      font-size: 1rem !important;
      line-height: 1.2;
    }
  }

  .admin-menu {
    border: unset !important;

    .ant-menu-item {
      margin: 0 !important;
      padding: 0;
      height: unset;
      line-height: unset;
    }
  }
}

.navigation-dropdown-menu {
  .ant-dropdown-menu {
    // position: relative;
    // top: 10px;
    // background-color: $mainBarBaseColor;

    .nav-item {
      // color: $white;

      .navigation-item-label {
        font-size: 0.75rem;
        line-height: 1.2;
        font-weight: 500;
        opacity: 0.7;
      }
    }
  }
}
