@import "../../Variables.scss";

.app-page-card {
  border: 1px solid $border;
  background-color: $white;
  @include with-shadow;
  padding: 0.5rem 1rem;
  box-sizing: content-box;
  flex-shrink: 0;
}
