@import "../../Variables.scss";

.simple-stat {
  flex-shrink: 0;

  .info-item {
    padding: 0;
    margin: 0;
  }
  .value-container {
    .static-value {
      margin-top: -0.5rem;
      font-size: $extraLargeFontSize;
      font-weight: 500;
    }

    .ant-progress {
      margin-top: 0.5rem;
      margin-bottom: 0.6rem;
    }
  }
}
