@import "../Variables";

.navigation-bar {
  position: sticky !important;
  top: 0 !important;
  z-index: 3;
  border-radius: 0 !important;
  box-shadow: unset !important;
  background-color: $mainBarBaseColor !important;
  width: 100vw !important;
  margin-left: 0 !important;
  border: unset !important;
  overflow-x: auto;
  overflow-y: hidden;
  height: calc($navigationBarHeight + 3px);
  display: flex;
  align-items: center;

  .back-button {
    color: $white;
    font-size: 1.5rem;
    margin-right: 1rem;
    cursor: pointer;
  }

  .logo {
    width: 25px;
    margin-right: 1.5rem;
    cursor: pointer;
    flex-shrink: 0;
    position: relative;
    top: 3px;
  }

  @media (max-width: $mobileBreakpoint) {
    .logo {
      display: none;
    }
  }

  .ant-card-body {
    padding-left: 0.8rem;
    padding-right: 0.8rem;
    padding-top: 0;
    padding-bottom: 0;
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
  }

  .timesheet-button,
  .holidays-button,
  .calendar-button {
    position: relative;
    top: 3px;
    margin-right: 1rem;
    cursor: pointer;

    svg {
      width: 16px;
      fill: $white;
    }

    &:hover {
      svg {
        fill: $accent;
      }
    }
  }

  .anticon-home {
    color: $white;
    font-size: 1.3rem;
  }

  .calendar-button {
    @media (max-width: $mobileBreakpoint) {
      display: none;
    }
  }

  .calendar-button {
    top: 0;

    .anticon {
      font-size: 20px;
      width: 20px;

      svg {
        width: 20px;
      }
    }
  }

  @media (max-width: $mobileBreakpoint) {
    .user-dropdown-container {
      .avatar .label {
        display: none;
      }
    }
  }
}

.user-menu-highlight {
  color: $mainBarBaseColor !important;
}

.floating-create-button-container {
  position: fixed;
  bottom: 5.5rem;
  right: 1.2rem;

  .ant-btn {
    border-radius: 50%;
    width: 3rem;
    height: 3rem;
    @include with-shadow;
  }

  .icon {
    margin: 0;
    fill: white !important;
    transform: translate(-50%, -50%);
    position: absolute;
    top: 50%;
    left: 50%;
    width: 1rem;
  }
}
