@import "../Variables";

.input {
  position: relative;
  display: inline-block;
  box-sizing: border-box;

  input,
  textarea {
    box-sizing: border-box;
  }

  textarea {
    overflow: hidden;
  }

  .prefix,
  .suffix {
    position: absolute;
    transform: translateY(-50%);
    top: 50%;
    color: $neutralDark;
    z-index: 2;

    .clear-button {
      cursor: pointer;
      margin-right: 0.2rem;
      position: relative;
      display: inline-block;

      .anticon {
        color: $neutralDark;
      }

      &::before {
        content: "";
        position: absolute;
        top: -2px;
        left: -3px;
        width: calc(100% + 6px);
        height: calc(100% + 4px);
        background-color: transparent;
      }

      &:hover {
        .anticon {
          color: $accent;
        }
      }
    }
  }

  .prefix {
    left: 0rem;
    width: 2rem;
    display: flex;
    justify-content: center;
  }

  .suffix {
    right: 0.3rem;
  }

  &.disabled {
    &.with-prefix {
      padding-left: 0;

      .prefix {
        color: $standardText;
      }
    }
  }

  &.show-border {
    input,
    textarea {
      border: 1px solid darken($neutral, 7);
    }

    input:disabled,
    textarea:disabled {
      background-color: desaturate($background, 30);
    }
  }

  &.centered {
    input,
    textarea {
      text-align: center;
    }
  }

  width: 10rem;
  max-width: 100%;
  input,
  textarea {
    width: 10rem;
    max-width: 100%;
  }

  &.full-width {
    width: 100%;
    input,
    textarea {
      width: 100%;
    }
  }

  &.flex-grow {
    flex-grow: 1;

    input,
    textarea {
      width: 100%;
    }
  }

  input,
  textarea {
    padding: 0.2rem 0.5rem;
    padding-left: 0.3rem;
    border-radius: $borderRadius;
    border: 1px solid transparent;
    background-color: transparent;
    transition: background-color 200ms, border 200ms;
    resize: none;
  }

  &.with-prefix {
    input,
    textarea {
      padding-left: 2.2rem;
    }
  }

  &.with-suffix {
    input {
      padding-right: 2rem;
    }
  }

  &.enabled {
    &:hover input,
    &:hover textarea,
    textarea:focus,
    input:focus {
      border-color: $accent;
      background-color: $white;
      outline: unset;
      box-shadow: unset;
    }
  }

  .ant-mentions {
    border: unset;
  }
}
