@import "../../Variables";

.hierarchy-panel {
  flex-shrink: 0;
  height: 100%;
  padding: 0.3rem 0.5rem !important;
  background-color: $white;
  text-align: left;
  user-select: none;
  overflow-y: auto;
  position: relative;

  &.is-preview-visible {
    opacity: 0.3;
    pointer-events: none;
  }

  .border-drag {
    right: 0;
    top: 0;
    height: 100%;
    width: 5px;
    z-index: 2;
    opacity: 1;
    cursor: col-resize;
    transition: all 300ms;
    border-right: 1px solid $neutral;

    &:hover {
      border-right: 3px solid $accent;
    }
  }

  .back-button {
    margin-top: 0.5rem;
    margin-left: auto;
    margin-right: auto;
    width: calc(100% - 1rem) !important;
    display: block;
  }

  .hierarchy-tree {
    overflow-y: auto;
    height: 100%;
    margin-right: -3px;
  }

  .ant-tree-node-content-wrapper {
    align-items: center;
    display: flex;

    .ant-tree-iconEle {
      display: flex !important;
      align-items: center;
    }
  }

  .preview-visible-text {
    display: none;
  }

  .ant-tree-title {
    font-size: $extraSmallFontSize;
  }

  .ant-tree .ant-tree-treenode {
    margin-bottom: -3px;
  }

  .ant-tree-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .hierarchy-item-label {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    * {
      line-height: 1.5;
    }

    .extra-item-icons {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      position: relative;
      top: 2px;

      & > *:first-child {
        margin-left: 0.3rem;
      }
    }

    .input {
      width: 100%;
      input {
        width: 100%;
      }
    }

    .visibility-button {
      position: relative;
      padding: 0.2rem;
      display: flex;
      justify-content: center;
      align-items: center;

      &::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: calc(100% + 0.5rem);
        height: calc(100% + 0.5rem);
        border-radius: 50%;
      }

      .anticon {
        display: flex;
      }

      &:hover {
        .anticon {
          color: $accent;
        }
      }
    }

    .anticon {
      color: $standardText;
      display: inline-block;
      position: relative;
      transition: all 300ms;
      font-size: 0.8rem;
    }

    &.root-element {
      user-select: none;
      pointer-events: none;
      * {
        user-select: none;
      }
    }
  }

  .ant-tree-treenode-selected {
    .anticon {
      color: $standardText;
    }
    &::before {
      background-color: $background !important;
    }
  }

  .ant-tree .ant-tree-node-content-wrapper {
    padding-left: 0;
  }

  .panel-title {
    margin-left: 1rem;
    font-size: $smallFontSize;
    margin-bottom: 0.5rem;
    margin-top: 0rem;
    font-weight: 500;
    display: block;
    border-bottom: 1px solid $border;
    padding-left: 0rem;
    padding-bottom: 0.5rem;
    margin-right: 1.5rem;
  }

  .icon {
    width: 16px;
    height: 24px;
    fill: $standardText;
  }

  .icon-paperclip.icon {
    path {
      fill: $standardText;
    }
  }

  .input-container.enabled input {
    background-color: $white !important;
  }

  .input-container.disabled input {
    user-select: none;
    pointer-events: none;
  }

  .ant-tree-switcher,
  .ant-tree-draggable-icon {
    svg {
      position: relative;
      top: 4px;
    }
  }
}
