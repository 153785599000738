@import "../Variables";

.batch-create-invoices-page {
  max-width: 1900px !important;

  .card {
    .info-item {
      margin-bottom: -0.5rem;
      margin-top: -1rem;
    }
  }

  .icon-has-timesheets-without-quote,
  .icon-has-timesheets-linked-to-rejected-quote-line-items,
  .icon-has-timesheets-linked-to-rejected-quotes {
    color: $bad;
    font-size: 1.2rem;
    margin-left: 0.5rem;
    vertical-align: middle;
    cursor: pointer;
  }

  .icon-has-timesheets-on-hold {
    color: $color4;
    font-size: 1.2rem;
    margin-left: 0.5rem;
    vertical-align: middle;
    cursor: pointer;
  }

  .actions {
    display: flex;
    gap: 0.5rem;
  }

  .minimum-hours-input {
    input {
      padding-right: 3rem !important;
      text-align: right;
    }
  }

  .project-card-actions {
    display: flex;
    gap: 0.5rem;

    .info-item {
      .label-container {
        text-align: center;
      }

      .value-container {
        text-align: center;
      }
    }
  }
}
