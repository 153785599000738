.holiday-modal {
  width: 650px !important;

  .ant-table-content {
    overflow-x: auto;
  }

  .ant-picker {
    width: 100%;
  }

  .ant-picker-panel-layout {
    display: none !important;
  }

  .submit-container {
    padding-left: 40%;
    padding-top: 1rem;
  }
}

@media (max-width: 720px) {
  .holiday-request-date-picker {
    .ant-picker-panel:nth-child(2) {
      display: none !important;
    }

    .ant-picker-header-next-btn,
    .ant-picker-header-super-next-btn {
      visibility: unset !important;
    }
  }
}
