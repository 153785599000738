.financials-recalculation-card {
  .info-item {
    .main-content {
      align-items: center;
    }
    .value-container {
      width: 100%;
      max-width: 300px;
    }
  }

  .ant-progress {
    display: flex;
    align-items: center;
    padding: 0;
    margin: 0;
  }
}
