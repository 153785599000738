@import "../../Variables";

.purchase-order-line-items-card {
  .ant-table-expanded-row > .ant-table-cell {
    background-color: $neutral !important;
    padding: 2rem 1rem !important;
  }

  .ant-table-content {
    overflow-x: auto;
  }

  .ant-table-wrapper .ant-table table {
    min-width: 1400px;
  }

  .ant-table.ant-table-scroll-horizontal .ant-table-content {
    overflow-x: auto !important;
    overflow: unset !important;
  }

  th.ant-table-cell {
    text-align: center;
    &.amount-column {
      text-align: right;
    }
  }

  .ant-table-cell {
    text-align: right;

    // button.ant-btn {
    //   width: 35px !important;
    // }

    .input-container {
      display: flex;
    }

    .actions {
      display: flex;
      gap: 0.5rem;
      justify-content: center;
    }

    // & > [class^="ant-"],
    .review-target > [class^="ant-"] {
      width: 100% !important;
    }

    .ant-table-row-expand-icon {
      width: 17px !important;
      margin: 0 auto;
      display: inline-block;
    }

    &.create-task-column {
      button {
        display: inline-block;
        width: 100%;
      }
    }
  }

  .timesheet-block-tags {
    display: flex;
    gap: 0.3rem;
  }

  @keyframes fade-in-out {
    0% {
      background-color: $white;
    }
    50% {
      background-color: $background;
    }
    100% {
      background-color: $white;
    }
  }

  .line-item-highlighted {
    animation-name: fade-in-out;
    animation-duration: 1s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: 5;
    animation-delay: 1.5s;
  }

  .line-item-go-to-task-button {
    height: 4rem;
  }

  textarea {
    min-height: 2rem;
    top: 3px;
  }

  .ant-checkbox-wrapper {
    width: 100%;
    .ant-checkbox {
      display: block;
      margin: 0 auto;
    }
  }

  .add-new-line-item-button {
    margin-bottom: 1rem;
  }

  .ant-table-tbody > tr.ant-table-row {
    cursor: auto !important;
  }

  .total-container {
    display: flex;
    justify-content: flex-end;
    margin-top: 1rem;
    margin-right: 1rem;

    .total-inner-container {
      width: 19rem;
      display: flex;
      flex-direction: column;

      .total-item {
        display: flex;
        justify-content: space-between;
        margin-bottom: 0.5rem;
        font-size: 1rem;

        .label {
          width: 7rem;
          text-align: right;
        }

        .value {
          text-align: right;
        }

        &.grand-total-item {
          font-size: 1.4rem;
          font-weight: 500;
          border-top: 1px solid $neutral;
          border-bottom: 1px solid $neutral;
        }
      }
    }
  }
}
