@import "../../Variables";

.review-thread-item {
  text-align: left;
  margin-bottom: 0.5rem;
  position: relative;
  font-size: 0.8rem;
  border-bottom: 1px solid $border;

  .ant-card {
    border-width: 2px !important;
    border-style: solid !important;
    border-color: transparent !important;
  }

  &.START .item-content {
    margin-top: 0.3rem;
  }

  &.highlighted {
    .ant-card {
      border-color: $accent !important;
    }
  }

  &.viewable {
    cursor: pointer;
    &:hover {
      .ant-card {
        border-color: $accent;
        background-color: $hover;
      }
    }
  }

  .ant-card-body {
    padding: 0.3rem 0.4rem 0rem;
  }

  .textarea {
    margin-bottom: 0.5rem;

    .textarea-content {
      padding: 0.3rem;
    }
    // margin-left: -0.3rem;
    // padding-left: 0.3rem;
  }

  .author-tag,
  .reviewer-tag {
    margin-left: 0.5rem;
  }

  .main-content {
    display: flex;
    flex-direction: column;

    .header {
      width: 100%;
      display: flex;
      align-items: center;
      padding-right: 0.5rem;
      gap: 0.5rem;

      .actions {
        display: flex;
        gap: 0rem;
        margin-right: -0.7rem;
        justify-content: flex-end;
        margin-left: auto;

        button:not(.finish-editing-button) {
          border: unset;
          box-shadow: unset;
          border-radius: 50%;
          transition: unset;
          margin: 0;

          &:hover {
            background-color: $hover;
          }

          &.reply-button {
            fill: $accent;

            .icon {
              margin: 0;
            }
          }
          &.mark-as-resolved {
            color: $accent;
          }

          &.more {
            .icon {
              transform: scale(0.6);
              position: relative;
              fill: $neutralDark;
              left: 4px;
            }
          }
        }
      }
    }

    .timestamp {
      opacity: 0.5;
      margin-top: -0.2rem;
      display: block;
      font-size: 0.7rem;
      font-weight: 500;
    }

    .item-content {
      width: 100%;
      // padding-left: 0.3rem;

      .content {
        margin-left: -0.3rem;
        padding-left: 0.3rem;
        overflow-y: hidden;
        margin-bottom: -0.3rem;

        .static-content {
          display: block;
          margin-bottom: 0.6rem;
        }
      }

      .input {
        margin-bottom: -0.3rem;
        margin-left: -0.3rem;
        padding-top: 0;
        padding-bottom: 0;
        resize: none;
        overflow-y: hidden;
      }
    }
  }

  .content {
    white-space: pre-wrap;

    .deleted {
      text-decoration: line-through;
    }

    .deleted-marker {
      color: darken($neutralDark, 10);
      margin-left: 0.5rem;
    }

    .edited-marker {
      color: darken($neutralDark, 20);
      color: $standardText;
      font-weight: 500;
      margin-right: 0.5rem;
    }

    .resolved-marker {
      color: $good;
      font-weight: 500;

      .anticon {
        position: relative;
        top: 2px;
      }
    }
  }

  .hover-container {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(white, 0.9);
    border-radius: $borderRadius;
    top: 0;
    left: 0;
    transition: all 200ms;
    display: none;
    justify-content: center;
    align-items: center;
  }

  button.resolve-item {
    .anticon {
      top: 1px !important;
      margin-right: 0;
    }
  }
}

.review-reply-list {
  margin-left: 2rem;
}

.review-thread-item-more-menu {
}
