@import "../../Variables";

.create-folder-modal {
  .ant-space-item {
    width: 100%;
  }
  .submit-container {
    text-align: center;
  }
}
