@import "../Variables";

.report-page {
  padding-top: 0.5rem;
  display: flex;
  justify-content: center;
  overflow-y: hidden;
  height: 100%;
  gap: 0.5rem;

  .page-title-container {
    display: flex;
    align-items: center;

    .page-title {
      margin-left: 1rem;
    }
  }

  .card-header > .ant-space {
    display: flex;
    flex-wrap: wrap;

    .card-actions {
      display: flex;
      gap: 0.5rem;
      flex-wrap: wrap;

      button {
        margin-left: 0;
      }
    }
  }

  @media (max-width: 1450px) {
    .form-template-actions {
      display: none;
    }
  }

  @media (max-width: 1230px) {
    .cloud-file-history-button {
      display: none;
    }
  }

  @media (max-width: 1030px) {
    .preview-switch-container {
      display: none;
    }
  }

  .report-user-fields-container {
    padding-right: 0;
  }

  .report-container {
    overflow-y: auto;
    margin-bottom: 10px;
    height: 100%;
    flex-shrink: 0;
    width: $reportWidth + 20px;

    .report-actions {
      padding-top: 1rem;
      text-align: right;
    }
  }

  &.no-fields {
    flex-direction: column;
    align-items: center;

    .report-container {
      overflow-y: visible;
      margin-bottom: 4rem;
    }
  }

  .pdf-renderer {
    margin: 0 auto;
  }
}

.preview-switch-container {
  font-weight: 500;

  .value-container {
    display: flex;
    justify-content: center;
  }
}

.report-field-context-menu {
  .ant-tooltip-inner {
    background-color: $white;
  }

  .ant-tooltip-arrow {
    .ant-tooltip-arrow-content {
      background-color: $white;
    }
  }

  .attachment-buttons-container {
    display: flex;
    flex-direction: column;

    .title {
      width: 100%;
      text-align: center;
      font-weight: 500;
    }

    button {
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;
    }
  }

  .text-options-panel {
    display: flex;
    margin: 0 auto;
    padding: 0.3rem 1rem;
    align-items: center;
    justify-content: center;

    .text-option-item {
      display: flex;
      align-items: center;
      &:not(:last-child) {
        margin-right: 1rem;
      }

      .label {
        margin-right: 0.5rem;
        color: $standardText;
      }

      .input {
        display: inline-block;
        position: relative;

        .ant-input-number {
          width: 60px;
        }

        .color-box {
          width: 22px;
          height: 22px;
          border: 2px solid $neutral;
          position: relative;
          border-radius: $borderRadius;

          box-sizing: border-box;
          cursor: pointer;
        }

        .twitter-picker {
          position: absolute !important;
          top: 35px;
          left: -10px;

          div[title="#ffffff"] {
            border: 2px solid $neutral;
          }
        }
      }
    }
  }
}

.deleted-attachments-container,
.encrypted-attachments-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 1rem;
}

.report-warning-deleted-attachments-container-modal {
  width: 800px !important;
}
