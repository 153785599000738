@import "../../Variables";

.send-task-files-modal {
  width: 900px !important;

  .selected-attachments-container {
    margin-top: -1.5rem;
  }
}

.send-task-files-attachments-modal {
  width: 1100px !important;
}
