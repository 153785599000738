@import "../Variables";

.lazy-load-list {
  .running-total {
    position: sticky;
    top: 0;
    z-index: 2;
    background-color: $neutralDark;
    display: block;
    color: white;
    padding: 0.1rem 1rem;
    width: 6rem;
    font-weight: 500;
    border-radius: $borderRadius;
    left: 50%;
    font-size: 1.5rem;
    transform: translateX(-50%);
  }

  .lazy-load-item {
    position: relative;

    .lazy-load-scroll-marker {
      width: 10px;
      height: 10px;
      // background-color: hotpink;
      z-index: 10;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      pointer-events: none;
      opacity: 0;
    }
  }
}
