@import "../../Variables.scss";

.activity-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem;
  gap: 0.5rem;

  &:not(:last-child) {
    border-bottom: 1px solid $border;
  }

  .item-avatar {
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 20px;
    }
  }

  .item-content {
    font-weight: 500;
    width: 100%;
  }

  .item-date {
    font-weight: 500;
    color: darken($neutralDark, 10);
    flex-shrink: 0;
  }

  .comment-content {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    font-weight: normal;

    .comment-actions {
      display: flex;
      margin-bottom: -0.5rem;
      gap: 1.5rem;
    }

    .textarea {
      margin-top: 0.5rem;
    }
  }
}
