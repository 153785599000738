@import "../Variables";

.task-id-tag.ant-tag {
  position: relative;
  top: -1px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 0.7rem;
  padding-left: 5px;
  padding-right: 5px;
  min-width: 7.5rem;

  .priority-marker {
    color: white;
    font-size: 0.7rem;
    margin-right: 0.3rem;
    flex-shrink: 0;

    // this is to prevent an alignment issue when the star for priority clients shows up
    margin-top: -4px;
    position: relative;
    top: 0.15rem;
  }
}
