@import "../../Variables";

.review-sheet-activity {
  width: 25rem;
  flex-shrink: 0;
  padding: 0rem 0.5rem 0.5rem;
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
  overflow: auto;
  overflow-x: hidden;
  border-left: 1px solid $neutral;
  background-color: $background;

  @media (min-width: 1600px) {
    width: 30rem;
  }

  .activity-title,
  .scrollable-content,
  .review-comment-box {
    transition: opacity 300ms;
    transition-delay: 300ms;
    opacity: 1;
  }

  .toggle-activity {
    position: absolute;
    left: 0rem;
    top: 0rem;
    padding: 0.4rem 0.15rem;
    z-index: 1;
    cursor: pointer;
    border-left: unset;

    .anticon {
      color: $neutralDark;
    }

    &:hover {
      background-color: $hover;
    }
  }

  &.is-closed {
    width: 1.2rem;
    border-left: unset;
    background-color: $white;
    border-left: 1px solid $neutral;
    overflow-x: hidden;

    .scrollable-content {
      position: absolute;
      opacity: 0;
      width: 0;
    }

    .activity-title,
    .review-comment-box {
      display: none;
    }

    .review-activity-list {
      display: none;
    }
  }

  .activity-title {
    width: calc(100% + 1rem);
    text-align: left;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 1.5rem;
    padding-right: 0.5rem;
    font-size: 0.9rem;
    margin-bottom: 0rem;
    margin-left: -0.5rem;
    margin-right: -0.5rem;
    display: flex;
    background-color: $white;
    border-bottom: 1px solid $neutral;

    .include-resolved {
      margin-left: auto;

      .ant-switch-inner {
        font-size: 0.8rem;
      }
    }
  }

  .ant-divider {
    margin: 0;
  }

  .review-comment-box {
    padding-top: 0.7rem;
  }

  @media only screen and (max-width: $reviewPageMobileBreakpoint) {
    position: fixed;
    top: $reviewTopBarHeight;
    right: 0;
    background-color: $background;
    z-index: 10;
    width: 100%;
    transition: all 500ms;
    transform: translateX(0);
    height: calc(100% - $reviewTopBarHeight);

    &.is-closed {
      width: 100%;
      transform: translateX(100%);

      .section {
        opacity: 1 !important;
        display: block !important;
      }
    }

    .toggle-activity {
      .anticon {
        display: none;
      }
    }

    .activity-title {
      padding-left: 1rem;
    }
  }
}
