@import "../Variables.scss";

.non-task-reports {
  .files-and-add-button {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    align-items: center;
    flex-wrap: wrap;
    a {
      width: 100%;
      max-width: 500px;
      button {
        width: 100%;
      }
    }
  }
}
