@import "../../Variables";

.client-sidebar {
  .ant-card-body {
    padding-bottom: 0 !important;
    display: flex;
    flex-direction: column;
  }

  .action-buttons {
    margin-bottom: 0.5rem;
  }

  .create-project {
    width: 100%;
    max-width: 15rem;
    margin: 0.5rem auto;
    display: inline-block;
  }

  &.split-layout {
    .action-buttons {
      display: flex;

      button,
      a {
        width: 100%;
        margin-bottom: 0;

        &:not(:last-child) {
          margin-right: 1rem;
        }
      }
    }

    .stats {
      columns: 2;
    }
  }

  .stat {
    margin-left: 0rem;
    width: calc(100% - 1rem);
    width: calc(100% + 1rem);
    display: block;
    padding-bottom: 0.5rem !important;
    padding-top: 0.5rem !important;
    padding-left: 1rem;
    margin-left: -1rem;
    margin-bottom: 0.5rem;
    break-inside: avoid-column;

    &.static {
      input {
        margin-left: -0.4rem;
      }
    }

    &.clickable {
      cursor: pointer;
      width: calc(100% + 2rem);

      &:hover {
        background-color: $hover;
      }
    }

    .stat-label {
      display: flex;
      align-items: center;
      font-weight: 500;
      margin-bottom: -0.2rem;

      .anticon-copy {
        margin-left: 0.5rem;

        &:hover {
          color: $accent;
        }
      }
    }

    .stat-value {
      position: relative;

      .client-logo-container {
        margin-top: 0.5rem;
        width: 4rem;
        text-align: center;
        flex-shrink: 0;

        .client-logo {
          max-height: 4rem;
          max-width: 100%;
        }
      }

      &.created-by {
        margin-left: 0.2rem;

        .avatar {
          margin-left: -0.3rem;
          margin-right: 0.2rem;
        }
      }
    }
  }

  .stat-label {
    .ant-checkbox-wrapper {
      border: 1px solid transparent;
      padding: 0.3rem 0.5rem;

      .ant-checkbox {
        &::after {
          border-color: darken($accent, 10);
        }
      }

      .ant-checkbox-checked {
        .ant-checkbox-inner {
          background-color: $accent;
          border-color: $accent;
        }

        & + span {
          color: darken($accent, 10);
          font-weight: 500;
        }
      }
    }
  }
  .stat-value {
    .ant-checkbox-group {
      margin-top: 0.2rem;
    }
  }

  .created-by-container {
    .avatar {
      top: 5px;
    }
  }

  .priority-client-container {
    display: flex;
    align-items: center;

    .client-priority-checkbox {
      margin-left: 1rem;
    }
  }

  .client-priority-label {
    font-weight: 500;
  }

  .logo-and-label-container {
    display: flex;
    justify-content: space-between;
  }
}
