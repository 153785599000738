@import "../Variables";

.users-filter-container {
  .users-filter {
    width: 300px;

    .ant-select-selector {
      min-height: 32px !important;

      .ant-select-selection-placeholder,
      input {
        position: relative;
      }
    }

    .ant-select-selection-item {
      color: $accent;
      padding-bottom: 0px !important;

      .label {
        position: relative;
        top: 1px;
      }
    }

    .ant-select-arrow {
      color: $accent;
    }

    &.ant-select-disabled {
      pointer-events: none;
      .ant-select-selector {
        background-color: unset;
      }
    }
  }
}

.users-filter-option .ant-select-item-option-content,
.option-user-unassigned .ant-select-item-option-content,
.ant-select-selection-item {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: center;

  .avatar-container {
    width: 100%;

    .avatar {
      position: relative;
      top: -1px;
    }

    .avatar .symbol {
      transform: scale(0.9);
      transform-origin: 50% 50%;
    }
  }
}

.ant-select-selection-item {
  .label-email {
    display: none;
  }
}
