@import "../../../Variables.scss";

.engineering-chart {
  opacity: 0;
  animation: fadeIn 0.2s ease-in-out;
  animation-fill-mode: forwards;
  animation-delay: 0.3s;

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  .engineering-actions {
    .engineering-action:not(:last-child) {
      margin-right: 1rem;
    }
  }

  .ant-card,
  .card-title {
    color: $standardText !important;
  }

  .engineering-chart-wrapper {
    text-align: center;
    font-weight: 500;
    font-size: 1.2rem;

    .engineering-chart {
      margin: 1rem auto 0 auto;
    }

    .engineering-chart-title {
      margin-bottom: 1rem !important;
    }

    .chart {
      display: flex;
      position: relative;

      .preloader {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgba($white, 0.7);
        z-index: 1;
      }
    }
  }
}
