@import "../../Variables.scss";

.metadata-card {
  position: relative;

  .ant-card-body {
    padding-top: 0.2rem;
    display: flex;
    justify-content: space-between;
  }

  .next-previous-buttons {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;

    button {
      height: 100px;
      z-index: 1;
    }
  }

  .metadata-container {
    display: flex;
    position: relative;
    gap: 1rem;
    flex-wrap: wrap;
    margin-top: 0.5rem;
    justify-content: center;
    padding-left: 2.5rem;
    padding-right: 2.5rem;

    .priority-marker {
      margin-right: 0.5rem;
    }

    .metadata-item {
      width: calc(20% - 1rem);
      min-width: 13rem;
      margin-left: -0.5rem;
      padding-left: 0.5rem;

      &.clickable {
        cursor: pointer;

        &:hover {
          background-color: $background;
        }
      }

      .item-label {
        margin: 0;
        margin-top: 0.5rem;
        margin-bottom: 0.3rem;
        font-size: 0.8rem;
        font-weight: 500;
      }

      .ant-select,
      .ant-picker,
      .ant-input-number,
      input {
        width: 100%;
      }

      .item-value {
        height: 2.2rem;
      }

      .item-value-static {
        margin-top: 0.6rem;
      }
    }
  }
}
