@import "../../../Variables.scss";

.template-variables {
  .action-buttons {
    display: inline-flex;
    gap: 0.5rem;
  }

  .color-display {
    display: flex;
    gap: 0.5rem;
    align-items: center;

    .color-preview {
      width: 1rem;
      height: 1rem;
      border-radius: $borderRadius;
    }
  }
}
