@import "../../Variables";

.task-sidebar {
  // margin-top: 0.55rem !important;

  .ant-card-body {
    padding-bottom: 0 !important;
  }

  .action-buttons {
    margin-bottom: 0.5rem;
  }

  .file-item {
    border: 1px solid $neutral;
    padding: 0.2rem 0.5rem;
    border-radius: $borderRadius;
    width: 100%;
    display: block;
    margin-top: 0.4rem;
    cursor: pointer;
    &:hover {
      background-color: $hover;
    }
  }

  .multiple-assigned-users-container {
    display: flex;
    flex-direction: column;
  }

  .edit-multiple-assigned-users-button {
    margin-top: 0.3rem;
    margin-bottom: 0.3rem;
  }

  .client-name,
  .assigned-to-name,
  .project-name {
    font-size: 0.85rem;
    font-weight: normal;
    color: darken($neutralDark, 5);
    margin-top: 0.5rem;
    display: inline-block;
  }

  button.request-review,
  button.go-to-review {
    margin-bottom: 1rem;
    width: 100%;
  }

  .mark-as-finished,
  .mark-as-not-finished {
    width: 100%;
  }

  &.split-layout {
    .action-buttons {
      display: flex;

      button,
      a {
        width: 100%;
        margin-bottom: 0;

        &:not(:last-child) {
          margin-right: 1rem;
        }
      }
    }

    .ant-card-body {
      padding-bottom: 1rem !important;
    }

    .stats {
      columns: 2;
    }
  }

  .stat {
    margin-left: 0rem;
    width: calc(100% - 1rem);
    width: calc(100% + 1rem);
    display: block;
    padding-bottom: 0.5rem !important;
    padding-top: 0.5rem !important;
    padding-left: 1rem;
    margin-left: -1rem;
    margin-bottom: 0.5rem;
    break-inside: avoid-column;

    &.clickable {
      cursor: pointer;
      width: calc(100% + 2rem);

      &:hover {
        background-color: $hover;
      }
    }

    .stat-label {
      display: flex;
      align-items: center;
      font-weight: 500;
      margin-bottom: -0.2rem;

      .anticon-copy {
        margin-left: 0.5rem;

        &:hover {
          color: $accent;
        }
      }
    }

    .task-estimated-hours-sidebar-input input {
      width: 100%;
      margin-left: -0.4rem;
    }

    .quote-line-item {
      border: 1px solid $neutral;
      border-radius: $borderRadius;
      padding: 0.25rem 0.5rem;
      margin-top: 0.5rem;
      margin-left: -0.5rem;

      .info-and-actions {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      button {
        border: unset;
      }
    }

    &.linked-tasks-container {
      .stat-label {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      .anticon {
        cursor: pointer;
      }

      .linked-tasks-items {
        margin-top: 10px;
      }

      .linked-task-item {
        border: 1px solid $neutral;
        border-radius: $borderRadius;
        margin-left: -0.5rem;
        margin-bottom: 0.5rem;
        padding: 0.25rem 0.5rem;
        button {
          border: unset;
        }

        .info-and-actions {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }

        .dashboard-item-tags {
          margin-top: 0.2rem;
        }
      }
    }

    .stat-value {
      position: relative;
      white-space: break-spaces;

      &.stat-value-block {
        display: block;
      }

      div.input {
        margin-left: -0.3rem;
        transition: all 300ms;

        &.show-border {
          margin-left: 0;
          margin-top: 0.2rem;
        }
      }

      .ant-input-number {
        width: 100%;
        padding-left: 0.5rem;
      }

      .value-prefix {
        position: absolute;
        left: 0.5rem;
        top: -0.15rem;
        z-index: 1;
      }
      .edit-icon {
        font-size: 1rem;
      }

      .ant-input-number {
        margin-top: 0.3rem;
      }

      input.input {
        margin-left: -0.4rem;
        width: calc(100% + 0.8rem);
      }

      .ant-switch {
        margin-top: 0.5rem;
      }

      .ant-radio-group {
        margin-top: 0.3rem;
      }

      .ant-select,
      .ant-picker {
        width: calc(100% + 1rem);
        max-width: calc(100% + 1rem);
        margin-left: -0.1rem;
      }

      .assigned-to-picker {
        margin-left: -0.2rem;
      }

      &.created-by {
        margin-left: 0.2rem;

        .avatar {
          margin-left: -0.3rem;
          margin-right: 0.2rem;
        }
      }
    }

    .ant-picker {
      margin-left: -0.1rem;
      margin-top: 0.2rem;
    }
  }

  .custom-field-map-wrapper .main-content {
    justify-content: space-between;
    margin-bottom: 0.4rem;
  }

  .stat-label {
    .ant-checkbox-wrapper {
      border: 1px solid transparent;
      padding: 0.3rem 0.5rem;

      .ant-checkbox {
        &::after {
          border-color: darken($accent, 10);
        }
      }

      .ant-checkbox-checked {
        .ant-checkbox-inner {
          background-color: $accent;
          border-color: $accent;
        }

        & + span {
          color: darken($accent, 10);
          font-weight: 500;
        }
      }
    }
  }
  .stat-value {
    .ant-checkbox-group {
      margin-top: 0.2rem;
    }
  }

  .created-by-container {
    .avatar {
      top: 5px;
    }
  }
}
