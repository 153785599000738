@import "../../Variables";

.timesheet-block-modal {
  width: 1300px !important;
  max-width: 95vw;

  &.no-scroll {
    .ant-modal-content {
      overflow-y: hidden !important;
      max-height: 100vh !important;
    }
  }

  .ant-select {
    max-width: unset !important;
  }

  .submit-container {
    padding-left: 33%;
  }

  .see-task-details-button {
    margin-left: 0.5rem;
  }

  @media (max-width: 500px) {
    .see-task-details-button {
      display: block;
      margin-left: 0;
    }
  }
}
