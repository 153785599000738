@import "../../Variables.scss";

.purchase-order-activity-card {
  .activity-filters {
    display: flex;
    gap: 3rem;
    align-items: center;
  }

  .purchase-order-filter-item {
    display: flex;
    gap: 0rem;
    align-items: center;

    .label {
      font-weight: 500;
      font-size: 0.8rem;
    }
  }

  .ant-table.ant-table-scroll-horizontal .ant-table-content {
    overflow: visible !important;
  }

  .ant-table-cell {
    white-space: pre-wrap !important;
  }
}
