@import "../../Variables.scss";

.dashboard-view-types {
  display: flex;
  margin-top: -0.5rem;
  margin-bottom: 0.5rem;
  border-radius: $borderRadius;
  padding-top: 0.5rem;
  padding-left: 0.05rem;
  padding-bottom: 0.5rem;

  .button-container {
    padding: 0 1rem;

    &:first-child {
      padding-left: 0;
    }

    &:not(:last-child) {
      border-right: 1px solid $neutral;
    }

    button {
      border: unset;
      background-color: transparent;
      color: $standardText;
      padding: 0.15rem 0;
      border-bottom: 2px solid transparent;
      font-weight: bold;
      cursor: pointer;
      outline: unset;
      display: flex;
      align-items: center;
      font-size: 0.8rem;
      justify-content: center;

      &:hover {
        border-bottom-color: $border;
      }

      .icon {
        margin-right: 0.5rem;
      }

      .icon {
        stroke: $standardText;
        fill: $standardText;
        width: 12px;
      }

      &.selected {
        color: $accent;
        border-bottom-color: $accent;

        .icon {
          stroke: $accent;
          fill: $accent;
        }
      }
    }

    &.disabled-add-on {
      pointer-events: none;
      opacity: 0.4;

      button {
        color: $accent;

        .icon {
          fill: $accent;
        }
      }

      .add-on {
        font-size: 0.6rem;
        margin-left: 0.5rem;
        background-color: $accent;
        color: white;
        padding: 0rem 0.5rem 0.1rem;
        border-radius: $borderRadius;
        position: relative;
        top: 1px;
      }
    }
  }
}
