@import "../../Variables.scss";

.metadata-card {
  .ant-card-body {
    padding-top: 0.2rem;
    display: flex;
    justify-content: space-between;
  }

  .metadata-container {
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
    margin-top: 0.5rem;
    justify-content: center;

    .priority-marker {
      margin-right: 0.5rem;
    }

    .metadata-item {
      width: calc(20% - 1rem);
      min-width: 13rem;
      margin-left: -0.5rem;
      padding-left: 0.5rem;

      &.clickable {
        cursor: pointer;

        &:hover {
          background-color: $background;
        }
      }

      .item-label {
        margin: 0;
        margin-top: 0.5rem;
        margin-bottom: 0.3rem;
        font-size: 0.8rem;
        font-weight: 500;
      }

      .ant-select,
      .ant-picker,
      .ant-input-number,
      input {
        width: 100%;
      }

      .item-value {
        height: 2.2rem;
      }

      .item-value-static {
        margin-top: 0.6rem;
      }
    }
  }
}

.client-contact-dropdown {
  width: 500px !important;
  max-width: 100vw !important;
}
