@import "../Variables";

.user-agreement {
  width: 100vw;
  overflow-x: hidden;

  .main-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 2rem;
    padding-top: 4rem;
    position: relative;

    .page-title {
      font-size: 3rem;
      font-weight: 500;
      letter-spacing: -1px;
      line-height: 4rem;
      position: relative;
      color: $standardText;
    }

    .page-subtitle {
      font-size: 1.2rem;
      letter-spacing: -0.3px;
      line-height: 2.1rem;
      margin-top: 1rem;
      position: relative;
      opacity: 0.7;
      max-width: 700px;
      margin-left: auto;
      margin-right: auto;
      color: rgba($standardText, 0.7);
    }

    .agreement-details {
      text-align: left;
      margin-left: auto;
      margin-right: auto;
      max-width: 700px;
      font-size: 1rem;
      letter-spacing: -0.3px;
      line-height: 2.1rem;
      position: relative;

      h1,
      .c4 {
        margin-top: 2rem;
        margin-bottom: 1rem;
      }

      h2 {
        margin-top: 1.5rem;
        margin-bottom: 1rem;
      }

      h3 {
        margin-top: 1rem;
        margin-bottom: 1rem;
      }

      .c2 {
        font-weight: bold;
      }
    }

    .cta-container {
      margin-bottom: 4rem;
    }
  }

  @media (max-width: 1000px) {
    .main-section {
      .page-title {
        font-size: 2.5rem;
        line-height: 1.3;
      }

      .page-subtitle {
        margin-top: 0.5rem;
      }

      .cta-container {
        margin-top: 1rem;
      }
    }

    .cta-container {
      transform: scale(0.8);
    }
  }

  @media (max-width: 550px) {
    .main-section {
      padding-top: 2rem;

      .page-title {
        font-size: 2rem;
        line-height: 1.2;
      }

      .page-subtitle {
        margin-top: 0.5rem;
      }

      .cta-container {
        margin-top: 1rem;
        margin-bottom: 0;

        button {
          height: 40px;
          width: 150px;
        }
      }

      .image-with-details {
        margin-top: 2rem;
      }
    }
  }

  @media (max-width: 430px) {
    .main-section {
      padding-top: 1rem;

      .page-title {
        font-size: 1.5rem;
        line-height: 1.3;
      }

      .page-subtitle {
        margin-top: 0rem;
        font-size: 1rem;
      }
    }
  }
}
