@import "../../Variables";

.organisation-settings-page {
  height: 100%;
  overflow-y: hidden;
  padding-top: 0.2rem;

  .ant-tabs-nav-wrap {
    justify-content: center;
  }

  .ant-tabs-content-holder {
    width: 1100px;
    max-width: 100%;
    margin: 0 auto;
  }

  .tabs {
    overflow-y: hidden;

    .ant-tabs-tab {
      padding-top: 0;
    }

    .ant-tabs-tabpane {
      padding-right: 0.3rem;
      margin-bottom: 0.5rem;
    }

    .ant-tabs-content-holder {
      height: calc(100vh - $navigationBarHeight - 5rem);
      overflow-y: auto;
    }
  }

  .setting-checkbox {
    display: flex;
    align-items: center;

    &:not(:last-child) {
      margin-bottom: 0.7rem;
      padding-bottom: 0.7rem;
      border-bottom: 1px solid $border;
    }

    .ant-checkbox {
      margin-right: 0.4rem;

      & + span {
        width: 100%;
      }
    }

    .ant-checkbox-inner {
      position: relative;
      top: -1px;
    }
  }

  .setting-name {
    color: $standardText;
    font-weight: 500;
  }

  .setting-description {
    opacity: 0.6;
  }

  .info-item .setting-description {
    display: block;
    margin-bottom: 0.2rem;
  }
}
