@import "../Variables";

.clients-page {
  .main-actions {
    margin-bottom: 0.5rem;
    display: flex;
    align-items: center;
    margin-top: 1rem;
    margin-left: 1rem;
    margin-right: 1rem;

    .clients-page-title {
      font-size: 0.85rem;
      font-weight: 500;
      color: darken($neutralDark, 10);
      margin-right: auto;
      position: relative;
      top: 3px;
    }

    .client-sort {
      width: 150px !important;
    }

    .create-client {
      margin-left: 1rem;
    }
  }

  .batch-create {
    margin-right: 1rem;
  }

  .export-client-contacts-list {
    margin-left: 1rem;
  }

  .main-content {
    background-color: $background;
    padding: 1rem;
    border-radius: $borderRadius;
  }

  .lazy-load-list {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }

  .client-item {
    width: 100%;
    background-color: $white;
    padding: 0.5rem 0.7rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 50px;

    border-radius: $borderRadius;
    border-color: transparent;
    @include with-shadow;

    .name-and-fee-overrides-tag-container {
      display: flex;
      justify-content: space-between;
      width: 300px;
    }

    .client-fee-override-tag {
      color: white;
      background-color: #19aae8;
      border: unset;
    }

    .details {
      width: 150px;
      text-align: left;
      margin-left: 0.5rem;
    }

    .client-logo-container {
      width: 150px;
    }

    .avatar {
      margin-top: 0.6rem;
    }
  }

  .ant-list-item-meta-avatar {
    order: 1;

    padding-left: 2rem;
  }

  .ant-list-item-meta-content {
    width: 200px;
    flex: unset;
    border-right: 1px solid #d6e0e4;
  }
}
