.review-activity-list {
  display: flex;
  flex-direction: column;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  scroll-behavior: smooth;
  height: 1px;
  flex-grow: 1;
  scroll-behavior: smooth;

  .ant-pagination {
    li:first-child {
      margin-right: 1rem;
    }

    li:last-child {
      margin-left: -1rem;
    }
  }

  .items {
    overflow-y: auto;
    flex-grow: 1;
    padding-right: 0.5rem;
  }
}
