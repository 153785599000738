@import "../../Variables";

.task-details-modal {
  $modalWidth: 1600px;
  position: fixed !important;
  top: 2rem !important;
  left: calc((100vw - #{$modalWidth}) / 2) !important;
  height: calc(100vh - 4rem) !important;
  width: $modalWidth !important;
  overflow-y: visible;
  margin: 0 !important;
  padding: 0 !important;
  max-width: unset !important;
  max-height: unset !important;

  @media (max-width: $modalWidth) {
    left: 1rem !important;
    width: calc(100vw - 2rem) !important;
  }

  @media (max-width: 500px) {
    left: 0 !important;
    width: 100vw !important;
    top: 0 !important;
    height: 100vh !important;

    .task-details-page {
      height: calc(100vh - 0.4rem) !important;
    }
  }

  .modal-icons {
    position: absolute;
    right: 1rem;
    top: 0.5rem;
    font-size: 1.5rem;
    display: flex;
    align-items: center;
    color: $standardText;
    z-index: 2;

    .close-button {
      margin-left: 1rem;
    }
  }

  .ant-modal-content {
    background-color: $background;
    padding: 0.3rem;

    @media (max-width: 500px) {
      border-radius: 0 !important;
      padding: 0;
      height: 100vh;
    }
  }

  .ant-modal-body {
    padding: 0 !important;
    padding-left: 1rem !important;

    @media (max-width: $mobileBreakpoint) {
      padding-left: $borderRadius !important;

      .task-details-page {
        padding-right: 0;
      }
    }
  }

  .task-details-page {
    height: calc(100vh - 4rem);
    overflow-y: auto;
    padding-top: 0;
    padding-right: 1rem;

    & > .ant-breadcrumb-wrapper > .ant-breadcrumb {
      position: sticky;
      top: 0;
      z-index: 2;
      background-color: $background;
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
    }
  }
}
