@import "../../Variables.scss";

.quote-activity-card {
  .activity-filters {
    display: flex;
    gap: 3rem;
    align-items: center;
  }

  .quote-filter-item {
    display: flex;
    gap: 0rem;
    align-items: center;

    .label {
      font-weight: 500;
      font-size: 0.8rem;
    }
  }
  .ant-table-cell {
    white-space: pre-wrap !important;
  }

  .comment-content {
    .edit-comment-button {
      margin-right: 0.7rem;
    }
  }
}
