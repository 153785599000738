@import "../Variables";

.avatar {
  position: relative;
  user-select: none;
  transition: all 200ms;
  vertical-align: middle;
  border-radius: 50%;
  border: 2px solid transparent;
  display: inline-flex;
  gap: 0.2rem;
  align-items: center;
  &.wrap {
    flex-wrap: wrap;
  }

  &.display-as-column {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &.is-user-card {
    pointer-events: none;
  }

  &.disabled {
    .label {
      opacity: 0.4;
    }
    .symbol {
      filter: saturate(0);
    }
  }

  .avatar-initials {
    font-weight: 500;
  }

  .label {
    position: relative;
    top: 1px;
    white-space: nowrap;
    font-size: $smallFontSize;
  }

  .symbol {
    color: white;
    text-align: center;
    overflow: hidden;
    background-size: cover;
    border-radius: 50%;
    display: inline-block;
    vertical-align: middle;
    position: relative;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    transition: all $transition;
    display: none;
  }

  &.show-image {
    .symbol {
      display: inline-flex;
    }
  }

  &.normal {
    .symbol {
      width: 1.6rem;
      height: 1.6rem;
    }
    .avatar-initials {
      position: relative;
      top: 0.5px;
    }
    &.draughthub {
      .symbol {
        height: 1.8rem;
        background-color: unset !important;
      }
    }
  }

  &.large {
    .symbol {
      width: 5rem;
      height: 5rem;

      .avatar-initials {
        font-size: 1.3rem;
      }
    }
  }

  &.with-border {
    .symbol {
      border: 2px solid white;
    }

    &.normal {
      .symbol {
        width: 2.2rem;
        height: 2.2rem;
      }
    }

    &.large {
      .symbol {
        width: 4.2rem;
        height: 4.2rem;

        .avatar-initials {
          font-size: 1.3rem;
        }
      }
    }
  }

  .image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    transition: all 300ms;
    background-color: $white;
    border-radius: 50%;
  }

  .edit-icon {
    opacity: 0;
    position: absolute;
    top: calc(50% - 3px);
    left: 50%;
    color: white;
    transition: all 300ms;
    transform: scale(0) translate(-1.3rem, -1.3rem);
    font-size: 1.3rem;
    margin: 0;
  }

  &.clickable {
    cursor: pointer;

    &.show-hover-animation {
      @media (hover: hover) {
        &:hover {
          transform: translateY(-6px);
          z-index: 2;
        }
      }
    }
  }

  &.without-photo {
    .symbol {
      position: relative;
      background-color: $neutralDark;

      .anticon {
        position: relative;
        top: -1px;
      }
    }
  }

  &.selected.show-hover-animation {
    transform: translateY(-1px);
    z-index: 2;
    background-color: white !important;
    color: $standardText;
    border-color: $neutralDark;
    transform: translateY(-6px);
    z-index: 2;

    .avatar-initials {
      font-weight: 500;
    }
    .symbol {
      border-width: 2px;
    }
  }

  .avatar-initials {
    transition: all 300ms;
    font-size: 0.7rem;
    text-transform: uppercase;
  }

  &.selected.in-dropdown {
    font-weight: 500;

    .label {
      color: $accent;
    }
  }
}
