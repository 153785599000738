@import "../../Variables";

.document-register-card {
  .card-title {
    font-size: 0.85rem;

    a {
      color: $standardText;
    }

    .anticon {
      margin-right: 0.5rem;
    }
  }

  .ant-divider {
    display: none;
  }
}
