@import "../../Variables";

.contact-modal {
  width: 600px !important;

  .ant-modal-title {
    text-align: center;
  }
  .submit-container {
    display: flex;
    justify-content: center;
  }
}
