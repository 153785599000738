@import "../Variables";

.drag-and-drop {
  position: relative;

  .overlay {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    z-index: 2;
    opacity: 0;
    top: -0.5rem;
    left: 0;
    width: 100%;
    height: calc(100% + 1rem);
    border-radius: $borderRadius;
    border: 2px dashed $accent;
    pointer-events: none;
    background-color: rgba(white, 0.85);
    flex-direction: column;
    transition: opacity 300ms;

    .anticon {
      font-size: 2rem;
      display: block;
      color: $accent;
    }

    .instructions {
      font-size: 0.9rem;
      color: $accent;
      font-weight: 500;
      text-align: center;
    }
  }

  &.dragging {
    .overlay {
      opacity: 1;
    }
  }
}
