@import "../Variables";

.zoomable {
  width: 100%;
  height: 100%;
  position: relative;

  &.not-loaded {
    .zoomable-inner {
      opacity: 0;
    }
  }

  &.active {
    cursor: grab;
  }

  .zoom-controls {
    position: absolute;
    right: 1rem;
    top: 1rem;
    z-index: 1;

    .zoom-control-group {
      @include with-shadow(darken($neutralDark, 5));
      border-radius: $borderRadius;
      margin-bottom: 1rem;
      background-color: $white;
    }

    .zoom-button {
      width: 2rem;
      height: 2rem;
      display: flex;
      justify-content: center;
      align-items: center;
      color: $standardText;
      cursor: pointer;
      transition: all 300ms;
      transform-origin: 50% 50%;

      &:first-child {
        border-top-left-radius: $borderRadius;
        border-top-right-radius: $borderRadius;
      }

      &:last-child {
        border-bottom-left-radius: $borderRadius;
        border-bottom-right-radius: $borderRadius;
      }

      &.zoom-control-separate {
        margin-bottom: 1rem;
      }

      &.zoom-control-with-separator {
        border-top: 1px solid $neutral;
      }

      &.enabled {
        &:hover {
          background-color: $standardText;
          color: $white;
        }
      }

      &.disabled {
        opacity: 0.2;
        cursor: not-allowed;
        background-color: $background;
      }
    }
  }

  .zoomable-spinner {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .zoomable-inner {
    position: relative;
    transform-origin: 0 0;

    &.animated {
      transition: all 400ms;
      transition-timing-function: ease-in-out;
    }
  }
}
