@import "../../Variables";

.variable-modal {
  .ant-space {
    width: 100%;
  }

  .color-preview {
    width: 1.2rem;
    height: 1.2rem;
    border-radius: $borderRadius;
    background-color: $neutralLight;
    position: absolute;
    top: 50%;
    left: 0.6rem;
    transform: translateY(-50%);
    cursor: pointer;
  }

  .color-input {
    padding-left: 2.2rem !important;
  }

  .submit-container {
    margin: 0 auto;
    margin-bottom: -2rem;

    button {
      margin-right: 1rem;
      display: block;
      margin: 0 auto;
      margin-bottom: 1rem;
      margin-left: 33%;
      width: 140px;
    }
  }
}
