@import "../../../Variables.scss";

.request-content {
  max-height: calc(100vh - $navigationBarHeight - 1.5rem);
  overflow-y: auto;
  width: 100%;

  @media (max-width: 900px) {
    max-height: unset;
    overflow-y: unset;
  }

  .form-actions-bottom {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    border-top: 1px solid $border;
    padding-top: 1rem;
  }

  .inline-report-form {
    .report-page {
      padding: 0 !important;
      overflow-y: visible !important;
      overflow-x: visible !important;

      .report-user-fields-container {
        height: unset !important;
        overflow-y: visible !important;
        overflow-x: visible !important;
        border: unset !important;
        max-width: unset;
        padding: 0 !important;
        margin: 0 !important;

        .form {
          padding: 0 !important;
          margin: 0 !important;
        }
      }
    }

    .card {
      max-height: unset !important;
      overflow-y: visible !important;
      overflow-x: visible !important;
      border: unset !important;
      box-shadow: unset !important;
    }

    .ant-card-body {
      padding: 0;
      overflow-y: visible !important;
      overflow-x: visible !important;
    }
  }
}
