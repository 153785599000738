@import "../../Variables.scss";

.multiple-assigned-users-modal {
  .option-items {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    .ant-checkbox-wrapper {
      display: flex;
      align-items: flex-start;

      .ant-checkbox {
        margin-top: 0.4rem;
      }

      .ant-checkbox-disabled {
        opacity: 0.3;
      }
    }

    .extra-messages {
      padding-left: 1.9rem;
      color: $standardText;
      opacity: 0.75;
    }
  }
}
