@import "../../Variables";

.review-page-container {
  max-width: 100% !important;
  padding-top: 0.3rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
  padding-left: 0 !important;

  .card-header,
  .ant-tabs-nav,
  .ant-tabs-nav-list {
    transition: all 300ms;
    overflow-y: hidden;
  }
  .ant-tabs-nav-operations {
    transition: all 300ms;
  }
  .ant-tabs {
    transition: all 300ms;
  }

  .actions .section {
    display: flex;
  }

  .breadcrumb-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 1;
    position: relative;
    margin-top: 0.1rem;
    padding-bottom: 0.45rem;
    flex-shrink: 0;

    .ant-breadcrumb {
      margin-bottom: 0;

      .anticon {
        position: relative;
        top: 1px;
        margin-right: 0.3rem;
      }
    }

    button {
      margin-left: 0;
      margin-right: 0.5rem;
      font-size: 0.8rem;
      padding: 0px 10px !important;
      height: 28px;
    }
  }

  .review-page-container-card {
    border-top: 1px solid $neutral;
    box-shadow: unset;
    transition: all 300ms;
    display: flex;
    height: 1px;
    flex-grow: 1;
    background-color: $white;

    .selected-tab-content {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      overflow-x: hidden;
    }
  }

  .review-sections {
    margin-top: 0.7rem;

    .review-section-approved-checkbox {
      &.disabled {
        pointer-events: none;
      }
    }

    .ant-checkbox {
      margin-left: 0.5rem;
    }

    .approved {
      margin-left: 0.5rem;
      margin-right: 0.5rem;
      color: $neutralDark;
      pointer-events: none;
    }

    .approved-old {
      color: $neutralDark;
      color: $color4;
      font-style: italic;
      pointer-events: none;
      margin-left: 0.5rem;
      // font-weight: ;
    }
  }

  .review-section-approval-icon {
    width: 16px;
    height: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid $neutral;
    border-radius: $borderRadius;

    &:hover {
      border-color: $accent;
    }

    &.approved {
      background-color: $accent;
      border-color: $accent;
      .anticon {
        color: white;
      }

      &:hover {
        background-color: darken($accent, 20);
        border-color: darken($accent, 20);
      }
    }
  }

  .ant-breadcrumb-link {
    .ant-tag {
      font-weight: normal;
      display: inline-block;

      &:first-child {
        margin-left: 0.5rem;
      }
    }
  }

  .revision-name {
    font-size: 0.85rem;
    font-weight: normal;
    color: darken($neutralDark, 5);
  }

  @media only screen and (max-width: $reviewPageMobileBreakpoint) {
    .breadcrumb-bar {
      justify-content: space-between;

      .back-button-container {
        margin-left: 0.5rem;
        margin-right: 0;
      }

      .breadcrumb-container {
        .anticon {
          padding-right: 3px;
        }

        .ant-tag {
          width: 170px;
        }
        .ant-tag:first-of-type {
          margin-bottom: 0.1rem;
        }
      }
    }

    .activity-button-container {
      background-color: $white;
      position: fixed;
      display: flex;
      z-index: 10;
      bottom: 0;
      left: 0;
      width: 100%;
      height: $reviewPageMobileNavigationBarHeight;
      gap: 1rem;
      justify-content: center;
      align-items: center;
      border-top: 1px solid darken($border, 0);
      transition: all 400ms;
      opacity: 0;
      pointer-events: none;

      button {
        transition: all 400ms;
        position: relative;
        top: 100px;
      }

      &.visible {
        opacity: 1;
        pointer-events: all;
        button {
          top: 0;
        }
      }
    }
  }
}

.review-revision-dropdown.ant-dropdown {
  .ant-dropdown-menu-item {
    .review-options-menu {
      padding: 0.5rem 0;
      font-weight: 500;
      color: $standardText;
    }

    .ant-dropdown-menu-title-content {
      font-weight: 500;

      .anticon {
        margin-right: 0.5rem;
      }

      .with-comments,
      &.with-comments {
        color: $accent !important;
      }

      .request-changes,
      &.request-changes {
        color: $bad !important;
      }

      .approve,
      &.approve {
        color: $good !important;
      }
    }

    .ant-divider {
      width: 100%;
      margin: 0rem 0;
    }
  }
}

.ant-tabs-dropdown {
  .review-section-approved-checkbox {
    margin-left: 0.5rem;
  }
}
