@import "../../Variables.scss";

.user-working-hours {
  .value-container {
    button {
      width: unset !important;
    }
  }

  .extra-content {
    margin-top: 1rem;
  }
}
