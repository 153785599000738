@import "../../Variables";

.invoice-filters {
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: sticky;
  gap: 0.5rem;
  top: 0px;
  z-index: 2;
  margin-top: -0.5rem;
  margin-bottom: 0.5rem;
  padding-top: 1rem;
  padding-bottom: 1.2rem;

  &::before {
    content: "";
    position: absolute;
    top: -1rem;
    left: 0;
    width: 100%;
    height: calc(100% + 1rem);
    background-color: $white;
  }

  span {
    position: relative;
  }

  @media (max-width: 400px) {
    .filter-button {
      padding-left: 8px;
      padding-right: 8px;
    }
  }

  .create-buttons {
    display: flex;
    gap: 0.5rem;
    margin-left: auto;
  }

  .combined-actions-button {
    margin-left: auto;
  }

  .filter-input {
    border: unset;
    border-radius: $borderRadius;
    width: 200px;
    border: 1px solid $neutral;

    .ant-input-prefix {
      margin-right: 0.6rem;

      .anticon {
        color: $standardText;
      }
    }

    .ant-input-affix-wrapper-focused {
      display: none !important;
      border: 1px solid $accent;
      display: inline-block;
    }

    input {
      color: $standardText;
      font-weight: 500;

      &::placeholder {
        color: $neutralDark;
        font-weight: normal;
      }
    }
  }
}

.invoice-filters-dropdown-overlay {
  width: 30rem;
  max-width: 100vw;
  overflow-y: auto;
  max-height: calc(100vh - 130px);
  @include with-soft-shadow;
  background-color: $white;

  .right-label {
    margin-left: 1rem;
  }

  .avatar .anticon {
    margin-right: unset !important;
  }

  .ant-dropdown-menu-title-content {
    display: flex;
    align-items: center;
  }

  .top-label {
    margin-bottom: -1rem;
    display: block;
  }

  .invoiced-in-range-container {
    width: 100%;

    .ant-picker {
      width: 100% !important;
    }
  }

  .left-label {
    margin-right: 0.5rem;
    display: inline-block;
    flex-shrink: 0;
  }

  .ant-switch {
    width: 4rem;
  }

  .flex-menu-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .invoice-filter-client,
  .invoice-filter-project {
    width: 100%;
  }
}
