@import "../../Variables.scss";

.project-budget-card {
  margin-bottom: 1rem !important;

  .info-items {
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    .info-items-column {
      width: 100%;
      display: flex;
      justify-content: space-between;
    }
  }
}
