@import "../Variables.scss";

.live-review-comment {
  position: absolute;
  width: 100%;

  &.is-animated {
    transition: top 400ms;
    transition-timing-function: ease-in-out;
  }

  &.invisible {
    opacity: 0;
  }

  &.is-new {
    left: 2rem;
    opacity: 0;
    @include with-shadow($neutralDark);
    border-radius: $borderRadius;
    z-index: 10;

    &::before {
      content: "";
      position: fixed;
      opacity: 0.3;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      background-color: $standardText;
      z-index: 5;
    }

    @keyframes fade-in {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
        left: 0rem;
      }
    }

    .ant-card {
      position: relative;
      z-index: 11;
    }

    animation-name: fade-in;
    animation-duration: 0.5s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
  }

  &.is-reply {
    position: relative;
    top: 0 !important;
    left: 0 !important;
    margin-top: 0.5rem;
  }

  .ant-card-body {
    padding: 0.3rem 0.7rem;
  }

  .reply-card {
    margin-top: 0.5rem;
    margin-left: 2rem !important;
    width: calc(100% - 2rem) !important;
  }

  .reply-box {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }

  .author-and-timestamp {
    .author,
    .timestamp {
      display: block;
      line-height: 1.2;
    }

    .timestamp {
      color: darken($neutralDark, 15);
      font-size: 0.8rem;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    .header {
      display: flex;
      gap: 0.5rem;
      align-items: center;

      .actions {
        display: flex;
        gap: 0rem;
        margin-right: -0.5rem;
        margin-left: auto;

        button {
          border: unset;
          box-shadow: unset;
          border-radius: 50%;
          transition: unset;

          .icon {
            margin: 0;
          }

          &:hover {
            background-color: $hover;
          }

          &.mark-as-resolved {
            color: $accent;
          }

          &.more {
            .icon {
              transform: scale(0.6);
              fill: $neutralDark;
            }
          }
        }
      }
    }

    .title {
      font-weight: 500;
    }

    .body {
      white-space: pre-wrap;
      display: block;
      margin-top: -0.5rem;
    }

    .buttons {
      display: flex;
      gap: 0.5rem;
    }
  }
}
