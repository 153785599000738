@import "../Variables";

.timeline-page {
  height: 100%;

  .timeline-view {
    display: flex;
    border-radius: $borderRadius;
    width: 100%;
    user-select: none;
    position: relative;
    padding-top: 0.5rem;

    &.is-dragging-block,
    &.is-resizing-block,
    &.is-modal-visible,
    &.is-locked {
      .timeline-block,
      .timeline-block * {
        pointer-events: none;
      }

      .main-view {
        overflow-x: hidden;
        padding-bottom: 10px;
      }
    }
  }

  .timeline {
    border-right: 1px solid $neutral;

    .date-headers .day {
      transition: all $transition;
      width: var(--timeline-day-cell-width);
    }

    .user-row::before {
      background-size: var(--timeline-day-cell-width) var(--timeline-day-cell-height);
      background-position: left var(--timeline-day-cell-width) top 0;
      transition: all $transition;
    }
    .user-row::after {
      background-size: calc(var(--timeline-day-cell-width) / 2) var(--timeline-day-cell-height);
      background-position: left var(--timeline-day-cell-width) top 0;
      transition: all $transition;
    }
  }

  .create-task {
    margin-right: 0.5rem;
  }

  .main-view-and-controls {
    overflow: auto;

    .controls {
      position: fixed;
      top: 3.7rem;
      right: 0.5rem;
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      gap: 0.5rem;
      margin-bottom: 0.5rem;
      z-index: 2;

      .zoom-controls {
        display: flex;
        gap: 0.5rem;
      }

      .setting {
        display: flex;
        flex-direction: column;
        align-items: center;

        &.setting-row {
          margin-top: -0.7rem;
          align-items: center;
          position: relative;
          top: 0.35rem;
          justify-content: center;

          .setting-label {
            margin: 0;
            color: $standardText;
            margin-bottom: -0.35rem;
          }

          &.switch-off {
            .setting-label {
              color: $neutralDark;
            }
          }

          & > * {
            margin: 0;
          }
        }

        .setting-label {
          display: block;
          font-size: 0.75rem;
          color: $standardText;
          font-weight: 500;
          line-height: 0.9rem;
          margin-bottom: 0.15rem;
        }

        .ant-input-number-input-wrap input {
          text-align: center;
        }

        .ant-switch {
          font-weight: 500;
          margin-top: 0.35rem;
          margin-bottom: 0.25rem;
        }
      }
    }

    .main-view {
      display: flex;
      overflow: auto;
      margin-left: 0.5rem;
      border-radius: $borderRadius;
      max-height: calc(100vh - $navigationBarHeight - 3.5rem);

      .user-list {
        width: 8rem;
        position: sticky;
        left: 0rem;
        flex-shrink: 0;
        z-index: 4;
        height: 100%;
        transition: all $transition;
        border-left: 1px solid $borderDark;

        &::after {
          content: "";
          position: absolute;
          top: 0;
          right: 0;
          width: 100%;
          height: 1px;
          background-color: $borderDark;
          z-index: 2;
        }

        &.small {
          width: 3rem;
        }

        .title {
          z-index: 1;
          position: sticky;
          top: 0px;
          display: flex;
          height: 2.5rem;
          justify-content: center;
          align-items: center;
          background-color: $hover;
          font-size: 0.75rem !important;
          font-weight: 500;
          text-transform: capitalize;
          border-right: 1px solid $borderDark;
          border-bottom: 1px solid $borderDark;
        }

        .user-item {
          height: var(--timeline-day-cell-height);
          transition: all $transition;
          display: flex;
          padding-left: 0.2rem;
          padding-right: 0.1rem;
          align-items: center;
          border-bottom: 1px solid $borderDark;
          border-right: 1px solid $borderDark;
          background-color: $hover;
          justify-content: center;

          &.is-stock-item {
            cursor: pointer;

            &:hover {
              .stock-item-name {
                color: $accent;
              }
            }
          }

          .stock-item-name {
            font-weight: 500;
            font-size: $extraSmallFontSize;
            color: $standardText;
            padding-left: 0.2rem;
            padding-right: 0.2rem;
          }

          .avatar {
            margin-top: 0;
            flex-direction: column;

            .symbol {
              width: 2rem;
              height: 2rem;
            }

            .avatar-initials {
              transition: unset !important;
            }

            .label {
              display: block;
              margin-left: 0;
              max-width: 100%;
              white-space: normal;
              line-height: 1;
              text-align: center;
              padding: 0 0.4rem;
              font-size: $extraSmallFontSize;
              font-weight: 500;
            }
          }

          &.tiny {
            .stock-item-name {
              font-size: $extraSmallFontSize;
            }
            .avatar {
              .symbol {
                width: 1.6rem;
                height: 1.6rem;
              }

              .avatar-initials {
                font-size: 0.55rem;
              }
            }
          }
        }

        @media (max-width: 600px), (max-height: 600px) {
          width: 3rem;

          .user-item {
            justify-content: center;
            padding-left: 0;

            .avatar .label {
              display: none !important;
            }
          }
        }
      }

      .timeline {
        display: flex;
        flex-direction: column;
        position: relative;
        height: 100%;

        .right-now-marker {
          position: absolute;
          top: 2.5rem;
          background-color: $accent;
          width: 2px;
          opacity: 0.7;
          pointer-events: none;
          transition: all $transition;
          z-index: 2;

          &::after {
            content: "";
            width: 0;
            height: 0;
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            border-top: 5px solid $accent;
            position: absolute;
            top: 0;
            transform: translateX(-50%);
            left: 50%;
          }
          &::before {
            content: "";
            width: 0;
            height: 0;
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            border-bottom: 5px solid $accent;
            position: absolute;
            bottom: 0px;
            transform: translateX(-50%);
            left: 50%;
          }
        }

        .weekend-highlights {
          position: absolute;
          top: 2.5rem;
          left: 0;

          .weekend-highlight {
            position: absolute;
            background-color: $hover;
            z-index: -1;
          }
        }

        .date-headers {
          display: flex;
          height: var(--timeline-date-headers-height);
          background-color: $hover;
          position: sticky;
          top: 0;
          flex-shrink: 0;
          border-bottom: 1px solid $borderDark;
          border-top: 1px solid $borderDark;
          z-index: 3;
          cursor: move;

          .day {
            display: flex;
            flex-direction: column;
            height: 100%;
            position: absolute;
            top: 0;
            align-items: center;
            justify-content: center;
            flex-shrink: 0;
            font-size: 0.75rem !important;
            font-weight: 500;
            text-transform: capitalize;
            color: $standardText;
            text-align: center;

            &:not(:last-child) {
              border-right: 1px solid $borderDark;
            }

            .label-month {
              font-size: 0.75rem;
              display: block;
              margin-bottom: -0.2rem;
              color: $standardText;
              opacity: 0.5;
            }
          }
        }

        .user-row {
          height: var(--timeline-day-cell-height);
          transition: all $transition;
          flex-shrink: 0;
          display: flex;
          position: absolute;
          left: 0;
          margin-left: -1px;
          border-bottom: 1px solid $borderDark;
          cursor: move;

          &.drag-highlight {
            background-color: rgba($accent, 0.1);
            border-top: 1px solid $accent;
            border-bottom-color: $accent;

            .project-title {
              background-color: transparent !important;
            }
          }

          &::before,
          &::after {
            content: "";
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            background-repeat: repeat-x;
          }

          &::before {
            background-image: linear-gradient(to right, $neutral 1px, transparent 1px, transparent);
          }

          &::after {
            background-image: linear-gradient(to right, rgba($neutral, 0.4) 1px, transparent 1px, transparent);
          }

          .user-row-click-area {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            z-index: 1;
          }
        }
      }
    }

    &.display-legend {
      .main-view {
        max-height: calc(100vh - $navigationBarHeight - 7rem);
      }
    }
  }

  &.zoom-level-0,
  &.zoom-level-1,
  &.zoom-level-2 {
    .timeline-block {
      .approval-status {
        display: none;
      }
    }
  }

  @media (max-width: 1150px) {
    padding-top: 3rem;
    margin-left: 0rem;

    .task-filters {
      display: none;
    }
  }
  @media (max-width: 850px) {
    margin-left: -1rem;
  }

  @media (max-width: 675px) {
    .controls {
      .ant-picker-range {
        height: 2rem;
      }
    }
    .pseudo-tasks-button {
      display: none;
    }
    .setting-row {
      display: none !important;
    }
  }

  @media (max-width: 475px) {
    .controls {
      gap: 0.5rem;
      align-items: center;
      justify-content: center;
      width: 100%;

      .ant-picker-range {
        width: 220px;
      }

      .ant-picker-input input {
        font-size: 0.9rem !important;
      }

      .setting {
        margin: 0 !important;
      }

      .settings-button {
        width: 2rem;
        span {
          display: none;
        }
        .anticon {
          display: inline-block;
        }
      }
    }
  }

  @media (max-width: 360px) {
    .controls {
      gap: 0.3rem;

      .ant-picker-range {
        width: 185px;
        padding-left: 0.3rem;
        padding-right: 0.3rem;

        .ant-picker-suffix {
          display: none;
        }
        .ant-picker-input input {
          font-size: 0.9rem !important;
        }
      }
    }
  }

  &.has-stock-items {
    @media (max-width: 600px), (max-height: 600px) {
      .user-list {
        width: 7rem !important;
      }
    }
  }
}

.timeline-settings-dropdown-overlay {
  .ant-dropdown-menu {
    padding: 0 !important;
  }
  .ant-dropdown-menu-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding-top: 0.6rem;
    padding-bottom: 0.6rem;

    .ant-select-selection-item {
      text-align: center;
      width: 100%;
      display: block;
    }

    .setting-label {
      display: block;
      font-size: 0.75rem;
      font-weight: 500;
      line-height: 0.9rem;
      margin-bottom: 0.3rem;
      text-align: center;
    }

    .ant-input-number-input-wrap input {
      text-align: center;
    }

    .ant-dropdown-menu-title-content {
      width: 100%;
    }

    .ant-switch {
      font-weight: 500;
      margin-top: 0.35rem;
      margin-bottom: 0.25rem;
    }

    .ant-input-number,
    .ant-select {
      width: 100%;
    }
  }
}

.special-task-menu-item {
  cursor: move !important;
}

.timeline-special-tasks-dropdown-overlay {
  .ant-dropdown-menu {
    padding-bottom: 0;
    padding-top: 0;
  }

  .ant-dropdown-menu-item-divider {
    margin-bottom: 0;
    margin-top: 0;
  }
}
