@import "../../Variables";

.subtasks-progress {
  background-color: white;
  border-radius: $borderRadius;
  margin-top: -0.5rem;
  display: block;
  text-align: center;
  margin-top: 1rem;
  text-align: left;
  display: flex;
  align-items: center;

  .label {
    width: 5rem;
    flex-shrink: 0;
    font-weight: 500;
  }

  .ant-progress {
    margin-left: 1rem;
  }
}

.task-summary {
  .ant-divider {
    display: none;
  }

  .card-title {
    width: 100%;
  }

  .dashboard-item-tags {
    margin-top: 1rem;

    .ant-tag {
      padding: 0.2rem 0.5rem;
      font-size: 0.75rem;
    }
  }

  .card-header .ant-space {
    display: block !important;

    .ant-space-item:first-child {
      margin-right: 0 !important;
    }
  }

  .task-description-label {
    margin: 0;
    margin-top: 0.5rem;
    margin-bottom: 0.3rem;
    font-size: 0.8rem;
  }

  .task-description {
    display: flex;
    flex-direction: column-reverse;
    font-weight: normal;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
      "Droid Sans", "Helvetica Neue", sans-serif;
    border-radius: $borderRadius;
    border: 1px solid $neutral !important;
    padding: 0rem;

    * {
      color: $standardText;
    }

    [class^="EditorToolbar"] {
      border-bottom: unset;
    }

    [class^="RichTextEditor__block"] {
      font-size: 0.8rem;
      margin: 0;
    }

    .public-DraftEditor-content {
      min-height: 5rem;
    }

    a {
      color: $accent;
      text-decoration: underline;

      * {
        color: $accent;
      }
    }

    button {
      border-color: $neutral !important;
      background: white !important;
    }

    select {
      border: 1px solid $neutral !important;
      padding-left: 0.5rem;
      padding-right: 2.5rem;
      outline: $accent;
    }
  }

  .title-and-initials {
    width: 100%;
    display: flex;
    align-items: center;
    margin-top: -0.5rem;
  }

  .task-title {
    font-weight: 500;
    flex-shrink: 0;

    input,
    textarea {
      font-size: 1.2rem;
    }

    .anticon {
      margin-right: 0.5rem;
    }
  }

  .task-initials {
    width: unset;
    position: relative;
    top: 2px;
    margin-top: -0.5rem;
    margin-right: 0.5rem;

    input {
      align-self: center;
      font-size: 0.8rem;
      color: darken($neutralDark, 5);
      margin-right: -0.3rem;
      flex-shrink: 0;
      font-weight: bold;
      width: 55px;
      text-align: center;
    }
  }

  .task-title {
    margin-left: -0.3rem;
    textarea,
    input {
      font-weight: 500;
    }
  }

  .task-subtitle,
  .client-name,
  .assigned-to-name,
  .project-name {
    font-size: 0.85rem;
    font-weight: normal;
    color: darken($neutralDark, 5);
    margin-top: 0.5rem;
    display: inline-block;
  }

  .task-subtitle {
    margin-left: 0.5rem;
  }

  .stats {
    text-align: left;

    .stat {
      margin-top: -0.5rem;
      margin-bottom: 0.5rem;
      padding-bottom: 0.5rem !important;
      padding-top: 0.5rem !important;
      display: block;
      padding-left: 1rem;
      margin-left: -1rem;
      max-width: 300px;

      &.clickable {
        cursor: pointer;

        &:hover {
          background-color: $hover;
        }
      }

      .stat-label {
        display: block;
        font-weight: 500;
      }

      .stat-value {
        .assigned-to-picker {
          margin-left: -0.2rem;

          .label {
            color: $standardText;
          }
        }

        &.created-by {
          margin-left: 0.2rem;
          .avatar {
            margin-left: -0.3rem;
            margin-right: 0.2rem;
          }
        }

        .ant-picker {
          margin-left: -0.1rem;
          margin-top: 0.2rem;
        }
      }
    }

    .created-by-container {
      .avatar {
        top: 5px;
      }
    }
  }

  .ant-row {
    padding: 0rem 1rem 0;

    .main-info {
      text-align: left;

      div.ant-typography {
        margin-bottom: 1rem !important;
      }
    }
  }

  .ant-statistic-content {
    font-weight: 500;
    color: $standardText;
  }

  &.archived,
  &.finished {
    .active-on-hover {
      pointer-events: none;
      background-color: transparent;

      .ant-select-selector {
        background-color: transparent;
      }
    }

    .stat.clickable {
      pointer-events: none;
    }
    .stat.clickable.link {
      pointer-events: all;
    }
  }
}
