@import "../Variables";

.projects-page {
  padding-bottom: 1rem;
  padding-top: 0.5rem;
  max-width: 1500px;
  margin: 0 auto;
  position: relative;

  .main-content {
    padding-top: 1rem;
  }

  .main-actions {
    margin-bottom: 1rem;
  }

  .task-list {
    padding-left: 3rem;
    margin-bottom: 2rem;
  }

  .project-count {
    display: block;
    width: calc(100% + 1rem);
    text-align: left;
    font-size: 1.2rem;
    font-weight: 500;
    // position: sticky;
    // z-index: 3;
    top: 2.9rem;
    margin-top: -0.9rem;
    background-color: $background;
    margin-left: -0.5rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 0.5rem;
  }

  .project-item {
    .project-name {
      pointer-events: none;
    }
  }
}
