@import "../Variables";

.change-password-page {
  display: flex;
  justify-content: center;

  .card {
    max-width: 400px;
  }

  .title.ant-typography {
    text-align: center;
    margin-bottom: 1.3rem;
  }

  .forgot-password {
    margin-top: -1.3rem;
    margin-bottom: 2rem;
    font-size: 0.8rem;
    text-align: right;
  }

  .ant-alert-error {
    margin-bottom: 0rem;
  }

  .ant-alert-message {
    text-align: center;
    width: 100%;
    display: inline-block;
  }
}
