.condition {
  .info-item {
    width: 100%;

    .main-content {
      width: 100%;

      .value-container {
        width: 100%;
      }
    }
  }
}
