@import "../Variables";

.sign-in-page {
  padding-top: 2rem;

  .card {
    max-width: 400px;
    margin: 0 auto !important;
  }

  .title.ant-typography {
    text-align: center;
    margin-bottom: 1.3rem;
  }

  .forgot-password.ant-typography {
    margin-top: -0.5rem;
    margin-bottom: 1.2rem;
    font-size: 0.8rem;
    text-align: right;
  }

  .ant-alert-error {
    margin-bottom: 0rem;
  }

  .ant-alert-message {
    text-align: center;
    width: 100%;
    display: inline-block;
  }
}
