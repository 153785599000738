.create-invoice-modal {
  width: 700px !important;
  max-width: 90vw;

  .ant-space {
    width: 100%;
  }

  .project-selector {
    margin-bottom: 0.5rem;
  }

  .submit-container {
    text-align: center;
  }
}
