@import "../../Variables";

.planned-activity-block {
  position: absolute;
  left: 1px;
  color: $standardText;
  padding: 0.5rem;
  width: calc(100% - 3px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: grab;

  &:hover {
    .background {
      border: 1px solid $accent !important;
    }
  }

  .background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.8;
    border-radius: $borderRadius;
  }

  .details {
    position: relative;
    display: flex;
    flex-direction: column;

    .task-title {
      color: black;
      font-weight: 500;
      opacity: 0.7;
      display: block;
    }

    .project-title {
      color: black;
      font-weight: 500;
      opacity: 0.5;
      display: block;
    }

    .task-id-tag {
      margin-top: 0.5rem;
      background-color: rgba(black, 0.7);
    }
  }

  &.compact {
    .task-id-tag {
      display: none;
    }
  }

  &.pseudo-task {
    .ant-typography {
      color: $white;
      position: relative;
      font-weight: 500;
    }
    .background {
      background-color: $standardText !important;
    }
  }
}
