@import "../Variables";

.queue-page {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-bottom: 1rem;
  padding-top: 0.5rem;

  a {
    color: $standardText !important;
    display: block;
    padding: 0.2rem 0.5rem;
    border-radius: $borderRadius;
    background-color: transparent;
    font-weight: bold;
    transition: unset;

    .ant-tag {
      transition: unset;
    }

    &:hover {
      background-color: $standardText;
      color: $white !important;

      .ant-tag {
        background-color: $white !important;
        color: $standardText !important;
        transition: unset;
      }
    }
  }

  .ant-table-body {
    overflow-y: auto;
  }

  .task-id-tag {
    min-width: 130px;
  }

  .anticon {
    color: $standardText;
  }

  .action-bar {
    margin-bottom: 0.5rem;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    justify-content: center;
  }
}
