@import "../Variables";

.document-review {
  width: 20rem;
  position: absolute;
  right: 0;
  top: 0;
  flex-shrink: 0;

  @media (max-width: 1200px) {
    width: 17rem;
  }

  @media (max-width: 1000px) {
    width: 15rem;
  }
}
