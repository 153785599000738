@import "../../../Variables.scss";

.page-padding-limit-container {
  position: absolute !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  pointer-events: none;

  .page-padding-limit {
    border: 1px dashed $accent;
    width: 100%;
    height: 100%;
  }
}
