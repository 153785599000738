@import "../../Variables.scss";

.attachment-picker-for-nested-field-list {
  .choose-attachments-button {
    margin-bottom: 0.5rem;
  }

  .attachment-item {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    margin-left: 1rem;
    padding-top: 0.3rem;
    padding-bottom: 0.3rem;

    &:not(:last-child) {
      border-bottom: 1px solid $border;
    }

    .attachment-item-delete-button {
      cursor: pointer;
      color: $accent;
    }
  }
}
