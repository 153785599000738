@import "../../Variables.scss";

.recording-symbol {
  display: inline-block;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background-color: $accent;
  margin-right: 0.3rem;
  position: relative;

  // create a repeating fading effect
  animation: fade 1.5s infinite;

  @keyframes fade {
    0% {
      opacity: 1;
    }
    40% {
      opacity: 0.2;
    }
    60% {
      opacity: 0.2;
    }
    100% {
      opacity: 1;
    }
  }

  &.white {
    background-color: $white;
    top: -1px;
  }
}
