.connect-to-quickbooks-button-wrapper {
  cursor: pointer;

  .connect-to-quickbooks-button {
    display: block;
  }

  .connect-to-quickbooks-button-on-hover {
    display: none;
  }

  &:hover {
    .connect-to-quickbooks-button {
      display: none;
    }

    .connect-to-quickbooks-button-on-hover {
      display: block;
    }
  }
}
