@import "../../Variables";

.invoice-item-container {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;

  .invoice-item {
    transition: all 300ms;
    display: block;
    cursor: pointer;
    position: relative;
    transition: all 800ms;
    max-height: 500px;
    opacity: 1;

    .ant-card-body {
      padding-right: 0.4rem;
      padding-top: 0.3rem;
      padding-bottom: 0.3rem;
      padding-left: 0.7rem;
    }

    &.drag-hidden {
      max-height: 0;
      opacity: 0;
    }

    .project-name {
      font-size: 0.8rem;
      color: saturate(darken($neutralDark, 20), 20);
      line-height: 1rem;
      margin-bottom: 0.1rem;
      font-size: 0.8rem;
      font-weight: 500;
      margin-bottom: -1px;
    }

    .task-id {
      margin-left: auto;
      margin-right: 0.2rem;
      flex-shrink: 0;

      .task-id-tag {
        margin-right: 0;
      }
    }

    .dashboard-item-tags {
      margin-top: 0.5rem;
    }

    .created {
      margin-bottom: 0;
    }

    .invoice-initials-and-name {
      font-weight: 500;
      margin: 0;
      line-height: 1rem;
      margin-top: 0;
    }

    .invoice-name,
    .invoice-initials {
      font-size: 0.8rem;
      color: darken($standardText, 3);
    }

    .invoice-subtitle {
      max-width: calc(100% - 35px);
      margin: 0;
      margin-bottom: -0.5rem;
      line-height: 1.1;
      font-size: 0.85rem;
      color: $neutralDark;
      display: block;
    }

    .invoice-subtotal {
      font-size: 0.8rem;
      font-weight: 500;
      color: darken($standardText, 3);
    }

    .client-logo {
      position: relative;
      top: -1px;

      &.client-logo-no-image {
        font-size: 0.8rem;
        position: relative;
        top: 2px;
        color: darken($neutralDark, 10);
      }
    }

    .footer {
      margin-top: 0.5rem;
      align-items: center;
      display: flex;
      justify-content: space-between;
    }

    .actions {
      margin-top: 0.5rem;
    }

    .invoice-tags {
      margin-top: 0;

      .ant-tag {
        padding: 0 0.3rem;
        font-size: 0.65rem;
        height: 1.1rem;
        line-height: 1rem;
        letter-spacing: 0.3px;
        border-radius: $borderRadius;
      }
    }

    .due-date {
      font-size: 0.65rem;
      font-weight: 500;
      text-transform: uppercase;
      color: darken($neutralDark, 10);
    }

    .avatar .symbol {
      width: 1.6rem;
      height: 1.6rem;
    }
  }
}
