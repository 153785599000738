@import "../Variables.scss";

.quick-create-menu {
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    fill: $white;
    margin-right: 1.2rem;
    font-size: 1.3rem;
  }

  &:hover {
    svg {
      fill: $accent;
    }
  }
}

.quick-create-button-menu {
  width: 10rem;

  .ant-dropdown-menu-item {
    padding: 0.5rem 1rem;
  }
}

.quick-create-button-menu-item {
  color: $standardText;
}

.quick-create-menu-header {
  pointer-events: none;
  font-size: $extraSmallFontSize !important;
  padding-bottom: 0;
  opacity: 0.7 !important;
}
