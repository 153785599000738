@import "../../Variables.scss";

.project-list-with-tasks {
  margin-left: auto;
  margin-right: auto;
  max-width: 1000px;

  .filter-input {
    margin-bottom: 1rem;
  }

  .ant-table-header {
    display: none;
  }

  b {
    color: $standardText;
  }

  tr:not(.ant-table-expanded-row) {
    cursor: pointer;
  }

  .task-item {
    padding: 0.5rem;
    &:not(:last-child) {
      border-bottom: 1px solid $neutral;
    }
    // border-radius: $borderRadius;
    // margin-bottom: 0.5rem;
    display: flex;
    cursor: pointer;
    font-weight: 500;
    align-items: center;
    gap: 0.5rem;
    color: $lightText;

    &:hover {
      // background-color: $accent;
      // border-color: $accent;
      // color: $white;
      background-color: lighten($background, 2);
      // border-color: $accent;
    }
  }
}
