.create-sheet-revision-modal {
  .ant-space {
    width: 100%;
  }

  .submit-container {
    text-align: center;
  }
}

.ant-radio-group.sheet-revision-status-list {
  display: flex;
  flex-direction: column;
}
