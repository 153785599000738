@import "../../../Variables.scss";

.template-page-header {
  position: absolute;
  top: -2.5rem;
  width: 100%;
  left: 0;
  text-align: center;
  font-weight: bold;

  .template-page-title {
    opacity: 0.6;
    font-size: 30px;
    transition: all $transition;
  }
}
