@import "../../Variables.scss";

.third-party-app-list {
  .ant-checkbox-wrapper + .ant-checkbox-wrapper {
    margin-left: 0;
  }

  .third-party-app-checkbox {
    display: flex;
    align-items: center;

    &:not(:last-child) {
      margin-bottom: 0.7rem;
      padding-bottom: 0.7rem;
      border-bottom: 1px solid $border;
    }

    .third-party-app-name {
      color: $standardText;
      font-weight: 500;
    }

    .third-party-app-description {
      color: $neutralDark;
    }

    .ant-checkbox {
      margin-right: 0.4rem;
    }

    .ant-checkbox-inner {
      position: relative;
      top: -1px;
    }
  }
}
