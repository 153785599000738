@import "../../Variables.scss";

.template-alert-banners {
  position: relative;
  z-index: 1;

  .preview-visible-text,
  .live-template-text,
  .viewing-old-version-text {
    display: block;
    z-index: 3;
    color: $white;
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    transition: all 400ms ease-in-out;
  }

  .preview-visible-text {
    background-color: $good;
  }

  .live-template-text {
    background-color: $accent;
  }

  .viewing-old-version-text {
    background-color: $bad;
  }
}
