@import "../../../Variables.scss";

.requests-table {
  max-height: calc(100vh - $navigationBarHeight - 5rem);
  overflow-y: auto;

  .ant-table {
    overflow-x: auto;

    .ant-table-container {
      min-width: 1200px;
    }
  }

  .ant-table-cell {
    padding: 0.2rem 0.4rem !important;
  }

  .dashboard-item-tags {
    justify-content: center;

    .ant-tag {
      width: 100%;
    }
  }
}
