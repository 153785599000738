@import "../../Variables";

.task-revisions {
  .card-header {
    & > .ant-space:first-child {
      display: flex;
      flex-wrap: wrap;

      .card-actions {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        flex-wrap: wrap;

        button {
          margin: 0;
        }
      }
    }
  }
  .task-revisions-timeline {
    .ant-timeline-item {
      display: flex;
    }

    .ant-timeline-item-tail {
      left: 110px !important;
      border-left-color: $border !important;
    }

    .ant-timeline-item-head {
      position: unset !important;
    }

    .ant-timeline-item-label {
      width: 110px !important;
      flex-shrink: 0 !important;
      position: relative !important;
      text-align: left !important;
    }

    .ant-timeline-item-content {
      position: relative !important;
      left: unset !important;
      top: -15px !important;
      width: 100% !important;
    }

    .ant-timeline-item-head-blue {
      color: lighten($standardText, 10);
      border-color: lighten($standardText, 10);
    }

    &.hide-dates {
      .ant-timeline-item-label,
      .ant-timeline-item-tail,
      .ant-timeline-item-head {
        display: none;
      }
      .ant-timeline-item-content {
        margin-left: 0;
      }
    }
  }

  .revision-name {
    margin-right: 0.5rem;
  }

  .ant-tag {
    .anticon {
      margin-right: 0.3rem;
      position: relative;
      top: 1px;

      &.anticon-check-circle svg * {
        color: $good;
      }
    }
  }

  .task-revision-name-and-status-wrapper {
    .task-revision-name-and-status {
      display: inline-flex;
      align-items: center;
    }
  }
}
