@import "../../Variables";

.add-to-task-modal {
  width: 95vw !important;
  max-width: 1200px !important;

  .choose-task-form-item {
    .ant-form-item-control-input-content {
      display: flex;
      gap: 1rem;
    }
  }

  .submit-container {
    margin-left: 33%;
  }
}
