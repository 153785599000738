@import "../Variables";
@import "../styles/Styles.scss";

body {
  min-height: 100vh;
  /* mobile viewport bug fix */
  min-height: -webkit-fill-available;
}

html {
  height: -webkit-fill-available;
}

* {
  box-sizing: border-box;
  &::-webkit-scrollbar {
    height: 0.6rem;
    width: 0.6rem;
    background: transparent;
    transition: all 400ms;

    &:hover {
      background: rgba($white, 0.6);
    }
  }

  &::-webkit-scrollbar-thumb {
    background: darken($accent, 17);
    background-color: darken(saturate($neutralDark, 20), 20);
    opacity: 0.5;
    border-radius: $borderRadius;
    cursor: pointer;
    width: 0.6rem;
  }
}

h1.ant-typography,
h2.ant-typography,
h3.ant-typography,
h4.ant-typography,
h5.ant-typography,
h6.ant-typography,
p.ant-typography,
span.ant-typography,
h1,
h2,
h3,
h4,
h5,
h6,
p,
.ant-typography,
div.ant-typography,
th.ant-table-cell,
label,
pre,
input,
input.ant-input,
textarea,
textarea.ant-input,
.ant-form-item-label > label,
.ant-checkbox-wrapper,
.ant-modal-title,
.ant-timeline-item-label,
.ant-select-item-option-content,
.ant-select-selection-item,
.avatar .label {
  color: $standardText;
  margin-top: 0;
  margin-bottom: 0;
}

img {
  max-width: 100%;
}

// .ant-typography,
// div.ant-typography,
// p,
// p.ant-typography,
// span.ant-typography,
// input,
// textarea {
// font-size: $smallFontSize !important;
// }

textarea,
input {
  font-family: $fontFamily;
}

.input input {
  min-height: 2rem;
}

td {
  color: darken($neutralDark, 21) !important;
}

.ant-input-password-icon.anticon-eye,
.ant-input-password-icon.anticon-eye-invisible {
  color: $accent !important;
}

h3.ant-typography {
  font-size: 1rem;
  font-weight: 500;
  color: $standardText;
}

.ant-result-subtitle,
.ant-result-title,
.ant-steps-item-title,
.ant-radio-wrapper span,
.ant-picker-header-view button,
.ant-picker-content th,
.ant-pagination li,
.ant-pagination-item-link,
.ant-table-thead .ant-table-cell,
.ant-picker-input input,
li {
  color: $standardText !important;
}

.ant-progress-status-normal .ant-progress-circle-path,
.ant-progress-status-normal .ant-progress-text {
  stroke: $accent !important;
  color: $accent !important;
}

.ant-progress-text {
  color: $neutralDark;
  font-weight: 500;
  width: 25px;
  text-align: right;
  font-size: $extraSmallFontSize;
}

.ant-select-arrow,
.ant-pagination-disabled a {
  color: $neutralDark !important;
}

hr {
  border: unset;
  height: 1px;
  background-color: $neutral;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.highlight {
  color: $accent;
}

.danger {
  color: $bad !important;
}

.spread {
  display: flex !important;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.anticon {
  justify-content: center;
}

button.ant-btn {
  color: $lightText;
  border-color: darken($neutral, 5);
  display: inline-flex;
  justify-content: center;
  align-items: center;
  max-width: calc(90vw - 4rem);
  min-height: 32px;
  height: unset;
  // white-space: normal;

  .icon {
    margin-right: 0.5rem;
    color: $neutralDark;
    fill: $neutralDark;
  }

  &.ant-btn-link {
    border: unset;
    padding: unset;
  }

  &.ant-btn-compact-last-item {
    margin-left: 0;

    &.ant-btn-primary {
      &:not([disabled])::after {
        background-color: rgba($white, 0.3) !important;
      }
    }
  }

  &:hover {
    border-color: $accent !important;
  }
}

button.ant-btn.ant-btn-primary {
  border-color: $accent;
  background-color: $accent;
  color: $white;

  .anticon {
    color: $white !important;
  }
  &:not(:disabled):hover {
    background-color: $accent;
    opacity: 0.7;
  }
}

button.ant-btn.ant-btn-dark,
.ant-btn.ant-btn-dark {
  background-color: $standardText;
  border-color: $standardText !important;
  color: $white !important;

  &:hover,
  &:active,
  &:focus {
    background-color: lighten($standardText, 10);
    border-color: lighten($standardText, 0);
    color: $white;
  }
}

button.ant-btn.ant-btn-clear {
  border-color: transparent;
  box-shadow: unset;
  background-color: unset;

  &:hover {
    background-color: $white;
    border-color: $accent;
  }
}

button.ant-btn.ant-btn-danger,
button.ant-btn.ant-btn-dangerous {
  border-color: $bad;
  color: $bad;

  &:hover {
    opacity: 0.5;
    background-color: $white;
    border-color: $bad !important;
  }
}

button:disabled,
button.ant-btn:disabled,
*.ant-btn:disabled {
  pointer-events: none;
  background-color: $hover !important;
  border-color: $border !important;
  color: $neutralDark !important;
  opacity: 0.5;

  .anticon {
    color: $neutralDark !important;
  }

  .icon {
    color: $neutralDark !important;
  }
}

.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab,
.ant-tabs-nav-add {
  margin-right: 10px !important;
  border: 1px solid $neutral !important;
  background-color: #fff;
  color: darken($neutralDark, 15);
  border-top-color: $border !important;
  border-left-color: $border !important;
  border-right-color: $border !important;

  .anticon-close {
    color: darken($neutralDark, 15);

    &:hover {
      color: $accent !important;
    }
  }

  &:hover {
    border-top-color: $standardText !important;
    border-left-color: $standardText !important;
    border-right-color: $standardText !important;
    color: $standardText;
  }

  &.ant-tabs-tab-active {
    border-top-color: $neutral !important;
    border-left-color: $neutral !important;
    border-right-color: $neutral !important;
    border-bottom-color: $neutral !important;
    color: $standardText;
    border-bottom-color: $standardText !important;
    border-bottom-width: 2px !important;
  }
}

.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab {
  &.ant-tabs-tab-active {
    border-bottom-color: $neutralDark !important;
  }
}

.ant-tabs-tab-disabled {
  opacity: 0.3;
}

.ant-tabs-nav-add {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}

.ant-tabs-tab.ant-tabs-tab-active {
  border-color: $standardText;

  .ant-tabs-tab-btn {
    color: $standardText !important;
  }
}

.ant-dropdown {
  border-radius: $borderRadius;

  // this is because modals in Ant Design have z-index 1000, but we still want dropdowns to be on top of them
  z-index: 1001 !important;
  position: absolute;

  .ant-dropdown-menu-item {
    color: $standardText;
    display: flex;
    align-items: center;

    &:hover {
      background-color: $hover;
    }

    .icon {
      margin-right: 0.5rem;
      color: $standardText;
      fill: $standardText;
    }

    &.ant-dropdown-menu-item-disabled {
      opacity: 0.3;
    }
  }

  .ant-dropdown-menu-item-disabled {
    color: $neutral;
  }

  .ant-dropdown-menu-item-divider {
    background-color: $background;
  }
}

div.ant-divider {
  border-top-color: $neutral;
}

.ant-form-item-label > label.ant-form-item-required::before {
  color: $accent !important;
}
.ant-form-item .ant-form-item-label > label {
  height: unset;
  min-height: 32px;
}

.ant-modal-root {
  label {
    white-space: normal;
  }

  .ant-modal-header {
    border-bottom-color: $border;
  }

  .ant-modal .ant-modal-close:hover {
    background-color: unset;
  }

  .ant-modal-close-x {
    color: $standardText;
  }

  .ant-modal-mask {
    background-color: rgba(darken($standardText, 11), 0.75);
  }
  .ant-modal-content {
    @include with-shadow(rgba(black, 0.6));
  }

  .ant-modal-confirm-body .ant-modal-confirm-title {
    color: $standardText;
  }

  .ant-modal-confirm-title {
    max-width: calc(90vw - 4rem);
    white-space: normal;
  }

  .ant-modal-confirm-body .ant-modal-confirm-content {
    color: desaturate(lighten($standardText, 30), 60);
  }
}

.jpg-image-preview {
  width: 100%;
}

.video-preview-modal {
  width: 80vw !important;
  height: calc(80vw * 16 / 9) !important;
  transform-origin: unset !important;

  .ant-modal-content {
    background-color: transparent;
    box-shadow: unset;
  }

  .ant-modal-confirm-content {
    margin-top: 0;
  }

  .ant-modal-body {
    padding: 0;
  }

  .ant-modal-confirm-btns {
    .ant-btn.ant-btn-primary {
      display: none;
    }
    .ant-btn {
      position: fixed;
      top: 3rem;
      right: 10vw;
    }
  }

  video {
    max-width: 100%;
    width: calc(85vh * 16 / 9);
    max-height: 85vh;
  }
}

.ant-notification {
  width: auto;

  .ant-notification-notice {
    width: 500px;
  }

  .ant-notification-notice-message {
    .ant-typography {
      color: $standardText;
    }
  }
}

.ant-typography-edit {
  color: $accent !important;
}

.ant-tag {
  font-weight: 500 !important;
  text-align: center;
  border-radius: 4px !important;
  font-size: $extraSmallFontSize;
  flex-shrink: 0;
  text-wrap: wrap;
}

.ant-tag.ant-tag-blue {
  color: $white;
  border-color: $accent;
  background-color: $accent;

  * {
    color: $white !important;
  }
}

.ant-tag.ant-tag-green {
  color: $white !important;
  border-color: $good;
  background-color: $good;

  * {
    color: $white !important;
  }
}

.ant-empty {
  .ant-empty-description {
    color: $neutralDark;
    font-weight: 500;
    font-size: 0.75rem;
  }

  .ant-empty-image {
    transform: scale(0.8);

    .ant-empty-img-default-path-1 {
      fill: $neutralDark;
    }
    .ant-empty-img-default-path-5,
    .ant-empty-img-default-path-4 {
      fill: $neutral;
    }
    .ant-empty-img-default-ellipse {
      fill: $neutral;
    }
  }
}

input {
  &::placeholder {
    color: darken($neutralDark, 5) !important;
    opacity: 1;
  }
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    box-shadow: 0 0 0 30px $white inset !important;
  }
}

.ant-picker,
.ant-select-selector,
.ant-input-number {
  border-color: darken($neutral, 7) !important;
  cursor: pointer;

  * {
    cursor: pointer;
  }

  &:hover {
    border-color: $accent !important;
  }

  .ant-picker-suffix,
  .ant-picker-separator {
    color: darken($neutralDark, 5) !important;
  }
}

.modal-no-buttons {
  .ant-modal-confirm-btns {
    display: none;
  }
}

.ant-checkbox-wrapper {
  * {
    user-select: none;
  }

  .ant-checkbox {
    z-index: 0; // without this, checkboxes tend to be displayed on top of other elements when scrolling

    .ant-checkbox-inner {
      border-radius: $borderRadius !important;
      border-color: darken($neutral, 7);
    }

    &::after {
      border-radius: $borderRadius !important;
    }
  }

  .ant-checkbox + span {
    flex-shrink: 0;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    border-color: $accent !important;
  }

  & + .ant-checkbox-wrapper {
    margin-inline-start: 0;
  }
}

.active-on-hover {
  position: relative;
  left: -0.7rem;
  border-color: transparent !important;
  min-width: 200px;
  max-width: 100%;

  .ant-select-selection-item {
    color: $standardText;
  }

  input {
    color: $standardText;
  }

  .ant-select-selector {
    border-color: transparent !important;
  }

  .ant-select-arrow {
    .edit-icon {
      position: relative;
      top: -3px;
      left: -1rem;
    }
  }

  &:hover {
    .ant-picker-suffix {
      opacity: 1 !important;
    }
    .ant-select-selector {
      border: 1px solid $accent !important;
    }

    .ant-select-arrow {
      display: block !important;
    }
  }

  &.ant-picker-disabled {
    background-color: transparent !important;
    border: unset !important;
  }
}

.ant-tabs-tab {
  color: rgba($standardText, 0.7);
}

.ant-tabs-nav {
  &::before {
    border-bottom-color: darken(saturate($neutral, 0), 5) !important;
  }

  .ant-tabs-ink-bar {
    background: lighten($standardText, 10) !important;
  }
}

.not-set {
  color: darken($neutralDark, 5) !important;
}

.ant-alert {
  .ant-alert-message {
    color: $standardText;
    text-align: center;
  }

  .anticon {
    margin-right: 1rem;
  }

  .ant-alert-message {
    font-weight: 500;
  }
}

.ant-alert-error {
  background-color: $bad;
  color: $white;
  border: unset;

  .anticon {
    color: $white;
  }

  .ant-alert-message {
    color: $white;

    .ant-typography {
      color: $white;
    }

    .content {
      display: flex;
      gap: 1rem;
      align-items: center;
      justify-content: center;
      button {
        color: $bad;
        border: unset;
        background-color: $white;
        font-weight: 700;
      }
    }

    .stacked-content {
      display: flex;
      flex-direction: column;
    }
  }
}

.ant-alert-info {
  margin-bottom: 0.5rem;
  background-color: desaturate(lighten($standardText, 20), 40);
  border: unset;

  .anticon {
    color: $white;
  }

  .ant-alert-message {
    color: $white;
  }
}

.ant-alert-success {
  margin-bottom: 0.5rem;
  background-color: $good;
  border: unset;

  .anticon {
    color: $white;
  }

  .ant-alert-message {
    color: $white;
  }
}

.ant-tooltip {
  z-index: 1000;

  .ant-tooltip-inner {
    background-color: rgba($standardText, 0.9);

    * {
      color: $white !important;
    }

    .ant-btn-default,
    .ant-btn-default * {
      color: $lightText !important;
    }
  }
}

.ant-picker-range {
  width: 190px;
  padding-left: 0.3rem;
  padding-right: 0.3rem;

  .ant-picker-range-separator {
    padding: 0 4px;
  }

  input {
    letter-spacing: -1px;
  }
}

.bold {
  font-weight: 500;
}

.ant-tabs-dropdown-menu li {
  color: $standardText;
}

.ant-tabs-nav-more {
  svg {
    color: $standardText;
  }
}

.ant-switch {
  background-color: $neutralDark;

  &.ant-switch-disabled {
    background-color: $neutral !important;
  }

  &.ant-switch-checked {
    background-color: $accent;
  }

  &:hover {
    transform: unset;
    background-color: $hover;
  }

  &.ant-switch-checked {
    &:hover {
      transform: unset;
      background-color: lighten($accent, 20);
    }
  }
}

.white-tag {
  background-color: $white;
  color: $standardText;
  border: unset;
  @include with-shadow;
  font-weight: 500;
}

.dark-tag {
  background-color: $standardText;
  color: $white !important;
  border: unset;
  font-weight: 500;
  box-shadow: unset;
}

.accent-tag {
  background-color: $accent;
  color: $white;
  border: unset;
  font-weight: 500;
  box-shadow: unset;
}

.ant-input-affix-wrapper .ant-input-clear-icon {
  color: $neutralDark;
}

.ant-radio-wrapper,
.ant-checkbox-wrapper {
  display: flex;
  align-items: center;

  .ant-radio,
  .ant-checkbox {
    top: 0px;
  }
}

.ant-input,
.ant-input-affix-wrapper,
.ant-radio-inner {
  border-color: darken($neutral, 5);
}

.ant-pagination {
  background-color: $background;
  padding: 0.3rem;
  border-radius: $borderRadius;

  input {
    border-color: $neutral !important;
  }
}

.boxed-layout {
  width: 100%;
  max-width: $boxedLayoutMaxWidth;
  padding-left: 1rem;
  padding-right: 1rem;
  margin-left: auto;
  margin-right: auto;
}

.hidden {
  display: none;
}

.ant-modal-title {
  font-weight: 500;
  margin-bottom: 1.5rem !important;
}

.ant-form-item-label > label {
  height: unset;
  min-height: 32px;
}

@media (max-width: 600px) {
  .ant-modal-title {
    font-weight: 500;
    font-size: 1rem !important;
  }

  .ant-modal-close-x {
    width: 3rem;
    height: 3rem;
    line-height: 3rem;
  }

  .ant-modal-body {
    padding: 0.5rem 1rem 0.3rem;
  }

  .ant-row.ant-form-item {
    margin-bottom: 1rem;

    .ant-form-item-label {
      padding-bottom: 0;

      label {
        font-size: 1rem;
      }
    }
  }

  .ant-picker-suffix,
  .ant-select-focused .ant-select-selection-search,
  .ant-select-focused .ant-select-selection-placeholder,
  .ant-select-focused,
  .ant-select-focused .ant-select-arrow,
  .upload-box .initial-message .message {
    font-size: 1rem !important;
  }

  input,
  textarea,
  .textarea-content,
  label,
  button {
    font-size: 1rem !important;
  }

  .ant-select-clear {
    font-size: 1rem !important;
    top: 14px !important;
    right: 44px !important;
  }

  .ant-select,
  .ant-select .ant-select-selector,
  .ant-select .ant-select-selector input {
    height: 2.3rem !important;
  }

  .ant-select .ant-select-selection-placeholder {
    line-height: 2.3rem !important;
  }

  .ant-select .ant-form-item-label label::before {
    font-size: 1.3rem !important;
  }

  .ant-picker-range {
    width: 220px;
  }
}

.check-file-is-open-modal,
.file-failed-to-open-modal,
.file-is-open-modal,
.file-has-opened-modal {
  .ant-modal-confirm-body-wrapper {
    margin-bottom: -1.5rem;
  }
  .anticon-loading {
    margin-left: 1rem;
  }
}

.ant-checkbox-group {
  display: flex;
  flex-direction: column;
}

.reassigning-task-modal {
  .ant-modal-confirm-btns {
    display: none !important;
  }
}

.option-disabled {
  opacity: 0.3;
}

// Table
.ant-table-title {
  font-weight: 700;
  text-align: center;
  color: $standardText;
}

.ant-pagination-options-size-changer {
  display: none;
}

.ant-table-thead > tr > th.ant-table-cell {
  background-color: white;
  border-bottom: 1px solid $neutral !important;
  font-weight: 700;
  text-align: center;

  &::before {
    display: none;
  }
}

.ant-table-tbody > tr > td.ant-table-cell {
  text-align: center;
  border-bottom: unset !important;
  background-color: $white;
}

.ant-table.ant-table-scroll-horizontal {
  .ant-table-content {
    overflow: auto !important;
  }
}

tbody tr:first-child {
  td:first-child {
    border-top-left-radius: $borderRadius;
  }
  td:last-child {
    border-top-right-radius: $borderRadius;
  }
}
tbody tr:last-child {
  td:first-child {
    border-bottom-left-radius: $borderRadius;
  }
  td:last-child {
    border-bottom-right-radius: $borderRadius;
  }
}

.ant-table-cell {
  padding: 0.5rem !important;
}

.ant-table-cell .edit-icon {
  color: $standardText;
  margin-left: 0.5rem;
}

.ant-table-wrapper {
  .ant-table table {
    border: 1px solid $neutral;
    border-radius: $borderRadius !important;
  }

  tr {
    td {
      border: none !important;
    }

    &:not(:last-child) {
      td {
        border-bottom: 1px solid $neutral !important;
      }
    }

    &:not(.ant-table-expanded-row):not(.ant-table-placeholder):hover {
      background-color: $hover !important;

      td.ant-table-cell {
        background-color: $hover !important;
      }
    }
  }

  .ant-table-title {
    text-align: left;
    padding-left: 0;
    padding-top: 0;
  }

  &.not-interactive tr,
  tr.not-interactive,
  .ant-table-thead tr {
    cursor: unset;

    &:hover {
      background-color: unset !important;
      td {
        background-color: unset !important;
      }
    }
  }
}

.ant-table-tbody > tr > td > .ant-table-wrapper:only-child .ant-table,
.ant-table-tbody > tr > td > .ant-table-expanded-row-fixed > .ant-table-wrapper:only-child .ant-table {
  margin: 0;
}

.ant-table-expanded-row > .ant-table-cell {
  background-color: $hover;
  padding: 1rem !important;
}

.ant-table-wrapper.no-hover {
  table {
    tbody {
      tr {
        &:not(.ant-table-expanded-row):not(.ant-table-placeholder):hover {
          background-color: unset !important;
        }
      }
      .ant-table-row:hover {
        td.ant-table-cell.ant-table-cell-row-hover {
          background-color: unset !important;
        }
      }
    }
  }
}

// Form
.ant-form {
  &.ant-form-vertical {
    .ant-form-item {
      margin-bottom: 0.7rem;

      .ant-form-item-label {
        padding-bottom: 0;
        margin-bottom: -0.2rem;
      }
    }
  }
}

// Drawer
.ant-drawer-header {
  padding: 0 !important;
}

.ant-drawer-title {
  color: $standardText;
}

.ant-drawer-close {
  color: $standardText;
  padding: 1rem;
}

// Modal

.ant-modal-wrap {
  overflow-x: hidden;
}

.ant-modal-body {
  padding: 0;
}

// Select
.ant-select-multiple {
  width: 100%;
  max-width: 700px !important;
}

.ant-select-selection-overflow-item {
  margin-right: 0.5rem;
  font-weight: 500;
}

.ant-select-selector {
  min-height: 2rem;
  &::after,
  .ant-select-selection-item::after {
    display: none !important;
  }

  .ant-select-selection-placeholder {
    color: darken($neutralDark, 5) !important;
    opacity: 1;
  }
}

// Collapse
.ant-collapse {
  .ant-collapse-item {
    .ant-collapse-header {
      font-weight: 500;
      color: $standardText;
    }
  }
}

// Popover
.ant-popover {
  z-index: 5 !important;
}

.popover-no-content {
  .ant-popover-inner-content {
    display: none;
  }

  .ant-popover-title {
    border: unset;
  }

  .ant-popover-inner {
    background-color: $standardText;

    .ant-popover-title {
      color: $white;
    }
  }
}

// Tree
.ant-tree {
  .ant-tree-treenode-draggable .ant-tree-draggable-icon {
    opacity: 1;
    cursor: grab;
  }
}

// Breadcrumb

.ant-breadcrumb {
  text-align: left;
  margin-bottom: 0.5rem;
  color: $neutralDark;
  font-size: 0.9rem;

  ol {
    list-style-type: none;
    margin: 0;
    align-items: center;

    li {
      margin: 0;
    }
  }

  & > span {
    display: inline-block;
  }

  & > span:last-child {
    color: $standardText;
  }

  a {
    color: $standardText !important;
    display: inline-block;
    padding: 0.2rem 0.5rem;

    &:hover {
      background-color: transparent;
    }
  }
  .ant-breadcrumb-separator {
    color: $neutralDark;
  }
}

a {
  text-decoration: none;
}

///////////////////////////////// message  /////////////////////////////
.ant-message.ant-message-top {
  top: 30px !important;
}

//////////////////////////////// time picker ////////////////////////////
.ant-picker-time-panel-column {
  &::before {
    display: block;
    color: $neutralDark;
    padding-bottom: 2px;
    border-bottom: 1px solid $border;
  }
  &:first-child::before {
    content: "hours";
    padding-left: 10px;
  }
  &:nth-child(2)::before {
    content: "minutes";
    padding-left: 3px;
  }
}

.time-picker-no-ok-button {
  .ant-picker-footer {
    display: none;
  }
}
