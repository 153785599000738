@import "../Variables";

.task-picker {
  width: 100%;

  .ant-select-selector {
    height: 32px !important;
  }

  .ant-select-selection-item {
    color: $accent;
    padding-bottom: 4px !important;

    .label {
      position: relative;
      top: 1px;
    }
  }

  .ant-select-arrow {
    color: $accent;
  }
}

.task-picker-option {
  &:not(:last-child) {
    border-bottom: 1px solid $neutral;
  }
}

.task-picker-option-inner {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 0;

  .project-title {
    font-size: $extraSmallFontSize;
    color: $lightText;
    font-weight: 500;
    display: block;
    margin-bottom: -0.3rem !important;
    margin-top: -0.3rem;
  }

  .task-title {
    font-size: $extraSmallFontSize;
    color: $standardText;
    font-weight: 500;
  }

  .tags-container {
    display: flex;
    flex-wrap: wrap;
  }
}

.task-picker-option-label {
  position: relative;
  top: 2px;

  .task-picker-option-label-task-title {
    font-size: $extraSmallFontSize;
    font-weight: 500;
  }
}
