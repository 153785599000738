@import "../../../Variables.scss";

.request-form-card {
  &.with-space {
    margin-bottom: 0.5rem;
  }

  & > .ant-card-body > .card-header {
    &.sticky-card-header {
      z-index: 5;
    }

    .ant-space-item:nth-child(2) {
      flex-shrink: 0;
    }
  }

  & > .ant-card-body > .card-header .card-title {
    cursor: pointer;
    margin-top: -0.3rem;
    margin-bottom: -0.3rem;
    margin-left: -0.3rem;
    padding-top: 0.3rem;
    padding-left: 0.3rem;
    padding-right: 0.2rem;
    padding-bottom: 0.25rem;
    font-size: $smallFontSize;
    border-radius: $borderRadius;
    display: flex;
    align-items: center;

    &:hover {
      background-color: $hover;
    }
  }

  &.collapsed {
    .card-header {
      .ant-divider {
        display: none;
      }
    }

    .ant-card-body {
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
    }

    .message-for-form {
      margin-bottom: 0rem;
      margin-top: 0.5rem;
    }

    .send-to-engineer-bottom-button-container {
      display: none;
    }
  }

  &.highlighted {
    border: 2px solid $accent !important;
  }

  .send-to-engineer-bottom-button-container {
    display: flex;
    justify-content: center;
    border-top: 1px solid $border;
    padding-top: 1rem;
  }

  @media (max-width: 1200px) {
    & > .ant-card-body > .card-header > .ant-space {
      flex-direction: column;

      & > .ant-space-item:nth-child(2) {
        order: -1;
        border-bottom: 1px solid $border;
        padding-bottom: 0.5rem;
        // margin-bottom: 0.5rem;
        width: 100%;
        justify-content: space-around;
        display: flex;
      }
    }
  }

  .card-actions {
    margin-bottom: -0.5rem;
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    button {
      margin-bottom: 0.5em;
    }
  }

  .review-status-approved {
    background-color: $good;
    border-color: $good;
    color: white;
  }

  .review-status-rejected {
    background-color: $bad;
    border-color: $bad;
    color: white;
  }
}
