@import "../../Variables.scss";

.email-details-modal {
  width: min-content;
  height: min-content;

  .content {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }

  .subject-wrapper {
    width: 95%;
    font-weight: bold;
    font-size: 1rem;
  }

  .attachment-list-wrapper {
    display: flex;
    flex-direction: column;
    gap: 0.4rem;
    font-weight: bold;
  }

  .body-label {
    font-weight: bold;
  }

  .attachment-list-item {
    display: flex;
    gap: 0.4rem;
    cursor: pointer;
  }
}
