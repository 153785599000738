@import "../../Variables";

.invoice-actions-container {
  background-color: $background;

  @media (max-width: 1000px) {
    .preview-switch {
      display: none;
    }
  }

  .invoice-actions {
    .ant-card-body {
      padding-top: 0.3rem;
      padding-bottom: 1rem;
    }

    .actions-container {
      flex-shrink: 0;
      gap: 1rem;
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      align-items: center;
      margin-bottom: -0.7rem;

      .download-and-send {
        margin-left: auto;
        display: flex;
        flex-wrap: wrap;
        gap: 1rem;
      }
    }
  }
}

.validation-error-message {
  margin-bottom: 1rem;
}

.validation-errors-modal {
  .ant-modal-confirm-content {
    margin-inline-start: 0 !important;
  }
}
