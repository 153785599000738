@import "../../Variables";

.create-external-reference-modal {
  width: 100% !important;
  max-width: 800px !important;

  .submit-container {
    margin-left: 33%;
  }
}
