@import "../../Variables.scss";

.nested-field-list-with-modal {
  &.is-not-form-editor {
    & > .info-item > .main-content {
      position: sticky;
      top: 0px;
      padding-top: 1rem;
      padding-bottom: 1rem;
      margin-bottom: -0.5rem;
      margin-top: -1rem;
      background-color: $white;
      width: 100%;
      z-index: 2;
      display: flex;
      flex-wrap: wrap;

      @media (max-width: 1030px) {
        top: 70px;
      }
    }
  }

  .item-index {
    margin-right: 0.5rem;
    font-weight: bold;
  }

  .top-level-actions {
    display: flex;
    gap: 0.5rem;
    flex-wrap: wrap;
  }
}
