.scheduled-notification-modal {
  .notification-body {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    button {
      width: 300px;
      margin: 0 auto;
    }
  }
}
