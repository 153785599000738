@import "../../Variables";

@keyframes is-generating-marker {
  from {
    transform: scale(0.7);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes needs-refresh-marker {
  from {
    margin-top: -1rem;
    opacity: 0;
  }
  to {
    margin-top: 0;
    opacity: 1;
  }
}

.review-sheet {
  display: flex;
  flex-direction: row;
  height: 100%;

  .inner-review-sheet-container {
    display: flex;
    overflow-y: hidden;
    flex-direction: column;
    width: 100%;
  }

  .inner-drawing-container {
    .pdf-renderer {
      height: unset;
      transform: scale(1.4);
      transform-origin: 0 0;
      margin: 0;
    }

    .scrolling-pdf-pagination {
      position: sticky;
      top: 0;
      z-index: 2;
    }
  }

  .needs-refresh {
    position: fixed;
    top: 19rem;
    transform: translateX(-50%);
    left: 50%;
    z-index: 2;
    background-color: $white;
    padding: 0.5rem 1.4rem;
    border-radius: $borderRadius;
    border: 1px solid $accent;
    animation-name: needs-refresh-marker;
    animation-duration: 400ms;
    animation-iteration-count: 1;

    .label {
      color: $neutralDark;
      font-weight: 500;
    }
  }

  .zoomable-content {
    .react-pdf__Page {
      box-shadow: unset !important;
    }
  }

  .message-container {
    position: absolute;
    left: 50%;
    top: 8.5rem;
    z-index: 2;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    transform: translateX(-50%);

    .message-item {
      padding: 0.5rem 2rem;
      border-radius: $borderRadius;
      background-color: $standardText;
      color: $white;
      cursor: no-drop;
      font-weight: 500;
      display: flex;
      gap: 1rem;
      align-items: center;
      justify-content: center;
      user-select: none;

      animation-name: is-generating-marker;
      animation-duration: 400ms;
      animation-iteration-count: 1;

      & > .anticon {
        font-size: 1rem;
      }

      .ant-typography {
        color: $white;
      }

      button {
        box-shadow: unset;
        border: unset;
        color: $standardText;
        margin: 0;
        margin-left: 1rem;
        background-color: $white;
      }
    }
  }

  .drawing-container {
    width: 100%;
    height: 1px;
    flex-grow: 1;
    background-color: $hover;
    position: relative;
    transition: all 500ms;
    transform: translateX(0);

    &.scrollable {
      overflow: auto;
    }

    @media (max-width: $reviewPageMobileBreakpoint) {
      &.is-file-list-open {
        transform: translateX(100%);
      }
      &.is-activity-open {
        transform: translateX(-100%);
      }
    }

    .draw-area-placeholder {
      position: absolute;
      top: 0;
      left: 0%;
      width: 100%;
      opacity: 0.3;
      background-color: pink;
    }

    .no-report-message {
      margin-top: 2rem;
      display: inline-block;
      background-color: $white;
      border-radius: $borderRadius * 2;
      @include with-shadow;
      color: darken($neutralDark, 25);
      width: 300px;
      padding: 1rem;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 0.8rem;
      z-index: 5;

      .main-message {
        font-size: 1rem;
        color: $neutralDark;
        font-weight: 500;
        display: inline-block;
        margin-bottom: 0.5rem;
      }
    }

    .react-pdf__Document {
      background-color: $background;
    }

    .react-pdf__Page {
      background-color: $hover;

      canvas {
        min-width: $reportWidth !important; // this is to prevent a white border around the page
        min-height: 841px;
      }

      &.high-resolution-page {
        transform: scale(calc(1 / 2));
        transform-origin: 0 0;
      }

      .react-pdf__Page__canvas {
        border: 1px solid $border;
      }
    }

    .no-image {
      position: absolute;
      transform: translate(-50%, -50%);
      top: 50%;
      left: 50%;
    }

    .draw-area {
      position: absolute;
      top: 0;
      left: 0;
      width: 3000px;
      height: 3000px;
      z-index: 1;

      .drawing {
        width: 100%;
        height: 100%;
      }
    }

    .react-transform-component,
    .react-transform-element {
      width: 100%;
      height: 100%;
    }

    .zoomable-content {
      padding: 0px;
      position: relative;

      .pdf-renderer {
        width: unset;
        height: unset;
      }

      .draw-area {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;

        .drawing {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
