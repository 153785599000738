@import "../../Variables";

.report-user-list-modal {
  width: 1000px !important;
  max-width: 90%;

  .submit-container {
    text-align: center;
  }
}
