@import "../../Variables";

.batch-create-client-modal {
  width: 40rem !important;

  .ant-space {
    width: 100%;
  }

  .drag-and-drop {
    height: 10rem;
    width: 100%;
    margin: 0 auto;
    border: 1px dashed $neutralDark;
    border-radius: 10px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .upload-icon {
      color: $neutralDark;
      font-size: 2.5rem;
      display: block;
      margin-bottom: 0.2rem;
    }

    .upload-instructions {
      color: $neutralDark;
      font-size: 0.8rem;
      font-weight: 500;
    }
  }

  .clients-title {
    margin-top: 1rem;
    display: block;
    font-weight: 500;
  }

  .clients-container {
    list-style-type: none;
    padding-left: 0;
    margin-top: 0.4rem;

    .client-items-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
      border-top: 1px solid $neutral;
      border-bottom: 1px solid $neutral;
      font-weight: 500;
      text-transform: uppercase;
      font-size: 0.7rem;
      * {
        color: darken($neutralDark, 10);
      }

      .header-name {
        width: 14rem;
        display: block;
        text-align: center;
      }

      .header-priority {
        display: block;
        text-align: center;
        width: 3.5rem;
      }

      .header-logo {
        display: block;
        width: 6rem;
        text-align: center;
      }

      .header-actions {
        display: block;
        width: 6rem;
        text-align: center;
      }
    }

    .client-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 0.5rem;
      padding-top: 0.5rem;
      border-bottom: 1px solid $neutral;

      .name-container {
        width: 14rem;

        input {
          border: 1px solid $neutral;
          border-radius: $borderRadius;
          padding-left: 0.5rem;
          width: 100%;
          text-align: center;
          color: $standardText;
          outline: unset;
          &:focus {
            border-color: $accent;
          }
        }
      }

      .priority-container {
        width: 3.5rem;
        text-align: center;
      }

      .logo-container {
        width: 6rem;
        img {
          width: 100%;
        }
      }

      .actions-container {
        width: 6rem;
        text-align: center;
      }
    }
  }

  .submit-container {
    display: flex;
    justify-content: center;
    button {
      width: 140px;
    }
  }
}
