@import "../../Variables.scss";

.calendar-page {
  .card-header {
    margin-bottom: -5rem;
    padding-top: 0.5rem;

    .calendar-card-title {
      display: flex;
      align-items: center;
      gap: 0.5rem;
    }
  }

  .ant-picker-cell {
    cursor: unset !important;

    .ant-picker-calendar-date {
      background-color: $white !important;
    }
  }

  .preloader-container {
    width: 100%;
    height: calc(100vh - $navigationBarHeight - 2rem);
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .ant-picker-calendar-mode-switch {
    display: none;
  }

  .date-cell {
    position: absolute;
    top: 2px;
    left: 0;
    width: calc(100% - 1.5rem);
    height: calc(100% - 4px);
    overflow-y: scroll;
  }

  .calendar-block {
    border-radius: $borderRadius;
    position: relative;
    height: auto;
    overflow-x: hidden;
    user-select: none;
    transform-origin: 50% 50%;
    transform: scale(1);
    transition: all $transition;

    &.clickable {
      cursor: pointer;

      &:hover {
        opacity: 0.8;
        transform: scale(0.95);
      }
    }

    .block-info {
      padding: 4px;
      color: $white;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      font-size: $smallFontSize;
      line-height: 1.3;

      .task-id {
        width: calc(100% - 8px);
        margin-left: 4px;
        font-weight: bold;
        color: $white;
      }

      .task-title {
        color: black;
        font-weight: 500;
        opacity: 0.7;
        display: block;
      }

      .task-title {
        color: black;
        font-weight: 500;
        opacity: 0.7;
        display: block;
      }

      .project-title {
        color: black;
        font-weight: 500;
        opacity: 0.5;
        // font-style: italic;
        // Add other styles as needed
      }
    }

    .background {
      // opacity: 0.8;
      z-index: -1;
      content: "";
      background-color: $accent;
      border-radius: $borderRadius;
      width: calc(100% - 2px);
      height: calc(100% - 2px);
      top: 1;
      left: 1px;
      position: absolute;

      &::after {
        z-index: -1;
        content: "";
        border-radius: $borderRadius;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        position: absolute;
        opacity: 0.3;
      }
    }

    &.unconfirmed {
      .background {
        background-color: #ccc !important;
      }
    }

    &.default {
      .background {
        background-color: $accent;
      }
    }

    &.under-review {
      .background {
        &::after {
          background: repeating-linear-gradient(-55deg, #aaa, #aaa 8px, #ddd 8px, #ddd 16px);
          background-size: 200% 100%;
          background-position-x: -50px;
          display: block;
        }
      }
    }

    &.overdue {
      .background {
        background-color: $bad;
      }
    }

    &.due-on-the-day {
      .background {
        background-color: #8b60f1;
      }
    }

    &.archived {
      .background {
        background-color: #ddd;
      }
    }

    &.finished {
      .background {
        background-color: $good;
      }
    }

    &.pseudo-task {
      .background {
        background-color: saturate(darken($standardText, 0), 100) !important;
      }
    }
  }
}
