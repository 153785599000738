@import "../../../../Variables.scss";

.organisation-main-settings {
  .setting-item {
    display: flex;
    align-items: center;
    &:not(:last-child) {
      margin-bottom: 1rem;
    }

    .setting-name {
      margin-right: 1rem;
      font-weight: 500;
      width: 12rem;
      flex-shrink: 0;
    }
  }

  .logo-container {
    img {
      max-width: 150px;
      border: 1px solid $border;
    }
  }
}
