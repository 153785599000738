@import "../../../Variables";

.purchase-order-settings-page {
  .info-item.inline {
    .label-container {
      width: 150px;
    }
    .value-container {
      width: 100%;
    }
    .ant-select {
      width: 100%;
    }
  }
}
