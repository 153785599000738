@import "../../Variables.scss";

.user-hierarchy {
  display: flex;
  gap: 0.5rem;
  overflow-x: auto;

  .user-container {
    border-radius: $borderRadius;
    align-items: center;
    display: flex;
    flex-direction: column;

    &.has-subordinates {
      background-color: rgba($standardText, 0.07);
      padding: 0.5rem;
    }

    .direct-subordinates {
      display: flex;
      gap: 0.5rem;
      margin-top: 0.5rem;
    }

    .user-details {
      border: 1px solid $neutral;
      border-radius: $borderRadius;
      display: flex;
      flex-direction: column;
      padding: 0.5rem 1rem 0.8rem;
      align-items: center;
      gap: 0.5rem;
      width: 200px;
      background-color: rgba($white, 0.5);
      position: relative;

      .add-user-button {
        max-width: 150px;
      }

      .remove-user-button {
        position: absolute;
        top: 0.2rem;
        right: 0.2rem;
        font-size: 1rem;
        cursor: pointer;

        &:hover {
          color: $accent;
        }
      }
    }

    .avatar {
      position: relative;
    }
  }
}
