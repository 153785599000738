.quickbooks-callback-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100vh;

  .quickbooks-callback-message {
    display: flex;
    flex-direction: column;
    align-items: center;

    .quickbooks-callback-message {
      font-size: 1.5rem;
    }
  }
}
