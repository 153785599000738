@import "../../Variables";

.user-computers {
  margin-top: 1rem !important;

  .no-configured-computers {
    color: $neutralDark;
    width: 100%;
    text-align: center;
    display: block;
  }

  .computer-item {
    max-width: 700px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    padding-bottom: 1rem;
    margin-bottom: 1rem;
    flex-wrap: wrap;

    &:not(:last-child) {
      border-bottom: 1px solid $border;
    }

    .computer-name {
      font-weight: 500;
      margin-left: 0.5rem;
      margin-right: 0.5rem;
      flex-shrink: 0;
    }

    .active-computer-mark {
      width: 0.7rem;
      height: 0.7rem;
      border-radius: 50%;
      background-color: $good;
      flex-shrink: 0;
    }

    .inactive-computer-mark {
      width: 0.7rem;
      height: 0.7rem;
      border-radius: 50%;
      background-color: $neutral;
      flex-shrink: 0;
    }

    .logstream-name-and-link-info {
      position: relative;
      min-width: 300px;

      .link-info {
        position: absolute;
        transform: translateY(-50%);
        top: 50%;
        left: 2rem;
        transition: all 300ms;
        opacity: 1;
        pointer-events: none;

        .link-installed,
        .link-running {
          color: $good;
        }
        .link-not-installed,
        .link-not-running {
          color: $bad;
        }
      }

      .logstream-name {
        opacity: 0;
        transition: all 300ms;
        margin-left: 2rem;
        position: relative;
        top: 0.7rem;
        user-select: none;

        .copy-icon {
          color: $accent;
        }

        &::after {
          content: "Debug information";
          position: absolute;
          top: -1.6rem;
          left: 0rem;
          color: $neutralDark;
        }
      }

      &.with-logstream {
        &:hover {
          .logstream-name {
            opacity: 1;
          }
          .link-info {
            opacity: 0;
          }
        }
      }
    }

    .delete-computer {
      margin-left: auto;
      flex-shrink: 0;
    }
  }

  @media (max-width: 750px) {
    .computer-item {
      flex-direction: column;

      .link-info {
        pointer-events: none;
        display: flex;
        flex-direction: column;
        gap: 0;
        position: relative !important;
        top: 0 !important;
        transform: unset !important;
        left: 0 !important;
        text-align: center;

        br {
          display: none;
        }
      }

      .delete-computer {
        margin-left: unset;
        margin-top: 0.5rem;
      }

      .logstream-name {
        display: none;
      }
    }
  }
}
