@import "../Variables.scss";

.working-hours {
  .table-header {
    display: flex;
    gap: 0.5rem;
    align-items: center;

    .ant-switch {
      width: 2rem !important;
    }

    .info-item {
      margin-top: 0;
    }
  }

  .row-actions {
    display: flex;
    gap: 0.5rem;
    align-items: center;
  }
}
