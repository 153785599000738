@import "../../Variables";

.purchase-order-actions-container {
  background-color: $background;

  .purchase-order-actions {
    .ant-card-body {
      padding-top: 0.3rem;
      padding-bottom: 1rem;
    }

    .actions-container {
      flex-shrink: 0;
      gap: 1rem;
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      align-items: center;
      margin-bottom: -0.7rem;

      .download-and-send {
        margin-left: auto;
        display: flex;
        flex-wrap: wrap;
        gap: 1rem;
      }
    }
  }
}
