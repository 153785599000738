@import "../../Variables.scss";

.file-sidebar {
  .ant-card-body {
    padding-bottom: 0rem;
  }

  .action-buttons {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;

    & > * {
      width: 100% !important;
      max-width: 17rem;
    }

    .publish {
      width: 100% !important;

      button:first-child {
        width: 100% !important;
      }
    }
  }

  &.split-layout {
    .action-buttons {
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;

      & > * {
        width: 11rem !important;

        &.add-revision-to-all-sheets {
          width: 13rem !important;
        }
      }
    }

    .stats {
      columns: 2;
    }
  }

  .external-references-items {
    a {
      color: $standardText !important;
    }

    .anticon {
      color: $standardText !important;
    }
  }

  .ant-card-body {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding-top: 0;

    .open-revision-pdf {
      width: 100%;

      & > button:first-child {
        width: 100%;
        margin: 0 !important;
      }
    }

    .file-settings {
      border-top: 1px solid $border;
      padding-top: 1rem;
      margin-top: 0.5rem;
      display: flex;
      flex-direction: column;
      align-items: center;

      .info-item,
      .external-references-container {
        width: 100%;
        max-width: 400px;
      }
    }

    .external-references-container {
      margin-bottom: 2rem;
      border-bottom: 1px solid $border;
      padding-bottom: 1rem;

      .external-references-items {
        margin-top: 1rem;

        .item {
          margin-top: 0.5rem;
          display: block;
          border: 1px solid $neutral;
          padding: 0.3rem 0.6rem;
          border-radius: $borderRadius;
          color: lighten($neutralDark, 10);

          &:hover {
            background-color: $hover;
            color: $neutralDark;
          }

          .anticon {
            margin-right: 0.5rem;
            color: lighten($neutralDark, 10);

            &:hover {
              color: $standardText;
            }
          }
        }
      }
    }

    .s3-button-container,
    .live-pdf-button-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 1rem;

      button,
      a {
        width: 100%;
        max-width: 17rem;
        margin: 0 auto;
      }
    }
  }
}

.async-jobs-for-file-overlay {
  .ant-popover-title {
    padding-top: 1rem;
    padding-bottom: 1rem;
    font-size: $regularFontSize;
  }
  .ant-table-wrapper {
    width: 700px;
  }
}

.file-sidebar-async-jobs-table-container {
  width: 800px;
}

.file-sidebar-async-jobs-table-overlay {
  .ant-dropdown-menu-item-active {
    cursor: unset !important;
    background-color: unset !important;
  }
}
