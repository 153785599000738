@import "../../Variables.scss";

.requests-page {
  background-color: $background;
  padding-right: 0.5rem;

  .page-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
    padding: 0.5rem;
    margin-left: -0.5rem;
    margin-right: -0.5rem;
    background-color: $white;

    .create-request-button {
      margin-left: auto;
    }
  }

  .client-logo-link {
    justify-content: flex-start;
  }
}
