@import "../Variables";

.reset-password-page {
  padding-top: 2rem;

  .main-space {
    width: 500px;
    max-width: 100vw;
  }
  .card {
    width: 100%;
    margin: 0 auto;
  }

  .title.ant-typography {
    text-align: center;
    margin-bottom: 1.3rem;
  }

  .forgot-password {
    margin-top: -1.3rem;
    margin-bottom: 2rem;
    font-size: 0.8rem;
    text-align: right;
  }
  .send-code-success {
    padding-top: 4px !important;
    padding-bottom: 4px !important;

    .ant-alert-icon {
      top: 9px;
    }
  }

  .ant-alert-error {
    margin-bottom: 0rem;
  }

  .back-to-sign-in {
    margin-top: 1rem;
    width: 100%;
  }

  .send-code {
    color: $accent;
    border-color: $accent;
    width: 100%;
  }

  .ant-alert-message {
    text-align: center;
    width: 100%;
    display: inline-block;
  }
}
