@import "../Variables";

.avatar-list {
  text-align: right;
  display: flex;
  align-items: center;

  .avatar {
    margin-right: -0.7rem;
  }

  .others-container {
    border-radius: 50%;
    border: 2px solid transparent;
    transition: all 300ms;
    position: relative;

    .others {
      position: relative;
      background-color: $neutral;
      cursor: pointer;
      display: block;
      color: darken($neutralDark, 30);
      text-align: center;
      overflow: hidden;
      background-size: cover;
      border-radius: 50%;
      display: block;
      width: 2.2rem;
      height: 2.2rem;
      line-height: 1.9rem;
      border: 2px solid white;
      transition: all 300ms;
      font-weight: 700;
      font-size: $extraSmallFontSize;
      letter-spacing: -1px;

      .plus-sign {
        position: relative;
        top: -1px;
      }
    }
    &.is-selected {
      border-color: $neutralDark;
    }
  }
}

.avatar-list-dropdown-menu-overlay {
  overflow-y: auto;
  max-height: 40vh;
  @include with-soft-shadow;
  background-color: $white;
}
