// $fontFamily: "Roboto", sans-serif;
@import "./Variables.scss";

@font-face {
  font-family: "Inter";
  src: local("Inter"), url("./fonts/Inter-Regular.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "Inter";
  src: local("Inter"), url("./fonts/Inter-SemiBold.ttf") format("truetype");
  font-weight: 500;
}

body {
  margin: 0;
  font-family: $fontFamily;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  pre {
    font-family: $fontFamily;
  }

  &.cypress {
    font-family: serif;

    .ant-tooltip {
      display: none;
    }
  }
}

* {
  box-sizing: border-box;
}

.toast {
  position: relative;
  top: -20px;
}
