@import "../../Variables";

.create-custom-field-modal {
  width: 850px !important;

  .option-item-container {
    display: flex;
    justify-content: space-around;
    width: 100%;
    flex-direction: column;

    .ant-form-item {
      flex-direction: column;
    }

    .ant-form-item-label {
      width: 200px;
      text-align: center;
    }
  }

  .option-color-marker {
    width: 22px;
    height: 22px;
    border: 2px solid $neutral;
    position: relative;
    border-radius: $borderRadius;
    box-sizing: border-box;
    cursor: pointer;
    position: relative;

    .twitter-picker {
      position: absolute !important;
      top: 32px;
      left: -12px;
      z-index: 1;
    }
  }

  .option-item-container {
    .option-color-marker,
    .show-option-as-tag .ant-checkbox {
      margin-left: 5.5rem;
    }
  }

  .submit-container {
    text-align: center;
  }
}
