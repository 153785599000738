@import "../../Variables.scss";

.rejection-details {
  display: flex;
  gap: 0.5rem;
  flex-direction: column;

  textarea {
    height: 9rem !important;
  }

  .ant-typography {
    display: block;
    font-weight: 500;
  }

  .ant-select {
    width: 100%;
    margin-bottom: 1rem;
  }
}
