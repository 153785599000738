@import "../../Variables";

.cookie-policy-modal {
  width: 1000px !important;
  max-width: 90vw;

  .ant-modal-body {
    padding-top: 0.5rem;
  }

  .cookie-policy {
    width: 100%;

    .main-section {
      padding-top: 0;
    }

    footer {
      display: none;
    }
  }

  .ant-modal-title {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .last-updated-date {
      font-size: 0.7rem;
      margin-left: 2rem;
      color: $neutralDark;
    }
  }

  p,
  li {
    margin-bottom: 0.6rem;
    font-size: 0.8rem;
    // letter-spacing: -0.3px;
  }
  li {
    margin-bottom: 1rem;
  }
  ul {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  h2 {
    border-bottom: 1px solid $neutral;
    margin-bottom: 2rem;
    margin-top: 2rem;
    text-align: center;
    font-size: 1rem;
    font-weight: 500;
    // letter-spacing: -0.3px;
  }

  h3 {
    margin-bottom: 1rem;
    // letter-spacing: -0.3px;
    font-size: 0.9rem;
    font-weight: 500;
  }

  .cookie-policy-modal {
    margin-top: -1rem;
  }

  .submit-container {
    text-align: center;
    margin-bottom: 1rem;
  }
}
