@import "../../Variables";

.request-picker {
  width: 100%;

  .ant-select-selector {
    height: 32px !important;
  }

  .ant-select-selection-item {
    color: $accent;
    padding-bottom: 4px !important;

    .label {
      position: relative;
      top: 1px;
    }
  }

  .ant-select-arrow {
    color: $accent;
  }
}

.request-picker-option-inner {
  display: flex;
  align-items: center;
  width: 100%;
  padding-bottom: 0.3rem;
  margin-bottom: 0;
  border-bottom: 1px solid $neutral;

  .request-title {
    font-size: $extraSmallFontSize;
    color: $standardText;
    font-weight: 500;
  }
}

.request-picker-option-label {
  position: relative;
  top: 2px;
  display: flex;
  align-items: center;

  .request-picker-option-label-task-title {
    font-size: $extraSmallFontSize;
    font-weight: 500;
  }
}
