@import "../Variables.scss";

.spreadsheet {
  width: 100%;
  height: 500px;
  overflow: auto;
  padding-bottom: 0.5rem;
  margin-bottom: 1rem;

  .row {
    display: flex;

    .cell {
      display: inline-block;
      height: 1.6rem;
      border: 1px solid $neutral;
      border-right-width: 0;
      border-bottom-width: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      color: $standardText;
      cursor: default;
      flex-shrink: 0;
      position: relative;

      &:first-child {
        position: sticky;
        left: 0;
        z-index: 1;
        border-right: 1px solid $neutral;
        margin-right: -1px;
      }

      .cell-content {
        pointer-events: none;
      }

      &.cell-highlighted,
      &.cell-selected {
        &::after {
          content: "";
          position: absolute;
          top: -1px;
          left: -1px;
          border: 2px solid $accent;
          width: calc(100% - 2px);
          height: calc(100% - 2px);
          z-index: 1;
          pointer-events: none;
        }
      }

      &.cell-selected {
        .cell-content {
          pointer-events: unset;
        }

        .input {
          z-index: 2;
          width: 100%;

          input {
            border-radius: 0;
            width: 100%;
            border: unset;
            background-color: unset;
          }
        }
      }

      &.cell-row-number,
      &.cell-top-left {
        pointer-events: none;
        font-weight: 500;
        color: $lightText;
        pointer-events: none;
        background-color: lighten($background, 3);
        width: 50px;
      }

      &.cell-formula {
        font-weight: 500;
        color: $lightText;
      }

      &:last-child {
        border-right-width: 1px;
      }
    }

    &:last-child {
      .cell {
        border-bottom-width: 1px;
      }
    }
  }

  .row-header {
    position: sticky;
    top: 0;
    z-index: 2;
    margin-bottom: -1px;

    .cell {
      font-weight: bold;
      color: $lightText;
      background-color: lighten($background, 3);
      position: relative;
      overflow: visible;
      text-overflow: clip;
      white-space: nowrap;
      user-select: none;
      border-bottom: 1px solid $neutral;

      .cell-resize-right {
        position: absolute;
        width: 5px;
        background-color: $accent;
        height: calc(100% + 2px);
        top: -1px;
        right: -3px;
        z-index: 2;
        cursor: ew-resize;
        opacity: 0;

        &:hover {
          opacity: 1;
        }

        &::before {
          content: "";
          position: absolute;
          width: 14px;
          height: 100%;
          left: 50%;
          top: 0;
          transform: translateX(-50%);
        }
      }
    }
  }
}
