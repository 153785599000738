@import "../../Variables.scss";

.upload-file-pdf-modal {
  .note {
    color: $lightText;
    margin-top: -1rem;
    display: block;
    margin-bottom: 1rem;
  }

  &.no-sheets {
    .ant-form-item-label {
      display: none;
    }
  }

  .ant-space,
  .ant-space-item,
  .ant-form-item,
  .ant-row,
  .ant-form-item-control,
  .ant-form-item-control-input,
  .ant-form-item-control-input-content {
    width: 100%;
  }
  .upload-container {
    width: 100%;

    .upload-box {
      width: 100% !important;
    }
  }

  .submit-container {
    display: flex;
    justify-content: center;
  }
}
