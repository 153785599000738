@import "../../Variables.scss";

.time-off-card {
  margin-top: 1rem !important;

  .time-off-table {
    .ant-table-container {
      overflow-x: auto;
    }
    .ant-table-content {
      min-width: 650px;
    }
  }

  .card-header {
    @media screen and (max-width: 850px) {
      div {
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .allowance-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 1rem;

    button {
      margin-left: 0;
    }

    @media (max-width: 850px) {
      flex-direction: column;
      align-items: flex-start;
      gap: 0.5rem;
    }
  }

  .holiday-past {
    opacity: 0.5;
  }

  .holiday-in-progress {
    * {
      color: $accent !important;
      font-weight: 500;
    }
  }

  .current-interval-time-off-label {
    font-size: $smallFontSize;
  }
}
