@import "../Variables.scss";

.client-communication-card {
  max-width: 395px;
  flex-grow: 0;

  .info-item {
    margin-bottom: 0.8rem;
    justify-content: center;

    .label-container {
      width: 115px;
      flex-shrink: 0;
      margin-bottom: 0.2rem;
      display: flex;

      .anticon {
        position: relative;
        top: 1px;
      }
    }

    .value-container {
      width: 100%;
    }
  }

  .card-body {
    margin-bottom: -1rem;
    margin-top: -1rem;

    .ant-select {
      width: 100%;
    }
  }

  .public-upload-container .value-container {
    display: flex;
    gap: 0.5rem;
  }
}
