@import "../../Variables";

.sheet-list-item {
  display: block;
  padding: 0.4rem;
  position: relative;

  .sheet-name {
    width: calc(100% - 3.5rem);
    margin: 0 auto;
    text-align: center;
    color: $lightText;
    pointer-events: none;
    position: relative;
  }

  &:hover {
    background-color: $hover;
  }

  &.selected {
    color: $accent;
    background-color: $hover;

    input {
      font-weight: 500;
    }

    &::after {
      content: "";
      position: absolute;
      right: 0px;
      z-index: 2;
      top: 0;
      height: 100%;
      width: 2px;
      background-color: $accent;
    }
  }
}
