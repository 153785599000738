@import "../Variables";

.task-details-page {
  max-width: 100%;
  padding: 0.5rem;
  padding-left: 0;
  padding-top: 0.5rem;
  overflow-x: hidden;

  & > .ant-breadcrumb-wrapper {
    margin-bottom: 0.5rem;
  }

  .ant-breadcrumb {
    margin-bottom: 0rem;
  }

  .summary-and-client-communication {
    display: flex;
    gap: 0.5rem;

    .task-sidebar {
      margin-top: 1rem;
    }
  }

  @media (max-width: 1370px) {
    .summary-and-client-communication {
      flex-direction: column;

      .client-communication-card {
        max-width: unset;
      }
    }
  }

  .task-main-tabs {
    .ant-tabs-nav-list {
      margin-left: 0.1rem;
    }

    .ant-tabs-nav-operations {
      display: none;
    }

    .ant-tabs-tabpane {
      padding-bottom: 1rem;
    }
  }

  .task-main-tabs > .ant-tabs-nav > .ant-tabs-nav-wrap > .ant-tabs-nav-list > button {
    font-size: 1rem;
  }

  .page-content {
    display: flex;

    & > div:not(:last-child) {
      margin-right: 0.5rem;
    }
  }

  @media (max-width: 600px) {
    .ant-timeline-item-label,
    .ant-timeline-item-tail,
    .ant-timeline-item-head {
      display: none;
    }
    .ant-timeline-item-content {
      margin-left: 0;
    }
  }

  .main-page-content {
    width: calc(100% - 300px);

    @media (max-width: $mobileBreakpoint) {
      width: 100%;
    }
  }
}
