@import "../Variables";

.draggable-task {
  margin-bottom: 0.3rem;

  .task-list-item {
    &.card {
      margin-bottom: 0rem;
    }
  }
}

.task-list-item {
  position: relative;
  border: 1px solid $neutral;
  padding: 0.15rem 0.5rem 0.2rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  opacity: 1;
  background-color: $white;
  margin-bottom: -1px;
  gap: 0.8rem;

  @media (max-width: 1100px) {
    gap: 0.65rem;
  }

  @media (max-width: 1000px) {
    gap: 0.5rem;
  }

  @media (max-width: 900px) {
    gap: 0.4rem;
  }

  @media (max-width: 750px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 0;
  }

  &.unconfirmed {
    opacity: 0.35;
    filter: grayscale(80%);
  }

  &.card {
    margin: 0.3rem;
    border-radius: $borderRadius;
    border: unset !important;
    @include with-shadow;
    border-radius: $borderRadius;
    border: unset !important;
  }

  &:hover {
    background-color: $hover;
    border-color: $accent;
    z-index: 2;
  }

  &.drag-hidden {
    height: 0;
    min-height: 0;
    opacity: 0;
  }

  .task-id-tag {
    width: 7.5rem;
    justify-content: center;
    flex-shrink: 0;

    @media (max-width: 700px) {
      display: none;
    }
  }

  .avatar {
    width: 13rem;
    flex-shrink: 0;
  }

  .title-and-tags {
    display: flex;
    flex-direction: column;
    width: 100%;

    .title {
      width: 100%;
      text-align: left;
      font-weight: 500;
      font-size: 0.8rem;
      line-height: 1.1;
      margin-top: 0.1rem;
      margin-bottom: 0.2rem;
    }

    .project-badge {
      background-color: unset !important;
      color: $neutralDark !important;
      text-align: left !important;
      padding: 0 !important;
      pointer-events: none;
      font-size: 0.75rem;
    }

    .dashboard-item-tags {
      @media (max-width: 700px) {
        display: none;
      }
    }
  }

  .due-date {
    font-size: 0.65rem;
    font-weight: 500;
    text-transform: uppercase;
    color: darken($neutralDark, 10);
    width: 5rem;
    flex-shrink: 0;
    text-align: center;

    @media (max-width: 1000px) {
      display: none;
    }
  }

  .project-badge {
    color: $white;
    border-radius: $borderRadius;
    padding: 0.2rem 0.5rem;
    font-size: 0.65rem;
    letter-spacing: 0.3px;
    min-width: 10rem;
    transition: unset;
    text-align: center;
    display: inline-block;
    font-weight: 500;
    flex-shrink: 0;
  }

  .client-logo-container {
    width: 5rem;
    text-align: center;
    flex-shrink: 0;

    .client-logo {
      max-height: 2rem;
      max-width: 100%;
    }
  }

  .status {
    text-align: right;
    text-transform: uppercase;
    font-size: 0.65rem;
    color: darken($neutralDark, 10);
    font-weight: 500;
    flex-shrink: 0;
    width: 6rem;
    text-align: center;
  }
}
