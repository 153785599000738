@import "../Variables";

.copy-link-button {
  display: inline;
  position: relative;
  top: 3px;
  margin-left: 0.2rem;
  cursor: pointer;

  .icon {
    color: $standardText;
    fill: $standardText;
  }

  &:hover {
    .icon {
      color: $accent;
      fill: $accent;
    }
  }
}
