@import "../../Variables.scss";

.invoice-activity-card {
  .activity-filters {
    display: flex;
    gap: 3rem;
    align-items: center;
  }

  .invoice-filter-item {
    display: flex;
    gap: 0rem;
    align-items: center;

    .label {
      font-weight: 500;
      font-size: 0.8rem;
    }
  }
  .ant-table-cell {
    white-space: pre-wrap !important;
  }
}
