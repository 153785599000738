@import "../../Variables.scss";

.task-budget-card {
  margin-bottom: 1rem !important;

  .info-items {
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    .info-items-column {
      width: 100%;
      display: flex;
      gap: 3rem;
      flex-wrap: wrap;

      @media (max-width: 600px) {
        gap: 1rem;
      }
    }
  }
}
