@import "../Variables.scss";

.custom-application-page {
  margin-top: -1rem;

  .page-actions {
    display: flex;
    gap: 0.5rem;
    justify-content: center;
    padding-bottom: 0.5rem;
    padding-top: 0.5rem;
  }
}
