@import "../Variables.scss";

.monitoring-page {
  td {
    font-weight: 500;
  }
  .ant-table-row {
    &.ONLINE {
      td {
        color: $good !important;
      }
    }
    &.OFFLINE {
      td {
        color: $bad !important;
      }
    }
  }
}
