@import "../../Variables";

.toolbar {
  background-color: $white;
  width: 3rem;
  z-index: 1;
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  border-right: 1px solid $neutral;
  overflow-y: auto;

  &.is-preview-visible {
    opacity: 0.3;
    pointer-events: none;
  }

  .toolbar-separator {
    width: 100%;
    height: 1px;
    background-color: $neutral;
  }

  .item {
    color: $standardText;
    height: 3rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: relative;

    &.disabled {
      opacity: 0.2;
      pointer-events: none;
    }

    &::before {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      height: 100%;
      transform: translate(-50%, -50%) scale(0.8);
      background-color: $standardText;
      border-radius: $borderRadius;
      opacity: 0;
      transition: all 300ms;
      z-index: -1;
    }

    .icon {
      width: 20px;
      height: 20px;
      fill: $standardText;
      position: relative;
      transition: all 300ms;
    }

    @keyframes scale-in {
      0% {
        transform: scale(0);
      }
      100% {
        transform: scale(1);
      }
    }

    .unsaved-changes-indicator {
      display: block;
      position: absolute;
      top: 0.8rem;
      right: 0.3rem;
      width: 0.5rem;
      height: 0.5rem;
      background-color: $accent;
      border-radius: 50%;
      z-index: 1;
      transition: all 600ms;
      transform: translate(-50%, -50%) scale(0);
      transform-origin: 50% 50%;
      transition-timing-function: cubic-bezier(0.175, 1, 0.22, 2.2);

      &.visible {
        transform: translate(-50%, -50%) scale(1);
      }
    }

    &.active,
    &:hover {
      position: relative;

      &::before {
        opacity: 1;
      }

      .icon,
      .anticon {
        fill: $white;
        color: $white;
      }
    }
  }
}
