@import "../../Variables";

@page {
  size: A4 portrait;
  margin: 0;
}

.report {
  h1.ant-typography,
  h2.ant-typography,
  h3.ant-typography,
  h4.ant-typography,
  h5.ant-typography,
  h6.ant-typography,
  p.ant-typography,
  span.ant-typography,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  .ant-typography,
  div.ant-typography,
  th.ant-table-cell {
    color: #333;
    margin-top: 0;
    margin-bottom: 0;
  }

  h1 {
    color: #000;
  }

  td,
  th,
  td * {
    color: #555 !important;
  }

  h2 {
    font-size: 1rem;
  }

  p {
    font-size: 0.7rem;
  }

  .attachment-inserted-image {
    max-width: calc(100% - 1rem);
  }

  .subsection {
    padding-top: 4rem;
    padding-bottom: 2rem;
    h1 {
      margin-bottom: 2rem;
    }
    h2 {
      margin-bottom: 1rem;
    }

    @media print {
      page-break-inside: avoid;
    }
  }
}

.report-preloader {
  width: $reportWidth;
  height: 842px;
  max-width: 100%;
  background-color: white;
  border-radius: $borderRadius;
  display: flex;
  justify-content: center;
  align-items: center;

  .anticon {
    font-size: 3rem;
    color: $accent;
  }
}
