@import "../../../Variables.scss";

.template-object-resize-handler {
  $handlerOffset: 6px;
  $handlerOffsetWithBorder: 8px;

  position: absolute !important;
  top: -1px;
  left: -1px;
  width: calc(200% + 4px);
  height: calc(200% + 4px);
  transform: scale(0.5);
  // position and scale are overridden in TemplateEditorCanvas.scss for higher zoom levels

  transform-origin: top left;
  // border: 1px solid $accent;
  border: 1px solid transparent;
  cursor: grab;
  z-index: 3;

  &.no-events {
    pointer-events: none;
  }

  .template-object-resize-handler {
    position: absolute !important;
    width: 15px;
    height: 15px;
    background-color: $accent;
    border-radius: 50%;
    z-index: 3;
  }

  .template-object-resize-handler-left {
    left: 0;
    top: 50%;
    cursor: ew-resize;
  }

  .template-object-resize-handler-right {
    left: 100%;
    top: 50%;
    cursor: ew-resize;
  }

  .template-object-resize-handler-top {
    top: 0;
    left: 50%;
    cursor: ns-resize;
  }

  .template-object-resize-handler-bottom {
    top: 100%;
    left: 50%;
    cursor: ns-resize;
  }

  .template-object-resize-handler-top-left {
    top: 0;
    left: 0;
    cursor: nwse-resize;
  }

  .template-object-resize-handler-top-right {
    top: 0;
    left: 100%;
    cursor: nesw-resize;
  }

  .template-object-resize-handler-bottom-left {
    top: 100%;
    left: 0;
    cursor: nesw-resize;
  }

  .template-object-resize-handler-bottom-right {
    top: 100%;
    left: 100%;
    cursor: nwse-resize;
  }
}
