@import "../../Variables";

.review-file-list {
  border-right: 1px solid $neutral;
  padding: 0.5rem;
  height: 100%;
  width: 15rem;
  overflow-y: auto;
  flex-shrink: 0;
  position: relative;

  .reviewer-picker {
    * {
      font-size: 0.8rem !important;
    }
    .ant-select-selector,
    .ant-select-selection-item {
      padding-right: 0 !important;
    }
    .anticon,
    .ant-select-icon,
    .ant-select-arrow {
      display: none !important;
    }
  }

  .toggle {
    position: absolute;
    right: 0rem;
    top: 0rem;
    padding: 0.4rem 0.15rem;
    z-index: 1;
    cursor: pointer;

    .anticon {
      color: $neutralDark;
    }

    &:hover {
      background-color: $hover;
    }
  }

  &.is-closed {
    width: 1.2rem;
    border-right: unset;
    border-right: 1px solid $neutral;

    .section {
      opacity: 0;
      display: none;
    }
  }

  .users-filter {
    min-width: unset;
    width: 100%;
  }

  .open-icon {
    position: relative;
    top: 3px;
  }

  .section {
    padding-bottom: 0.5rem;
    margin-bottom: 0.5rem;
    text-align: left;
    transition: opacity 300ms;
    transition-delay: 300ms;
    overflow-x: visible;
    opacity: 1;

    &:not(:last-child) {
      border-bottom: 1px solid $neutral;
    }

    .section-label {
      text-align: center;
      font-size: 0.9rem;
      font-weight: 500;
      margin-bottom: 0.3rem;
    }
  }

  button {
    display: block;
    width: 100%;
    margin: 0;
    margin-bottom: 0.3rem;
  }

  .section-sheets {
    .file-item {
      padding: 0.3rem 0;
      display: block;

      .file-type {
        padding-left: 0.3rem;
      }

      &.request-form {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 0;
        font-weight: 500;
        padding-left: 0.3rem;
      }

      &:not(:last-child) {
        border-bottom: 1px solid $border;
      }
    }

    .file-type {
      font-size: 0.8rem;
      font-weight: 500;
      margin-top: 0.5rem;
      display: block;
      position: sticky;
      top: -8px;
      width: 100%;
      z-index: 1;

      &.sheet-item {
        margin-left: 0;
      }

      .icon {
        cursor: pointer;
        display: inline-block;
        position: relative;
        margin-left: 0.5rem;

        &:hover {
          color: $accent;
          fill: $accent;
        }
      }
    }

    .file-without-sheets {
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
      padding-right: 0.3rem;
      gap: 0.5rem;

      &:hover,
      &.selected {
        color: $accent;

        .file-type {
          color: $accent;
        }
      }

      .sheet-item {
        border: unset !important;
      }

      .file-type {
        margin-top: 0;
      }
    }

    .sheet-item {
      text-align: left;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-left: 0.7rem;
      cursor: pointer;
      padding: 0 0.3rem;
      gap: 0.5rem;

      &:not(:last-child) {
        border-bottom: 1px solid $border;
      }

      &:hover,
      &:hover *,
      &.selected,
      &.selected * {
        color: $accent;
      }

      .sheet-name {
        font-size: 0.75rem;
      }
    }
  }

  @media only screen and (max-width: $reviewPageMobileBreakpoint) {
    position: fixed;
    top: $reviewTopBarHeight;
    left: 0;
    background-color: $white;
    z-index: 10;
    width: 100%;
    transition: all 500ms;
    transform: translateX(0);
    height: calc(100% - $reviewTopBarHeight);

    &.is-closed {
      width: 100%;
      transform: translateX(-100%);

      .section {
        opacity: 1 !important;
        display: block !important;
      }
    }
  }

  &.is-minimal {
  }
}
