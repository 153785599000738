@import "../../Variables";

.add-quote-line-item-to-invoice-line-item-modal {
  width: 700px !important;

  .ant-space-item {
    width: 100%;
  }

  .submit-container {
    text-align: center;
  }
}
