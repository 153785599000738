@import "../Variables";

.audit {
  .audit-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem;
    &:not(:last-child) {
      border-bottom: 1px solid $border;
    }

    .item-date {
      font-weight: 500;
      color: darken($neutralDark, 10);
      font-size: 0.7rem;
    }

    .item-content {
      .item-title {
        display: flex;
        align-items: center;
        font-weight: 500;

        .avatar,
        img {
          margin-right: 0.7rem;
        }
      }
      .item-icon {
        img {
          width: 20px;
        }
      }
    }
  }
}
