@import "../../Variables";

.create-stock-item-maintenance-modal {
  width: 1100px !important;
  max-width: 90vw;

  .ant-select {
    width: 100% !important;
  }

  .ant-radio-wrapper,
  .ant-checkbox-wrapper {
    display: flex;
    align-items: center;
  }

  .loading-button {
    width: 75px;
  }

  .submit-button-container {
    display: flex;
    align-items: center;

    .create-another-label-and-checkbox {
      display: flex;
      margin-left: 2rem;
      cursor: pointer;

      span.label {
        margin-left: 0.5rem;
      }
    }
  }
}
