@import "../Variables.scss";

.purchase-order-list-item {
  margin-bottom: 0.3rem !important;

  .ant-tag {
    min-width: 130px;
  }

  .ant-card-body {
    padding-top: 0.8rem !important;
    padding-bottom: 0.8rem !important;
    display: flex;
    align-items: center;
  }

  .purchase-order-total {
    font-weight: 500;
  }

  .purchase-order-title {
    margin-left: 0.5rem;
  }
}
