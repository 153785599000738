@import "../../Variables";

.user-item-container {
  padding-bottom: 0.5rem;
}

.users-page {
  .user-item {
    width: 100%;

    &.ant-card {
      &:not(:last-child) {
        margin-bottom: 0.5rem;
      }

      .ant-card-body {
        padding-bottom: 0.2rem;
        padding-top: 0.2rem;
      }
    }

    &.is-collapsed {
      .card-header .ant-divider {
        display: none;
      }
    }

    .secondary-info {
      margin-top: -1.5rem;
    }

    .info-item {
      width: 100%;
      margin: 0;

      .label-container {
        flex-shrink: 0;
      }

      .value-container {
        width: 100%;
        & > *:not(.permissions-table-wrapper, .group-memberships-table) {
          width: 100%;
          max-width: 400px;
        }
      }
    }

    .card-title {
      display: flex;
      gap: 1rem;
      align-items: center;
      font-size: $smallFontSize;
    }

    .primary-info {
      display: flex;
      gap: 1rem;
      align-items: center;

      .info-item {
        .value-container {
          width: 100%;
        }

        &.job-title,
        &.qualifications {
          width: 300px;
          flex-shrink: 0;
        }
      }
    }

    .expand-button,
    .collapse-button {
      padding-left: 0.5rem;
      padding-right: 0.5rem;
      margin-right: 1rem;
    }

    .user-details {
      display: flex;
      flex-direction: column;
    }

    .tags {
      margin-top: 0.2rem;
      display: flex;
      flex-wrap: wrap;

      .ant-tag {
        margin-top: 0.2rem;
      }
    }

    .name-container {
      width: 200px;
      &:hover {
        .name-text {
          color: $accent;
        }
      }

      * {
        white-space: nowrap;
      }
    }

    .avatar .symbol {
      width: 2.5rem !important;
      height: 2.5rem !important;
    }

    .ant-select-multiple {
      width: 100%;
      max-width: 700px !important;
    }

    .ant-select-selection-overflow-item {
      margin-right: 0.5rem;
      font-weight: 500;
    }

    .add-user-permissions-button {
      margin-bottom: 0.5rem;
    }
  }
}
