@import "../../Variables";

.sprint-item {
  margin-bottom: 0.5rem !important;
  width: 100%;
  transition: all 300ms !important;
  border: 2px solid transparent;
  border-radius: 4px;
  box-sizing: border-box;
  padding-right: 2px;

  &::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: $standardText;
    z-index: 1;
    pointer-events: none;
    opacity: 0;
    transition: all 300ms;
  }

  &.list-being-dragged-over {
    border-color: $accent;
    position: relative;

    &::after {
      opacity: 0.07;
    }

    * {
      pointer-events: none;
    }
  }

  &.drag-started-on-this,
  &.list-being-dragged-over {
    .lazy-load-list {
      padding-bottom: 3rem;
    }

    &.is-collapsed {
      .lazy-load-list {
        padding-bottom: 0;
      }
    }
  }

  &.is-collapsed {
    .card-header .ant-divider {
      display: none;
    }
  }

  .card-header {
    position: sticky;
    top: 47px;
    background-color: white;
    z-index: 1;
    padding-top: 1rem;
    margin-top: -1rem;

    .expand-button,
    .collapse-button {
      padding-left: 0.5rem;
      padding-right: 0.5rem;
      margin-right: 1rem;
    }

    .ant-space-item:first-child {
      width: 100%;
    }

    .card-title {
      width: 100%;
      display: flex;
      align-items: center;
    }

    .card-actions {
      display: flex;
    }
  }

  .sprint-name {
    margin-left: -0.5rem;

    input {
      font-weight: 500;
    }
  }

  .task-count-in-sprint {
    font-size: 0.8rem;
    top: -1px;
    margin-right: 1rem;
    color: $neutralDark;
    width: 4rem;
    text-align: center;
    flex-shrink: 0;
  }

  .ant-card-body {
    transition: all 300ms;
  }
}

.move-to-sprint-select {
  width: 100%;
}
