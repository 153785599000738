@import "../Variables.scss";

.simple-mobile-home-page {
  .navigation {
    margin-top: 2rem;
    flex-wrap: wrap;
    justify-content: center;

    .nav-item {
      width: 40vw;
      height: 40vw;
      max-width: 200px;
      max-height: 200px;
      border: 1px solid desaturate(lighten($standardText, 10), 10);
      display: flex;
      justify-content: center;
      align-items: center;
      color: $white;
      border-radius: $borderRadius;
      background-color: desaturate(lighten($standardText, 30), 50);
      padding: 1rem;
      position: relative;

      &::before {
        content: "";
        position: absolute;
      }

      .navigation-item-label {
        font-weight: 500;
        font-size: $regularFontSize;
      }

      &.selected {
        &::before {
          display: none;
        }
      }

      &:hover {
        background-color: desaturate(lighten($standardText, 40), 50);
      }
    }
  }
}
