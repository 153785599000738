@import "../Variables";

.user-card-container {
  width: 500px;
  z-index: 105 !important;

  .ant-tooltip-inner {
    background-color: $white !important;
    text-align: center;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 60px;
      background-color: $standardText;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
    }
  }

  &.disabled {
    .ant-tooltip-inner {
      &::before {
        background-color: $neutralDark;
      }
    }
  }

  .ant-tooltip-arrow {
    display: none;
  }

  .user-card {
    text-align: center;
    padding: 1rem;
    position: relative;

    .avatar .symbol {
      box-shadow: 0px 0px 0px 5px white;
      border-radius: 50%;
    }

    .name {
      display: block;
      margin-top: 1rem;
      margin-bottom: 0.2rem;
      font-weight: 500;
      color: $standardText !important;
    }

    .email {
      display: block;
      margin-bottom: 0.2rem;
      color: darken($neutralDark, 15) !important;
    }

    .position {
      display: block;
      color: darken($neutralDark, 15) !important;
    }

    &.disabled {
      .name,
      .email,
      .position {
        color: darken($neutralDark, 20) !important;
      }
    }
  }
}
