@import "../../../Variables.scss";

.daily-log-item {
  margin-bottom: 0.1rem !important;
  cursor: grab;

  &:hover {
    border: 1px solid $accent !important;
  }

  @media (max-width: 764px) {
    .ant-card-body {
      flex-direction: column;
      gap: 0 !important;
      padding: 0.1rem !important;

      .time-container {
        margin-bottom: -0.4rem;
      }

      .info-container {
        width: 100%;
        align-items: center;

        .title,
        .client-name-container,
        .project-and-task {
          text-align: center;
        }
      }

      .badge-container {
        margin-top: 0.2rem;
      }
    }
  }

  .ant-card-body {
    display: flex;
    padding: 0.3rem 0.2rem;
    gap: 0.5rem;
    align-items: center;

    .time-container {
      .time {
        font-weight: 500;
        opacity: 0.6;
      }
    }

    .info-container {
      display: flex;
      flex-direction: column;
      flex-grow: 1;

      .title {
        font-weight: 500;
      }
    }

    .project-and-task,
    .title {
      line-height: 1.5;
    }

    .badge-container {
      display: flex;

      .ant-tag {
        margin: 0;
      }
    }
  }
}
