@import "../../Variables.scss";

.add-permissions-modal {
  .ant-space {
    width: 100%;
  }

  .submit-container {
    text-align: center;
  }
}

.already-added-permission-container {
  .ant-select-tree-node-content-wrapper-normal::after {
    content: "Already added";
    background-color: $neutralLight;
    border-radius: $borderRadius;
    margin-left: 1rem;
    padding: 0.3rem;
  }
}
