@import "../../Variables";

.with-sheets {
  .sheet-details {
    width: 1px;
    flex-grow: 10;
  }
}

.sheet-details {
  padding-left: 1rem;
  padding-top: 1rem;

  @media screen and (max-width: 768px) {
    padding-left: 0 !important;
  }

  &.no-sheets {
    padding: 0;

    .metadata-container {
      flex-direction: column;
    }
  }

  .metadata-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    & > div {
      width: 50%;
      margin-bottom: 0.5rem;

      @media screen and (max-width: 600px) {
        width: 100%;
      }
    }
  }

  .revisions-and-preview {
    display: flex;
    gap: 1rem;
    justify-content: space-between;
    border-top: 1px solid $neutral;
    padding-top: 1rem;
    margin-top: 1rem;

    @media screen and (max-width: 1000px) {
      justify-content: center;
    }

    .preview-container {
      width: 1px;
      flex-grow: 1;
      height: calc(100vh - 105px);
      display: flex;
      flex-direction: column;
      align-items: center;

      .pdf-preview-title {
        margin-top: 0.4rem;
        margin-bottom: 0rem;
        text-align: center;
      }

      .zoomable-container {
        height: calc(100vh - 150px);
        width: 100%;
        overflow: hidden;
        background-color: $background;
        border-radius: $borderRadius;
        border: 1px solid $neutral;
        opacity: 0;

        @keyframes fade-in {
          0% {
            opacity: 0;
          }
          100% {
            opacity: 1;
          }
        }

        animation-name: fade-in;
        animation-duration: 0.5s;
        animation-iteration-count: 1;
        animation-delay: 0.8s;
        animation-fill-mode: forwards;

        .no-preview-available-message {
          font-size: $largeFontSize;
          margin-left: calc(50% - 100px);
        }
      }
    }
  }

  .title-item {
    justify-content: space-between;
    .title-value {
      width: 14rem;
      input {
        width: 100%;
      }
    }
  }

  .sheet-revisions {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 400px;
    flex-shrink: 0;

    @media (max-width: 1000px) {
      width: 100%;
      max-width: 400px;
    }

    .title-and-actions {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .sheet-revision-item {
      display: flex;
      flex-direction: column;
      gap: 0.3rem;
      border: 1px solid $neutral;
      border-radius: $borderRadius;
      padding: 0.5rem 1rem;

      &.in-preview {
        border-color: $accent;
        border-width: 2px;
      }

      .revision-details {
        display: flex;
        flex-direction: column;
        gap: 0.2rem;

        .revision-detail {
          display: flex;
          justify-content: space-between;
          padding-top: 0.4rem;
          padding-bottom: 0.2rem;
          align-items: center;

          &:not(:first-child) {
            border-top: 1px solid $neutral;
          }

          & > *:first-child {
            font-weight: 500;
            width: 100px;
            flex-shrink: 0;
          }

          & > *:nth-child(2) {
            width: 100%;
          }

          .ant-select {
            width: 100%;
          }

          .buttons {
            display: flex;
            gap: 0.5rem;
            flex-wrap: wrap;

            & > * {
              width: 100%;
            }
          }
        }
      }

      h4.ant-typography {
        font-size: 1rem;
      }

      .ant-typography {
        display: block;
      }
    }
  }

  @media (max-width: 1400px) {
    .sheet-revisions {
      // width: 260px;

      .revision-detail {
        flex-direction: column;
        align-items: flex-start !important;
      }
    }
  }
}
