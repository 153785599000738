@import "../Variables";
// report/quote mixins
@mixin input-group-style {
  margin-bottom: 2rem;
  position: relative;

  &.inline-label {
    display: flex;
    gap: 0.5rem;
    align-items: center;

    .field-label {
      margin-bottom: 0 !important;
    }
  }

  .ant-divider {
    margin-bottom: -0.5rem;
  }

  .editable-option-item {
    .ant-btn {
      border: unset;
      padding: 0.1rem;
      height: unset;
      box-shadow: unset;

      .anticon {
        font-size: 0.8rem;
      }
    }
  }

  .attachments {
    .attachments-container.view-type-list {
      min-height: 10rem;
    }
  }

  &.attachment-picker-section-field {
    background-color: $background;
    padding: 1rem;
    border-radius: $borderRadius * 2;
    margin-bottom: 1rem;

    .attachment-section-title-and-description {
      .attachment-section-title-container,
      .attachment-section-description-container {
        display: flex;
        margin-bottom: 0.5rem;

        .attachment-section-label {
          width: 7rem;
          flex-shrink: 0;
          text-align: right;
        }
      }
    }

    .input {
      position: relative;
      top: -0.2rem;
      margin-left: 1rem;
      width: 100%;

      input,
      textarea {
        background-color: $white;
      }
    }

    .button-without-outline {
      padding: 0;
      margin-right: 2rem;
      background-color: unset;
    }

    textarea.input {
      min-height: 3rem;
    }

    .attachment-list-title {
      font-weight: 500;
    }
  }

  .attachment-label-input {
    font-weight: 500;
    &::placeholder {
      font-weight: normal;
    }
  }

  .attachment-items {
    margin-top: -0.5rem;
    padding-left: 0rem;
    list-style-type: none;

    li {
      padding: 0.1rem 0;
      display: flex;
      align-items: center;

      .remove-attachment-button {
        margin-left: -0.5rem;
      }

      .attachment-name {
        padding: 0.2rem 0.5rem;
        margin-left: -0.2rem;
      }

      .clickable-attachment-name {
        cursor: pointer;
        color: $accent;
        border-radius: $borderRadius;

        &:hover {
          background-color: $hover;
        }
      }

      button {
        border: unset;
        box-shadow: unset;
      }

      &:not(:last-child) {
        border-bottom: 1px solid $neutral;
      }
    }
  }

  .attachment-extra-field-input {
    font-weight: 500;
    margin-right: 5px;

    &::placeholder {
      font-weight: normal;
    }
  }

  .nested-field-item {
    border: 1px solid $neutral;
    border-radius: $borderRadius;
    padding: 0.5rem;
    background-color: $background;

    .input-group {
      margin-bottom: 0.5rem;
    }

    .report-field-container {
      .ant-divider {
        display: none;
      }
    }

    &:not(:last-child) {
      margin-bottom: 1rem;
    }
  }

  .report-field-container:last-child {
    .ant-divider {
      display: none;
    }
  }

  .text-section-item {
    margin-bottom: 1rem;
    border-radius: $borderRadius;
    margin-left: -0.5rem;
    margin-right: -0.5rem;
    padding: 0.5rem 0.5rem;
    position: relative;

    .delete-section {
      position: absolute;
      right: 4.5rem;
      top: 3rem;
      background-color: transparent;
      border: unset;
      box-shadow: unset;

      .anticon {
        font-size: 1.2rem;
      }
    }

    .field-name {
      font-weight: 500;
      width: calc(100% - 2.7rem);
    }

    .section-textarea-container {
      position: relative;

      .editor-toolbox {
        top: -1.8rem;
      }
    }
  }

  .editor-toolbox {
    position: absolute;
    right: -0.3rem;
    top: 0rem;
    display: flex;
    justify-content: flex-end;

    &.disabled {
      opacity: 0.3;
      pointer-events: none;
    }

    button {
      border: unset;
      box-shadow: unset;
      background-color: transparent;

      .icon {
        width: 22px;
        color: $lightText;
        fill: $lightText;
      }
    }
  }

  textarea {
    padding-right: 2rem;
    line-height: 1.2;
  }

  .field-label {
    margin-bottom: 0.5rem;
    display: flex;
    justify-content: center;
    flex-direction: column;

    .field-name {
      display: inline-block;
      font-weight: 500;
    }
  }

  .mandatory-marker {
    color: $accent;
    margin-right: 0.2rem;
    position: relative;
  }
}

.report-user-fields-container,
.user-input-container {
  margin-top: 0rem;
  width: 100%;
  max-width: 1000px;
  flex-shrink: 2;
  display: flex;
  flex-direction: column;
  height: calc(100vh - $navigationBarHeight - 10px);
  overflow-y: auto;
  padding-right: 0.5rem;
  transition: opacity 300ms;
  opacity: 1;

  &.invisible {
    opacity: 0;
  }
}

.no-fields {
  height: unset;

  @media (max-width: 600px) {
    .report-container {
      display: none;
    }
  }

  .report-user-fields-container {
    width: $reportWidth;
    max-width: 100%;
    height: unset;
    flex-shrink: unset !important;
  }
}

.report-user-fields-container,
.user-input-container,
.report-modal-with-fields {
  @media (max-width: 600px) {
    & > .card > .ant-card-body {
      padding-bottom: 4rem;
    }
  }
  .card {
    .card-header {
      background-color: white;
      padding-top: 1rem;
      padding-left: 0.5rem;
      padding-right: 0.5rem;
      margin-top: -1rem;
      margin-left: -0.5rem;
      margin-right: -0.5rem;
      z-index: 2;
    }

    .card-actions {
      display: flex;
      align-items: center;

      .preview-switch-container {
        margin-left: 0;
      }
    }

    .form {
      margin-right: -1rem;
      padding-right: 1rem;
    }
  }

  .field-inline-description {
    .textarea {
      background-color: transparent !important;
      opacity: 0.7;

      .textarea-content {
        padding: 0;
        min-height: unset;
      }
    }
  }

  .field-label.only-display-explanation {
    .textarea {
      opacity: 1;
    }
  }

  .save-message {
    font-size: 0.7rem;
    display: block;

    &.saved-at {
      color: $good;

      .anticon {
        color: $good;
        position: relative;
        top: 1px;
        margin-left: 0.3rem;
      }
    }

    &.is-saving {
      color: $neutralDark;
    }

    &.is-save-error {
      color: $bad;
    }
  }

  .field-attachments-list {
    .field-attachment-item {
      display: block;
      margin-bottom: 0.5rem;

      .anticon {
        margin-right: 0.5rem;
        color: $good;
      }
    }
  }

  .ant-checkbox-group {
    overflow-y: auto;
    display: flex;
    flex-direction: column;
  }

  .button-without-outline {
    border: unset;
    box-shadow: unset;
    position: relative;
    top: 1px;
    &:focus {
      color: $accent;
    }
  }

  .ant-checkbox-group {
    .ant-checkbox-group-item {
      display: flex;
    }
  }

  .ant-form-item {
    flex-direction: column;

    .ant-col.ant-form-item-label {
      text-align: center;
    }
  }

  .user-list-options {
    padding-left: 0;

    .user-list-option-item {
      border: 1px solid $neutral;
      border-radius: $borderRadius;
      padding: 0.7rem;
      margin-bottom: 0.5rem;
      display: flex;
      align-items: flex-start;
      width: 100%;

      .item-content {
        width: 100%;
      }

      .item-action-buttons {
        display: flex;
        gap: 0.5rem;
      }

      .delete-item,
      .edit-item,
      .add-item-to-template {
        color: $accent;
      }
    }
  }
}

.report-modal-with-fields {
  width: 800px !important;
  max-width: 95vw !important;

  .input-group {
    @include input-group-style;
  }
}

.field-type-section-heading {
  position: sticky !important;
  background-color: $white;
  z-index: 1;
  top: 0;
}

.compact-fields {
  // .form {
  //   display: flex;
  //   flex-wrap: wrap;
  //   gap: 0.5rem;
  // }

  // .field-type-section-heading,
  // .field-type-checkbox-list,
  // .field-type-textarea,
  // .field-type-separator {
  //   width: 100%;
  // }

  // .field-type-dropdown,
  // .field-type-textfield,
  // .field-type-number {
  //   width: calc(50% - 0.5rem);

  //   @media (max-width: 500px) {
  //     width: 100%;
  //   }
  // }

  .input-group {
    margin-bottom: 0.5rem !important;

    .field-label {
      margin-bottom: 0 !important;
    }
  }

  .info-item {
    margin-bottom: 0.5rem !important;
  }

  .field-type-section-heading {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
}

.report-field-container {
  position: relative;

  .input-group,
  .info-item {
    @include input-group-style;
  }
}

.spreadsheet-container {
  .after-spreadsheet {
    width: 100%;
    margin-bottom: 5px;
  }

  .Spreadsheet {
    --border-color: #c1d8e3;
    --outline-color: #4ba5e1;
    --text-color: #113144;
    --outline-background-color: rgba(red, 0.2);

    &__header {
      background-color: $hover;
      color: $lightText;
      font-weight: bold;
    }

    &__cell {
      &.with-formula {
        background-color: rgba($background, 0.7);
        font-weight: 700;
        opacity: 1;
      }
    }
  }
}

.form-separator {
  width: 100%;
  height: 2px;
  background-color: $neutral;
  margin-bottom: 1.5rem;
}

.form-section-heading {
  padding-bottom: 0.5rem;
  margin-bottom: 1rem;
  padding-top: 0.5rem;
  margin-top: 0.5rem;
  border-top: 1px solid $neutral;
  border-bottom: 1px solid $neutral;
  display: flex;
  gap: 0.5rem;
  align-items: center;

  &.clickable {
    cursor: pointer;

    &:hover {
      background-color: $hover;
    }
  }

  .open-close-section-icon {
    display: block;
    padding: 0.5rem 0;
    color: $accent;
    transition: all 300ms;
    transform: rotate(0deg);
    transform-origin: 50% 50%;
    margin-left: 0.5rem;

    &.rotated {
      transform: rotate(90deg);
    }
  }

  .ant-typography {
    font-size: $regularFontSize;
  }
}

.form-section {
  border: 1px solid $neutral;
  border-radius: $borderRadius;
  margin-bottom: 1rem;
  position: relative;

  @media (max-width: 400px) {
    margin-left: -0.5rem;
    margin-right: -0.5rem;
  }

  &.placeholder-section {
    margin-top: -2rem;
  }

  .form-section > .form-section-heading {
    border: unset !important;
  }

  .form-section-content {
    padding: 0 0.5rem;
    opacity: 0;

    .input-group {
      &:last-child {
        margin-bottom: 0.5rem;
      }
    }

    .form-section-heading {
      border-top: 1px solid $neutral;
      border-bottom: 1px solid $neutral;
      border-radius: 0;
    }
  }

  .form-section-heading {
    margin-top: 0;
    margin-bottom: 0;
    border-bottom: unset;
    border-top: unset;
    border-radius: $borderRadius;
  }

  .section-item-buttons {
    position: absolute;
    right: 0.5rem;
    top: 0.5rem;
    z-index: 1;
    display: flex;
    gap: 0.5rem;
  }

  &.has-invalid-fields {
    .form-section-heading {
      pointer-events: none;
    }
  }

  &.always-expanded {
    padding-top: 0.5rem;

    & > .section-item-buttons {
      right: 9px;
      top: 0.2rem;
    }

    & > .form-section-heading {
      display: none !important;
    }
  }

  &.open {
    & > .form-section-heading {
      margin-bottom: 0.5rem;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }

    & > .form-section-content {
      opacity: 1;
    }
  }
}

.validation-errors-for-field {
  padding: 0;
  margin: 0;
  margin-bottom: 0 !important;

  li {
    list-style-type: none;
    padding: 0;
    margin: 0;
    margin-inline: 0 !important;
  }
}

.form {
  .attachments {
    border: 1px solid $neutral;
    padding: 0.5rem;
    border-radius: $borderRadius;
    padding-top: 1.5rem;

    &.presentation-only {
      padding-top: 0.75rem;
    }

    .card-header {
      padding-top: 0;
    }
  }

  &.read-only {
    .input-group {
      margin-bottom: 1rem;
    }

    .attachments {
      .attachments-container.view-type-list {
        min-height: unset;
        margin-top: -0.4rem;
      }
    }

    .attachment-items {
      li {
        padding-top: 0.2rem;
        padding-bottom: 0.2rem;
      }
    }
  }
}

.field-tooltip-description-overlay {
  .textarea {
    background-color: transparent !important;

    .textarea-content {
      padding: 0;
      min-height: unset;
    }
  }
}
