@import "../../Variables.scss";

.user-menu {
  cursor: pointer;
  justify-content: flex-end;
  display: flex;
  align-items: center;
  flex-shrink: 0;

  .user-name {
    color: $white;
    margin-right: 0.3rem;
    position: relative;
    top: 1px;
    font-weight: 500;
    font-size: 0.75rem;

    @media (max-width: 1150px) {
      display: none;
    }
  }

  &:hover {
    .user-name {
      color: $accent;
    }
  }

  .avatar {
    .symbol {
      border: 2px solid $white;
      height: 1.9rem;
      width: 1.9rem;
    }
  }

  .email {
    color: $standardText;
  }
}

.account-dropdown-overlay {
  .user-name {
    font-weight: 500;
  }

  .ant-dropdown-menu {
    padding: 0;
  }

  .ant-dropdown-menu-item {
    cursor: unset !important;
    min-width: 350px;

    &:hover {
      background: unset !important;
    }
  }

  .teams {
    background-color: $white;
    border-radius: $borderRadius;
    margin-top: 1rem;
    padding: 0.5rem 1rem;

    .teams-label {
      font-weight: bold;
      margin-bottom: 0.5rem;
      display: block;
    }
  }

  .current-account-info-container {
    display: flex;
    padding: 0.5rem;
    align-items: center;
    text-align: center;
    gap: 0.5rem;

    .avatar {
      margin-left: 0.8rem;
      position: relative;
      top: 2px;
    }

    .account-info-section {
      display: flex;
      flex-direction: column;
      margin: 0 auto;

      .account-name {
        font-size: 0.85rem;
        font-weight: 500;
      }

      .account-email {
        font-size: 0.85rem;
        color: darken($neutralDark, 15);
        display: block;
        margin-top: -0.3rem;
      }

      .my-account-button {
        margin-top: 0.5rem;
        width: 170px;
        margin-left: auto;
        margin-right: auto;
      }
    }

    .external-user-provider-name {
      * {
        color: $neutralDark;
      }
    }
  }

  .quick-account-actions {
    display: flex;
    padding: 0;
    border-top: 1px solid $neutral;
    flex-direction: column;
    margin-top: -0.7rem;

    &:hover {
      background-color: unset !important;
    }

    .action-item {
      display: block !important;
      text-align: center;
      width: 100%;
      padding-left: 1.3rem;
      padding-right: 1.3rem;
      padding-top: 0.6rem;
      padding-bottom: 0.6rem;
      color: darken($neutralDark, 20);
      transition: all 250ms;
      cursor: pointer;

      &:hover {
        background-color: $hover !important;
      }

      .anticon {
        margin-right: 0.5rem;
      }

      &:not(:first-child) {
        border-top: 1px solid $neutral;
      }
    }
  }
}
