@import "../../../../Variables";

.report-AEC {
  .first-page {
    padding-left: 0;
    padding-bottom: 0;
    display: flex;
    height: 297mm;

    .first-page-side-image {
      height: 100%;
      position: relative;
    }

    .info-section {
      padding-top: 10rem;
      padding-left: 5rem;

      p {
        margin-bottom: 2rem;
      }

      b {
        margin-right: 0.5rem;
      }
    }
  }
}
