.place-request-modal {
  width: 700px !important;

  .option-chooser {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    max-width: 15rem;

    button {
      width: 100%;
    }
  }
}
