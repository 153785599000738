@import "../../Variables";

.templates-page {
  .ant-tabs-nav-wrap {
    justify-content: center;
  }

  .ant-tabs-content-holder {
    width: 1100px;
    max-width: 100%;
    margin: 0 auto;
  }

  .actions {
    display: flex;
    gap: 0.5rem;
    align-items: center;
  }

  .actions-container {
    display: flex;
    gap: 0.5rem;
    justify-content: center;
  }

  .starting-file-actions {
    .open-icon {
      fill: $white;
    }
  }

  .section-title {
    font-size: $regularFontSize;
    font-weight: bold;
    padding-bottom: 0.5rem;
    margin-bottom: 0.5rem !important;
    display: block;
    margin-top: 1rem !important;
  }

  .subsection-title {
    font-size: $smallFontSize;
    font-weight: bold;
    padding-bottom: 0.5rem;
    margin-bottom: 0rem !important;
    margin-top: -0.5rem !important;
    margin-left: 1rem;
    display: block;
  }

  .subsection-table {
    margin-left: 1rem;
  }
}
