@import "../Variables.scss";
@import "../Mixins.scss";
@import "../ReportPage/reportHelpers.scss";

.quote-details-page {
  display: flex;
  gap: 0.5rem;
  padding: 1rem;
  padding-right: 0rem;
  width: 100%;
  height: 100%;

  .corrupted-message-container {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    margin: 3rem auto;

    .corrupted-title {
      font-weight: 500;
    }
  }

  .quote-metadata-wrapper {
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
    margin-bottom: 1rem;

    .metadata-card {
      width: calc(70% - 0.65rem);
      flex-grow: 1;
      min-width: 20rem;
    }

    .rejection-details {
      width: calc(30% - 0.65rem);
      flex-grow: 1;
      min-width: 20rem;
    }
  }

  &.is-archived {
    opacity: 0.7;
    filter: grayscale(50%);
  }

  .user-input-container {
    width: 100%;
    height: 100%;
    max-width: unset;

    .user-input-scroll-container {
      border-radius: $borderRadius * 1.3;
      height: 100%;
      position: relative;
    }

    .card {
      max-height: unset;
    }
  }

  .quote-summary {
    .ant-space-item:first-child {
      width: 100%;
    }
  }

  .react-pdf__Document > div:first-child {
    background-color: unset !important;
  }

  .pdf-preview-container {
    display: none;
    height: 100%;
    overflow-y: auto;
    width: 610px;
  }

  .quote-fields-card {
    .input-group {
      @include input-group-style;
    }
  }

  .title-and-actions {
    width: 100%;
    align-items: flex-end;
    margin-top: -0.5rem;
    display: flex;
    justify-content: space-between;
    gap: 1rem;

    .title-container {
      width: 100%;
    }
  }

  .quote-title {
    input {
      font-size: 1.2rem;
      font-weight: 500;
    }

    .anticon {
      margin-right: 0.5rem;
    }
  }

  .quote-description-label {
    margin: 0;
    margin-top: 0.5rem;
    margin-bottom: 0.3rem;
    font-size: 0.8rem;
  }

  .quote-description {
    display: flex;
    flex-direction: column-reverse;
    font-weight: normal;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
      "Droid Sans", "Helvetica Neue", sans-serif;
    border-radius: $borderRadius;
    border: 1px solid $neutral !important;
    padding: 0rem;

    * {
      color: $standardText;
    }

    [class^="EditorToolbar"] {
      border-bottom: unset;
    }

    [class^="RichTextEditor__block"] {
      font-size: 0.8rem;
      margin: 0;
    }

    .public-DraftEditor-content {
      min-height: 5rem;
    }

    a {
      color: $accent;
      text-decoration: underline;

      * {
        color: $accent;
      }
    }

    button {
      border-color: $neutral !important;
      background: white !important;
    }

    select {
      border: 1px solid $neutral !important;
      padding-left: 0.5rem;
      padding-right: 2.5rem;
      outline: $accent;
    }
  }

  .line-items-card {
    .ant-table-wrapper {
      overflow-x: auto;
      overflow-y: visible !important;
    }

    .ant-table.ant-table-scroll-horizontal .ant-table-content {
      overflow-x: auto !important;
      overflow: unset !important;
    }

    th.ant-table-cell {
      text-align: center;

      &.amount-column {
        text-align: right;
      }
    }

    .ant-table-cell {
      text-align: right;

      & > [class^="ant-"],
      .review-target > [class^="ant-"] {
        width: 100% !important;
      }

      &.create-task-column {
        button {
          display: inline-block;
          width: 100%;
        }

        .buttons-container {
          display: flex;
          flex-direction: column;
          gap: 0.5rem;
        }
      }
    }

    .line-item-actions {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      gap: 0.5rem;

      button {
        width: 100%;
      }

      .icon {
        margin: 0;
        color: $lightText;
      }
    }

    @keyframes fade-in-out {
      0% {
        background-color: $white;
      }
      50% {
        background-color: $background;
      }
      100% {
        background-color: $white;
      }
    }

    .line-item-highlighted {
      animation-name: fade-in-out;
      animation-duration: 1s;
      animation-timing-function: ease-in-out;
      animation-iteration-count: 5;
      animation-delay: 1.5s;
    }

    .line-item-go-to-task-button {
      height: 4rem;
    }

    textarea {
      min-height: 2rem;
      top: 3px;
    }

    .ant-checkbox-wrapper {
      width: 100%;
      .ant-checkbox {
        display: block;
        margin: 0 auto;
      }
    }

    .create-line-item-buttons {
      display: flex;
      flex-wrap: wrap;
      gap: 0.5rem;
      margin-bottom: 1rem;
      align-items: center;
    }

    .ant-table-tbody > tr.ant-table-row {
      cursor: auto !important;
    }

    .line-item-tags {
      display: flex;
      flex-direction: column;
      gap: 0.3rem;
    }

    .total-container {
      display: flex;
      justify-content: flex-end;
      margin-top: 1rem;
      margin-right: 1rem;

      .total-inner-container {
        width: 19rem;
        display: flex;
        flex-direction: column;

        .total-item {
          display: flex;
          justify-content: space-between;
          margin-bottom: 0.5rem;
          font-size: 1rem;

          .label {
            width: 7rem;
            text-align: right;
          }

          .value {
            text-align: right;
          }

          &.grand-total-item {
            font-size: 1.4rem;
            font-weight: 500;
            border-top: 1px solid $neutral;
            border-bottom: 1px solid $neutral;
          }
        }
      }
    }
  }

  &.with-comments {
    .user-input-container .reviewable-content {
      width: calc(100% - 21rem);

      @media (max-width: 1200px) {
        width: calc(100% - 18rem);
      }

      @media (max-width: 1000px) {
        width: calc(100% - 16rem);
      }
    }
  }

  @media (min-width: 1001px) {
    &.with-preview {
      .pdf-preview-container {
        display: block;
      }
      .user-input-container {
        width: calc(100% - 38rem);
      }
    }
  }

  // &.are-comments-visible {
  //   .user-input-scroll-container {
  //     display: flex;
  //     width: 100%;
  //     gap: 1rem;

  //     .reviewable-content {
  //       width: calc(100vw - $navigationWidth - 2rem - 20rem);
  //     }
  //   }
  // }
}

.delete-line-item-modal {
  .ant-modal-content {
    width: 500px;
  }
}

.invoices-containing-quote-line-item {
  display: flex;
  flex-direction: column;
  gap: 0.2rem;

  .quote-line-item-invoice-item {
    padding: 0.3rem;
    cursor: pointer;
    border-radius: $borderRadius;

    &:hover {
      background-color: $hover;
    }
  }
}
