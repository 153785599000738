@import "../../../Variables.scss";

.request-issued-task-files-modal {
  width: 1500px !important;

  .files-included-title {
    margin-top: 1.5rem;
    display: block;
    font-weight: 500;
    margin-bottom: 1rem;
  }
}
