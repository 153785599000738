@import "../Variables";

.time-off-page {
  .time-off-actions {
    display: flex;
    gap: 1rem;
  }

  .select-interval-label {
    font-size: smallFontSize;
    font-weight: 500;
    margin-right: 0.5rem;
  }

  .select-year-dropdown {
    width: 5rem !important;
  }
}
