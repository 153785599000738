@import "../../../Variables.scss";

.request-sidebar {
  width: 300px !important;
  flex-grow: 0;
  flex-shrink: 0;
  max-height: calc(100vh - $navigationBarHeight - 1.5rem);
  overflow-y: auto;

  .ant-card-body > button,
  .ant-card-body .ant-select {
    width: 100%;
  }

  .delete-request-button {
    max-width: 300px;
    margin: 0 auto;
    display: block;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  @media (max-width: 900px) {
    width: 100% !important;
    order: -1;
    max-height: unset;
    overflow-y: unset;

    .ant-card-body > div {
      display: flex;
      flex-wrap: wrap;

      .info-item {
        width: 50%;
      }
    }
  }

  @media (max-width: 580px) {
    .ant-card-body {
      flex-direction: column;

      .info-item {
        width: 100% !important;
      }
    }
  }
}

.delete-request-modal {
  width: 500px !important;
}
