@import "../../Variables";

$columnDropdownButtonTop: 6rem;

.table-view {
  position: relative;

  .budget-bar {
    width: 100%;
  }

  .ant-table-thead {
    position: sticky;
    top: 0;
    z-index: 1;
    background-color: $white;

    th {
      border-top: 1px solid $border !important;
    }
  }

  .ant-table-cell {
    padding: 0rem !important;
    padding-left: 0.2rem !important;
    padding-right: 0.2rem !important;
    height: 2rem;
    min-height: unset;
  }

  .ant-table-thead > tr > th.ant-table-cell {
    text-align: center;
    font-weight: 500;

    &:first-child {
      border-top-left-radius: $borderRadius !important;
    }
    &:last-child {
      border-top-right-radius: $borderRadius !important;
    }
  }

  .ant-table-tbody > tr > td {
    text-align: center;
    padding: 0.15rem 0;
  }

  tr {
    cursor: pointer;
  }
}

.table-column-dropdown-overlay {
  .ant-dropdown-menu {
    overflow-y: auto;
    max-height: calc(100vh - #{$columnDropdownButtonTop} - 4rem);
  }
}

.columns-dropdown-button {
  position: fixed;
  top: 6.2rem;
  left: 11rem;
  z-index: 1;

  @media (max-width: 800px) {
    display: none !important;
  }
}
