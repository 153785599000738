@import "../../Variables";

.address-modal {
  width: 800px !important;

  .submit-container {
    display: flex;
    justify-content: center;
    margin-top: 1rem;
  }
}
