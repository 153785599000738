@import "../../Variables";

.task-list {
  width: 100%;

  & > div {
    &:focus {
      outline: unset;
      background-color: $neutralDark;
      border-radius: $borderRadius;
      // border: 1px solid $neutral;
      padding: 0 0.25rem;
      .ant-card {
        box-shadow: unset;
      }
    }
  }
}
