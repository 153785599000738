@import "../Variables";

.card {
  // @include with-shadow;
  border: 1px solid $neutral !important;
  box-shadow: 0px 1px 3px 0px $neutral !important;
  width: calc(100% - 2px) !important;
  margin-left: 1px !important;
  text-align: left;
  border: 1px solid transparent;
  border-color: transparent;
  transition: unset !important;
  position: relative;

  &.with-space {
    margin-bottom: 1rem;
  }

  &.ant-card {
    border-radius: $borderRadius;
  }

  &.interactive:not(.no-hover) {
    cursor: pointer;

    .ant-card-body {
      transition: unset !important;
    }

    &:hover,
    &:focus {
      &::after {
        content: "";
        position: absolute;
        top: -1px;
        left: -1px;
        width: calc(100% + 2px);
        height: calc(100% + 2px);
        background-color: $neutral;
        opacity: 0.2;
        pointer-events: none;
        border: unset;
        border-radius: $borderRadius;
      }
    }
  }

  &.flex-title {
    .card-title {
      display: flex;
      align-items: center;
      gap: 0.5rem;
    }
  }

  .card-header {
    .ant-divider {
      margin-top: 1rem;
    }

    &.sticky-card-header {
      position: sticky;
      top: -0.5rem;
      padding-top: 1rem;
      margin-top: -1rem;
      z-index: 3;
      background-color: $white;
    }
  }

  &.no-divider {
    .card-header {
      .ant-divider {
        display: none;
      }
    }
  }

  &.wrap-header {
    .card-header {
      .ant-space {
        flex-wrap: wrap;
      }
    }
  }

  .card-actions {
    button {
      margin-left: 0.5rem;
    }
  }

  .ant-card-body {
    padding: 1rem;
  }
}
