@import "../../Variables.scss";

.template-editor-canvas {
  background-color: $background;
  overflow: hidden;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  &.invisible {
    visibility: hidden;
  }

  &.chapters-exist {
    .page-row {
      background-color: $neutral;
    }
  }

  .pdf-renderer {
    position: absolute;
    top: 0;
    left: 0;
    transform: scale(calc(2 / 3));
    transform-origin: 0 0;
  }

  .zoomable-content {
    position: absolute;

    span,
    p,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      overflow: hidden !important;
    }

    display: flex;
    flex-direction: column;
    gap: 10rem;

    .page-row {
      position: relative;
      display: flex;
      padding: 3rem;
      gap: 3rem;

      .page-row-name {
        position: absolute;
        transform: translate(-50%, -50%);
        top: calc(-1rem - (0.5rem * 1 / var(--zoomable-scale)));
        left: 50%;
        font-size: $smallFontSize;
        transform: scale(calc(1 / var(--zoomable-scale)));
        font-weight: bold;
        opacity: 0.6;
        color: $standardText;
      }
    }
  }

  .highlight-overlay-for-object {
    position: absolute;
    pointer-events: none;
    opacity: 0.75;
    z-index: 2;

    * {
      position: absolute;
      background-color: darken($standardText, 8);
    }
  }

  * {
    box-sizing: border-box;
    position: relative;
    display: block;
    line-height: 1;
  }

  .template-object {
    &::after {
      content: "";
      position: absolute;
      top: -1px;
      left: -1px;
      width: calc(200% + 2px);
      height: calc(200% + 2px);
      transform: scale(0.5);
      transform-origin: top left;
      z-index: 2;
      display: none;
      pointer-events: none;
    }

    &.selectable {
      cursor: pointer;
      &:hover {
        &::after {
          display: block;
          border: 1px dashed black;
        }
      }
    }

    &.selected-for-editing-children {
      &::after {
        display: block;
        border: 1px solid $accent;
      }

      &.page-container {
        &::after {
          display: block;
          border: calc(3px / var(--zoomable-scale)) solid $accent;
        }
      }

      &.page-row {
        background-color: $accent;
      }

      .page-row-name {
        color: $accent;
      }
    }
  }

  &.scale-0 {
    .template-object {
      &::after {
        width: calc(100% + 1px);
        height: calc(100% + 1px);
        transform: scale(1);
      }
    }
  }

  &.scale-1 {
    .template-object {
      &::after {
        width: calc(100% + 1px);
        height: calc(100% + 1px);
        transform: scale(1);
      }
    }
  }

  &.scale-2 {
    .template-object {
      &::after {
        top: -1px;
        left: -1px;
        width: calc(100% * 2 + 1px * 2);
        height: calc(100% * 2 + 1px * 2);
        transform: scale(0.5);
      }
    }
  }

  &.scale-3 {
    .template-object {
      &::after {
        top: -1px;
        left: -1px;
        width: calc(100% * 2 + 1px * 2);
        height: calc(100% * 2 + 1px * 2);
        transform: scale(calc(1 / 2));
      }
    }
  }

  &.scale-6 {
    .template-object {
      &::after {
        top: -0.5px;
        left: -0.5px;
        width: calc(100% * 6 + 1px * 6);
        height: calc(100% * 6 + 1px * 6);
        transform: scale(calc(1 / 6));
      }
    }

    // .template-object-resize-handler {
    //   width: calc(100% * 6 + 2px * 6);
    //   height: calc(100% * 6 + 2px * 6);
    //   transform: scale(calc(1 / 6));
    // }
  }

  // @for $i from 2 through 20 {
  //   &.scale-#{$i} {
  //     .template-object {
  //       &::after {
  //         width: calc(100% * #{$i} + 1px * #{$i});
  //         height: calc(100% * #{$i} + 1px * #{$i});
  //         transform: scale(1 / #{$i});
  //       }
  //     }
  //   }
  // }

  .page-container {
    background-color: white;
    flex-shrink: 0;
    border: 1px solid $neutral;
    position: relative;

    .template-page-header {
      pointer-events: none;
      position: absolute;
    }
  }
}
