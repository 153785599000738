@import "../../Variables";

.global-search {
  margin: 0 1rem;
  width: 100%;
  z-index: 4;
  text-align: right;

  .ant-input-suffix {
    display: none;
  }

  .search-icon {
    color: white;
    font-size: 1.2rem;
    position: relative;
    top: 2px;
    outline: unset;

    &:hover {
      color: $accent;
    }
  }
}

.modal-global-search {
  width: 95vw !important;
  max-width: 900px !important;

  .ant-modal-body {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .ant-modal-content {
    padding: 0 !important;
    background-color: transparent;
    border: unset;
    box-shadow: unset !important;
  }

  .ant-checkbox-wrapper {
    font-weight: 500;
  }

  .filter-input {
    width: 100%;
    max-width: 500px;
    border-bottom-left-radius: calc(#{$borderRadius} - 1px);
    border-bottom-right-radius: calc(#{$borderRadius} - 1px);
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    padding-bottom: 10px;
    margin-bottom: 1rem;
    padding-top: 0.5rem;

    input {
      font-weight: 500 !important;
      font-size: 1rem;
    }

    .anticon-close-circle {
      font-size: 1rem;
      position: relative;
      top: 3px;
    }
  }

  @media (max-width: 600px) {
    .ant-checkbox-wrapper {
      font-size: 1rem;
    }

    * {
      font-size: 1rem !important;
    }

    .avatar-initials {
      font-size: 0.75rem !important;
    }

    .ant-tag {
      height: unset !important;
      font-size: 0.75rem !important;
      padding: 0.1rem 0.5rem !important;
    }

    .dark-tag {
      display: block;
      margin-bottom: 0.2rem;
      font-size: 1rem !important;
      padding: 0.3rem 0.5rem !important;
    }
  }

  .instant-search-results {
    width: 95vw !important;
    max-width: 900px !important;
    border-radius: 6px !important;
    padding-left: 5px;
    height: 60vh;
    overflow-y: auto;
  }

  .search-filters {
    width: 100%;
    max-width: 500px;
    display: flex;
    gap: 0.5rem;
    justify-content: center;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    background-color: $background;
    margin-top: -0.25rem;
    border-top-left-radius: calc(#{$borderRadius} - 1px);
    border-top-right-radius: calc(#{$borderRadius} - 1px);
    position: relative;
    margin-left: -4px;
    margin-right: -4px;
    border-bottom: 1px solid $neutral;
    flex-wrap: wrap;

    .ant-checkbox-inner {
      border: 1px solid $neutralDark;
    }
  }
}

.search-background {
  z-index: 10;
  position: fixed;
  top: calc($navigationBarHeight - 1px);
  left: 0;
  background-color: transparent;
  width: 100vw;
  height: 100vh;
  transition: all 300ms;
  pointer-events: none;

  &.visible {
    background-color: $mainBarBaseColor;
    opacity: 0.9;
    pointer-events: all;
  }
}

.instant-search-results {
  .inner-container {
    max-width: 100vw;
  }

  .project-badge {
    color: $white;
    border-radius: $borderRadius;
    padding: 0.2rem 0.5rem;
    font-size: 0.65rem;
    letter-spacing: 0.3px;
    min-width: 10rem;
    transition: unset;
    text-align: center;
    display: inline-block;
    margin-left: auto;
    font-weight: 500;
    flex-shrink: 0;
  }

  .spinner-container {
    display: flex;
    justify-content: center;
    padding-top: 0.8rem;
    padding-bottom: 0.5rem;
  }

  .no-results,
  .invalid-search-value-message {
    font-size: 0.8rem;
    color: darken($neutralDark, 10);
    font-weight: 500;
    margin-top: 0.5rem;
    display: block;
    text-align: center;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .category {
    margin-top: 0.5rem;
    margin-bottom: 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    &:last-child {
      margin-bottom: 0.5rem;
    }

    .category-label,
    .attachments-category-title {
      text-transform: uppercase;
      font-size: $smallFontSize;
      color: $lightText;
      font-weight: 500;
      text-align: center;
      display: block;
      position: sticky;
      top: 0;
      background-color: $white;
      width: 100%;
      z-index: 1;
      padding-top: 0.3rem;
      margin-top: -0.3rem;
    }

    .attachments-category-subtitle {
      text-transform: none;
      display: block;
    }

    &.full-width {
      .category-label {
        width: 100%;
      }
    }

    .results-in-category {
      padding-left: 0;
      width: 100%;

      .result {
        list-style-type: none;
        font-size: 0.8rem;
        color: $mainBarBaseColor;
        padding: 0.4rem 0.5rem;
        display: flex;
        align-items: center;
        border: 1px solid transparent;

        &:not(:last-child) {
          border-bottom: 1px solid $neutral;
        }

        @media (max-width: 600px) {
          flex-direction: column;
          align-items: flex-start;

          .task-status {
            display: none;
          }

          .task-title {
            line-height: 1;
          }
        }

        * {
          user-select: none;
        }

        &.clickable {
          cursor: pointer;

          &.selected {
            border-color: $accent;
            background-color: $hover;
          }
        }

        .task-status {
          margin-left: 1rem;
          text-transform: uppercase;
          font-size: 0.65rem;
          color: darken($neutralDark, 10);
          font-weight: 500;
          flex-shrink: 0;
          width: 5rem;
        }

        .task-title-and-tags,
        .project-title {
          width: 100%;
        }

        .client-logo {
          margin-left: 1rem;
          flex-shrink: 0;
        }

        .task-id-tag,
        .project-id-tag,
        .quote-id-tag,
        .invoice-id-tag,
        .purchase-order-id-tag {
          margin-right: 1rem;
          min-width: 9rem !important;
        }

        .task-title,
        .project-title,
        .client-name,
        .quote-title,
        .purchase-order-title {
          font-weight: 500;
          margin-right: 1rem;
          display: block;
          width: 100%;
        }

        .task-project-title,
        .task-client-name,
        .project-client-name {
          color: $lightText;
          font-weight: 500;
          display: block;
        }

        .task-project-title,
        .task-client-name,
        .project-client-name,
        .task-title {
          line-height: 1.2;
        }

        .invoice-tags {
          width: 100%;
        }

        .dashboard-item-tags {
          margin: 0;
          margin-top: 0.2rem;
          transform-origin: 0 0;

          .ant-tag {
            margin-top: 0;
            font-size: 0.55rem;
            padding: 0rem 0.5rem;
          }
        }

        .avatar {
          margin-right: 0.5rem;
        }

        .client-logo {
          max-height: 2.5rem;
          text-align: center;
          margin-right: 1rem;
        }
      }
    }
  }

  .attachment-list-header {
    .attachment-content {
      padding-left: 0.5rem;
      padding-right: 0.5rem;

      .ant-typography {
        text-transform: uppercase;
        font-size: 0.65rem !important;
        color: $lightText !important;
        font-weight: 500;
        display: block;

        &.select {
          width: 2rem;
          flex-shrink: 0;
        }
      }
    }
  }

  .attachment-item,
  .attachment-list-header {
    margin-left: -0.5rem;
    margin-right: -0.5rem;
    padding: 0.3rem 0.5rem;
    position: relative;
    color: $standardText;
    background-color: transparent;
    cursor: pointer;
    transition: all 300ms;
    font-size: 0.8rem;
    width: 100%;

    .file-type-icon {
      margin-right: 0.5rem;
      font-size: 1rem;
      color: $accent;
    }

    .attachment-content {
      display: flex;
      align-items: center;
      transition: all 300ms;
      gap: 0.5rem;

      .ant-checkbox-wrapper {
        margin-right: 1rem;

        @media (max-width: 600px) {
          margin-right: 0.5rem;
        }
      }

      .name {
        width: 100%;
        transition: all 300ms;
        color: $standardText;
        display: flex;
        align-items: center;
        word-break: break-all;
        line-height: 1.2;

        &.type-folder {
          font-weight: 500;
        }
      }

      .updated-at,
      .created-at {
        color: $standardText;
        width: 7.5rem;
        text-align: right;
        flex-shrink: 0;
      }

      .file-size {
        width: 4rem;
        text-align: right;

        color: $standardText;
      }
    }

    &:hover {
      background-color: $hover;

      .attachment-content {
        color: $accent;
      }

      .name {
        color: $accent;
      }
    }

    .delete-attachment {
      position: static;
      left: unset;
      top: unset;
      border: 0;
      transition: all 300ms;

      .anticon {
        color: $lightText !important;
      }

      &:hover {
        background-color: $hover;

        .anticon {
          color: $accent !important;
        }
      }
    }
  }

  .document-item {
    display: flex;
    flex-direction: column;

    .document-name {
      font-weight: bold;
      margin-bottom: 0.2rem;
    }
  }

  .spinner-container {
    height: 100%;
    align-items: center;
  }
}
