@import "../Variables.scss";

.time-off-for-all-users {
  .time-off-table-header {
    font-size: $regularFontSize;
    font-weight: 500;
    margin-bottom: 1rem;
    display: block;
    text-align: left;
    width: 100%;
  }

  .user-allowances {
    margin-top: 1rem;
  }
}

.time-off-actions-menu {
  button {
    width: 100%;
  }
}
