@import "../Variables";

.overall-spinner {
  position: relative;
  z-index: 10;
  display: none;

  .overall-spinner-inner-container {
    position: fixed;
    top: $navigationBarHeight;
    left: 0rem;
    width: 100%;
    height: calc(100vh - #{$navigationBarHeight});
    border-radius: $borderRadius;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba($white, 0.8);
    z-index: 10;
  }

  &.visible {
    display: block;
  }

  &.contained {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;

    .overall-spinner-inner-container {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
    }
  }
}
