@import "../Variables";

.public-upload-page {
  max-width: 1000px !important;
  padding-top: 2rem;

  .upload-container {
    display: block;
    width: 100%;
    cursor: pointer;
  }

  .host-organisation-logo {
    margin: 0rem auto 1rem;
    text-align: center;

    img {
      max-width: 200px;
      border-radius: $borderRadius * 2;
    }
  }

  .upload-title-line {
    display: block;
  }

  .card-header {
    .ant-space-item:first-child {
      width: 100%;
      margin-right: 0 !important;
      text-align: center;
    }
    .ant-space-item:last-child {
      display: none;
    }
  }

  .upload-details-container {
    width: 350px;
    flex-shrink: 0;

    .organisation-logo-container {
      width: 200px;
      margin: 0 auto 1rem;
    }

    .upload-details-line {
      display: block;
      font-weight: 500;

      font-size: 0.85rem;
      display: flex;
      justify-content: space-between;

      &:not(:last-child) {
        border-bottom: 1px dashed $neutral;
      }

      .item-value {
        color: darken($neutralDark, 25);
        text-align: right;
      }
      .item-label {
        color: darken($neutralDark, 5);
        margin-right: 1rem;
        width: 100px;
        flex-shrink: 0;
      }
    }
  }

  .card-content {
    display: flex;
    gap: 20px;
    align-items: center;
  }

  .drag-and-drop {
    height: 13rem;
    width: 100%;
    margin: 0 auto;
    border: 1px dashed $neutralDark;
    border-radius: 10px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .upload-icon {
      color: $neutralDark;
      font-size: 2.5rem;
      display: block;
      margin-bottom: 0.2rem;
    }

    .upload-instructions {
      color: $neutralDark;
      font-size: 0.8rem;
      font-weight: 500;
    }
  }
}
