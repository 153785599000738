@import "../Variables";

.timesheets-page {
  max-width: 100% !important;

  .ant-table {
    .anticon {
      font-size: $smallFontSize;
    }
  }

  .card-header > .ant-space > .ant-space-item:first-child {
    display: none;
  }
  .card-header > .ant-space > .ant-space-item:nth-child(2) {
    width: 100%;
  }

  .timesheets-filters {
    display: flex;
    gap: 0.5rem;
    width: 100%;
    flex-wrap: wrap;
    justify-content: center;

    .task-picker-container {
      width: 100%;
      max-width: 400px !important;

      .task-picker {
        width: 100%;
      }
    }

    .info-item {
      max-width: 100%;

      .ant-select {
        max-width: 100%;
      }

      @media (max-width: 500px) {
        width: 100%;

        .ant-select {
          width: 100% !important;
          max-width: 100% !important;
        }

        .ant-picker {
          width: 100% !important;
        }
      }
    }

    .info-item {
      margin-bottom: 0 !important;
    }
  }
}
