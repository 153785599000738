@import "../../Variables";

.task-item-container {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;

  &.unconfirmed {
    opacity: 0.5;
    filter: grayscale(50%) brightness(1.1) contrast(0.95) hue-rotate(0deg);
    position: relative;

    .card {
      box-shadow: unset !important;
    }
  }

  &.is-draggable {
    &:hover {
      .task-item {
        border: 1px solid $accent !important;
        cursor: pointer;
      }
    }
  }

  .task-item {
    display: block;
    position: relative;
    transition: all 800ms;
    max-height: 500px;
    opacity: 1;

    .info-item {
      margin-bottom: 0;
      border-bottom: 1px solid $border;
      border-radius: 0;
      margin-left: -0.7rem;
      margin-right: -0.4rem;
      padding-left: 0.65rem;
      padding-right: 0.65rem;
      padding-bottom: 0.2rem;
      margin-bottom: 0.5rem;
    }

    .ant-card-body {
      padding-right: 0.4rem;
      padding-top: 0.3rem;
      padding-bottom: 0.3rem;
      padding-left: 0.7rem;
    }

    &.drag-hidden {
      max-height: 0;
      opacity: 0;
    }

    .child-elements {
      display: flex;
      flex-wrap: wrap;
      gap: 0.5rem;

      .child-item {
        padding: 0.5rem;
        background-color: $neutral;
        border-radius: $borderRadius;
        cursor: grab;
        border: 1px solid transparent;

        &:hover {
          border-color: $accent;
        }
      }
    }

    .budget-bar,
    .task-progress-bar {
      height: 1rem;
      width: calc(100% + 1.08rem);
      margin-bottom: 0.5rem;
      border-bottom: 1px solid $border;
      padding-bottom: 0.8rem;
      margin-top: 0.6rem;
      margin-left: -0.7rem;
      padding-left: 0.65rem;
      padding-right: 0.65rem;
      display: flex;
      align-items: center;

      .progress-label {
        flex-shrink: 0;
        margin-top: 0;
        font-size: $extraSmallFontSize;
        font-weight: bold;
        width: 50px !important;
        text-align: right;
      }

      .static-label {
        flex-shrink: 0;
        margin-right: 0.5rem;
        font-size: $extraSmallFontSize;
        font-weight: bold;
        width: 100px !important;
      }

      .ant-progress {
        margin-bottom: 0;
        height: 1rem;
        width: 100% !important;

        .ant-progress-outer {
          margin: 0;
          padding: 0;
        }
      }
    }

    .project-name {
      font-size: 0.8rem;
      color: saturate(darken($neutralDark, 20), 20);
      line-height: 1rem;
      margin-bottom: 0.1rem;
      font-size: 0.8rem;
      font-weight: 500;
      margin-bottom: -1px;
    }

    .task-id {
      margin-left: auto;
      margin-right: 0.2rem;
      flex-shrink: 0;

      .task-id-tag {
        margin-right: 0;
        min-width: unset;
      }
    }

    .created {
      margin-bottom: 0;
    }

    .task-initials-and-name {
      font-weight: 500;
      margin: 0;
      line-height: 1rem;
      margin-top: 0;
    }

    .task-name,
    .task-initials {
      font-size: 0.8rem;
      color: darken($standardText, 3);
    }

    .task-subtitle {
      max-width: calc(100% - 35px);
      margin: 0;
      margin-bottom: -0.5rem;
      line-height: 1.1;
      font-size: 0.85rem;
      color: $neutralDark;
      display: block;
    }

    .client-logo {
      &.client-logo-no-image {
        font-size: 0.8rem;
        position: relative;
        top: 2px;
        color: darken($neutralDark, 10);
      }
    }

    .footer {
      margin-top: 0.5rem;
      align-items: center;
      display: flex;
      justify-content: space-between;
    }

    .actions {
      margin-top: 0.5rem;
    }

    .dashboard-item-tags {
      margin-top: 0.2rem;
    }

    .due-date {
      font-size: 0.65rem;
      font-weight: 500;
      text-transform: uppercase;
      color: darken($neutralDark, 10);
    }

    .avatar .symbol {
      width: 1.6rem;
      height: 1.6rem;
    }
  }
}
