@import "../Variables";

.sprints-page {
  .main-content {
    width: calc(100% + 1rem);
    margin-left: -0.5rem;
    margin-right: -0.5rem;
    padding-left: 1rem;
    padding-right: 1rem;

    .sprint-list {
      padding-top: 0.5rem;
    }
  }

  .filter-container {
    display: flex;
    align-items: center;
    margin-bottom: 0.3rem;
    position: sticky;
    top: 0;
    z-index: 2;
    margin: 0 -0.2rem;
    padding: 0 0.2rem;

    &::before {
      background-color: $background;
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    .task-filters {
      padding-top: 1.15rem;
      margin-right: 1rem;

      &::before {
        background-color: $background;
      }
    }
  }
}
