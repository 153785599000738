@import "../../Variables";

.upload-custom-file-modal {
  width: min-content;

  .info-item {
    justify-content: center;
    width: 100%;
  }

  .sheet-item-container {
    display: flex;
    justify-content: space-around;

    .ant-row {
      display: block;
    }

    .ant-form-item {
      flex-direction: column;
    }

    .ant-form-item-label {
      width: 200px;
      text-align: center;
    }

    .ant-select {
      width: 200px !important;
    }
  }

  .submit-container {
    text-align: center;
    border-top: 1px solid $neutral;
    padding-top: 1rem;
  }

  .add-remove-sheet-container {
    display: flex;
    justify-content: center;
    margin-bottom: 1rem;
    margin-top: 1rem;

    button {
      margin: 0 0.5rem;
    }
  }

  .sheet-form-items-container {
  }

  .upload-button-container {
    margin-bottom: 1rem;
    margin-top: 1rem;
    display: block;
    width: 100%;
    text-align: center;
  }

  .upload-custom-file {
    width: 100%;

    button {
      width: 100%;
    }
  }
}
