@import "../../Variables";

.subtasks {
  .subtasks-header {
    display: flex;
    padding: 0.5rem;
    margin-top: 1rem;
    font-weight: 500;
    text-align: left;
    align-items: center;
    color: #003a54;

    .subtasks-title {
      margin-left: 2rem;
      padding-left: 4.8px;
      width: 100%;
      border: 1px solid transparent;
    }

    .subtasks-due-date {
      margin-right: 44px;
      padding-right: 10px;
      width: 10rem;
      border: 1px solid transparent;
    }
  }
  .subtask-list {
    .subtask-item {
      display: flex;
      align-items: center;
      padding: 0.5rem;
      &:not(:last-child) {
        border-bottom: 1px solid $border;
      }

      .icon-drag-handle {
        margin-right: 1rem;
        cursor: grab;
        path {
          fill: $neutralDark;
        }
      }

      .item-title {
        display: flex;
        align-items: center;
        font-weight: 500;
        margin-left: 1rem;
        padding-right: 1rem;
        margin-right: 1rem;

        img {
          margin-right: 0.7rem;
        }
      }

      .item-due-date {
        width: 10rem;
        min-width: unset;
      }

      .delete-item-button {
        margin-left: auto;
      }

      .item-icon {
        img {
          width: 20px;
        }
      }

      &.is-finished {
        .item-title {
          text-decoration: line-through;
          color: darken($neutralDark, 0);
        }
      }
    }
  }
}
