@import "../../Variables";

.purchase-order-picker {
  width: 100%;

  .ant-select-selector {
    // height: 38px !important;
    height: 32px !important;

    // .ant-select-selection-placeholder,
    // input {
    //   position: relative;
    //   top: 3px;
    // }
  }

  .ant-select-selection-item {
    color: $accent;
    padding-bottom: 4px !important;

    .label {
      position: relative;
      top: 1px;
    }
  }

  .ant-select-arrow {
    color: $accent;
  }
}

.purchase-order-picker-option-inner {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: 0.3rem;
  margin-bottom: 0;
  border-bottom: 1px solid $neutral;

  .project-title {
    font-size: $extraSmallFontSize;
    color: $lightText;
    font-weight: 500;
    display: block;
    margin-bottom: -0.3rem !important;
    margin-top: -0.3rem;
  }

  .purchase-order-title {
    font-size: $extraSmallFontSize;
    color: $standardText;
    font-weight: 500;
  }

  .tags-container {
    display: flex;
  }
}

.purchase-order-picker-option-label {
  position: relative;
  top: 2px;

  .purchase-order-picker-option-label-purchase-order-title {
    font-size: $extraSmallFontSize;
    font-weight: 500;
  }
}
