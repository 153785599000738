@import "../../Variables";

.new-timeline-block-modal {
  width: 800px !important;
  max-width: 95vw;

  .ant-select {
    max-width: unset !important;
  }

  .task-picker-row {
    display: flex;
    gap: 0.5rem;

    .task-picker {
      max-width: 620px;
    }

    @media (max-width: 450px) {
      flex-direction: column;
    }
  }

  .submit-container {
    padding-left: 33%;
  }
}
