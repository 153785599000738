@import "../../Variables.scss";

.textarea {
  border-radius: $borderRadius;
  position: relative;
  transition: all 300ms;

  &.textarea-has-focus {
    border-radius: $borderRadius;
    background-color: rgba($hover, 0.5);
  }

  &.textarea-no-focus {
    border-radius: $borderRadius;
    border: 1px solid $border;

    .textarea-toolbar {
      opacity: 0;
      height: 0;
    }
  }

  &.no-toolbar,
  &.no-toolbar-on-blur.is-not-focused {
    background-color: unset;
    border: 1px solid $neutral;

    &:hover,
    &:focus {
      border-color: $accent;
    }

    .textarea-toolbar {
      display: none !important;
    }

    [data-slate-node="element"] {
      text-align: left;
      font-size: $smallFontSize;
    }
  }

  &.no-border {
    border: unset !important;

    &:not(.disabled) {
      &:hover,
      &:focus {
        background-color: lighten($background, 3);
      }
    }
  }

  &.no-padding {
    .textarea-content {
      padding: 0;
    }
  }

  .avatar .label {
    position: relative;
    top: -1px;
    font-weight: 500;
  }

  .autocomplete-overlay {
    border-radius: $borderRadius;
    border: 1px solid $neutral;
    background-color: $white;
    color: $standardText;
    position: fixed;
    z-index: 100;
    display: flex;
    flex-direction: column;
    max-height: var(--max-height);
    overflow-y: auto;

    .autocomplete-option {
      padding: 0.5rem 1rem;

      cursor: pointer;
      text-align: left;

      &:not(:last-child) {
        border-bottom: 1px solid $border;
      }

      &:hover {
        background-color: $hover;
      }
    }
  }

  .anticon.anticon-caret-down {
    font-size: 0.8rem !important;
  }

  .font-size-button {
    font-weight: bold;
    margin-right: 0.5rem;
  }

  .color-button {
    font-weight: bold;

    .color-sample {
      width: 1.3rem;
      height: 1.3rem;
      display: inline-block;
      border-radius: $borderRadius;
      margin-right: 0.5rem;
    }
  }

  &.basic-formatting-only {
    .textarea-toolbar-inner {
      padding: 0 !important;
    }

    .tool-row {
      padding-left: 0 !important;
    }

    .tool-group {
      button {
        padding: 0 !important;
      }
    }
  }

  .textarea-toolbar {
    position: sticky;
    top: -1px;
    z-index: 3;

    .textarea-toolbar-inner {
      display: flex;
      flex-wrap: wrap;
      transition: all 300ms;
      transition-timing-function: ease-in-out;
      overflow: hidden;
      flex-direction: row;
      background-color: $background;
      border-top-left-radius: $borderRadius;
      border-top-right-radius: $borderRadius;
      min-height: 2.4rem;
      align-items: center;
      padding: 0.3rem 0;

      .tool-row {
        display: flex;
        flex-wrap: wrap;
        padding-left: 0.5rem;
        align-items: center;
      }

      @media (max-width: 600px) {
        justify-content: center;
        flex-direction: column;
      }

      button {
        text-align: center;
        transition: unset;

        &.active {
          background-color: $lightText;
          color: $white !important;

          .icon {
            color: $white;
            fill: $white;
          }
          .anticon {
            color: $white;
          }

          &:hover {
            background-color: rgba($lightText, 0.5);
          }
        }
      }

      .icon {
        color: $lightText;
        fill: $lightText;
        max-width: 22px;
        margin: 0;
      }

      .anticon {
        color: $lightText;
        font-size: 1rem;
      }
    }
  }

  .font-size-picker {
    width: 200px;
  }

  .textarea-content {
    padding: 0.5rem;
    min-height: var(--min-height, 120px);
    transition: all $transition-duration $transition-timing-function;
    border-bottom-left-radius: $borderRadius;
    border-bottom-right-radius: $borderRadius;

    .node-mention {
      padding-left: 0.3rem;
      padding-top: 0.1rem;
      padding-bottom: 0.1rem;
      padding-right: 0.3rem;
      margin-right: 0.2rem;

      position: relative;
      background-color: $standardText;
      border-radius: 4px;

      * {
        color: $white !important;
      }
    }

    .node-page-break {
      width: 100%;
      text-align: center;
      position: relative;
      cursor: pointer;

      &::before {
        content: "";
        z-index: 0;
        position: absolute;
        width: 100%;
        height: 2px;
        left: 0;
        transform: translateY(-50%);
        top: 50%;
        background-color: $neutral;
      }

      .label {
        position: relative;
        z-index: 1;
        background-color: $neutral;
        border-radius: $borderRadius;
        display: inline-block;
        padding: 0 0.5rem;
        font-weight: 500;
        color: $lightText;
      }

      &:hover {
        .label {
          background-color: $accent !important;
          color: $white;
        }

        &::before {
          background-color: $accent;
        }
      }

      &.selected {
        border-color: $accent;

        &::before {
          background-color: $accent;
        }
        .label {
          background-color: $accent;
          color: $white;
        }
      }
    }

    .node-attachment {
      width: 100%;
      position: relative;
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;

      .label {
        position: relative;
        z-index: 1;
        background-color: $white;
        border-radius: $borderRadius;
        border: 1px solid $accent;
        display: block;
        padding: 0.3rem 0.5rem;
        cursor: pointer;
        color: $accent;

        .anticon {
          font-size: 1rem;
        }
      }

      &.selected {
        border-color: $accent;

        .label {
          background-color: $accent;
          color: $white;
        }
      }

      &:hover {
        .label {
          background-color: $accent;
          color: $white;
        }
      }
    }
  }
}

.textarea-attachment-popover {
  .thumbnail {
    width: 95vw;
    max-width: 700px;
    margin-bottom: 0.5rem;
  }

  .info-item {
    .value-container {
      width: 100%;
    }
  }

  .ant-select {
    width: 100%;
  }
}

.textarea-font-size-popover {
  display: flex;
  flex-direction: column;
}

.textarea-color-popover {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .color-sample {
    width: 1.3rem;
    height: 1.3rem;
    display: inline-block;
    border-radius: $borderRadius;
    margin-right: 1rem;
  }
}
