@import "../Variables";

.dashboard-page {
  width: 100%;
  padding-right: 0.5rem;
  display: flex;
  flex-direction: column;
  height: 100%;

  .task-filters {
    margin-bottom: 0.5rem;
    left: 0;

    &::before {
      background-color: $white;
    }
  }

  .no-task-statuses-message {
    display: flex;
    flex-direction: column;
    width: 13rem;
    gap: 0.5rem;
    margin: 0 auto;
  }

  .table-view {
    overflow-y: auto;

    .ant-table-body {
      overflow-y: visible !important;
    }

    .ant-table-header {
      overflow: unset !important;
      position: sticky;
      top: -1px;
      z-index: 1;
    }
  }
}
