@import "../Variables.scss";

.supplier-details-page {
  padding: 0.5rem 1rem 1rem 0;

  .page-content {
    display: flex;

    & > div:not(:last-child) {
      margin-right: 0.5rem;
    }
  }

  .client-id-tag {
    .anticon {
      font-size: 12px !important;
    }
  }

  .ant-card-body {
    padding-top: 0.5rem;
  }

  .supplier-initials-and-name {
    display: flex;

    .supplier-initials {
      align-self: center;
      font-size: 0.8rem;
      color: darken($neutralDark, 5);
      margin-right: 0.6rem;
    }

    .supplier-name > textarea {
      font-size: 1.2rem;
      font-weight: 500;
    }
  }

  .supplier-priority-checkbox {
    margin-left: 0.2rem;
  }

  .ant-tabs {
    margin-top: 0.5rem;
  }

  .address-list,
  .contact-list {
    &.with-border {
      border: 1px solid $neutral;
      margin-top: 0;
      box-shadow: unset;
    }
  }

  .project-item {
    margin-bottom: 0.5rem !important;
  }

  .supplier-details-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
