@import "../Variables.scss";

.public-navigation-bar {
  position: sticky;
  width: 100%;
  left: 0;
  z-index: 2;
  top: -1px;
  padding: 0rem;
  border-bottom: 1px solid $neutral;

  .inner-container {
    background-color: white;
    padding: 1rem 1rem;
    display: flex;
    justify-content: space-between;
  }

  .logo-container {
    width: 150px;

    img {
      max-width: 100%;
    }

    .mobile-logo {
      display: none;
    }
  }

  .buttons {
    .button-container {
      margin: 0;
      margin-left: 1rem;
      display: inline-block;

      button {
        font-weight: 500;
        padding: 0.2rem 2rem;
      }
    }
  }

  @media (max-width: 500px) {
    .logo-container {
      width: 30px;

      .mobile-logo {
        display: block;
        width: 25px;
      }
      .desktop-logo {
        display: none;
      }
    }
    .buttons .button-container {
      margin-left: 0.5rem;

      button {
        padding: 0.2rem 1rem;
      }
    }
  }
}
