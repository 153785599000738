@import "../Variables";

.create-task-modal {
  width: 1100px !important;
  max-width: 90vw;

  .ant-select {
    width: 100% !important;
  }

  .ant-radio-wrapper,
  .ant-checkbox-wrapper {
    display: flex;
    align-items: center;
  }

  .loading-button {
    width: 75px;
  }

  .task-estimated-hours-input {
    width: 150px;
  }

  .submit-button-container {
    display: flex;
    align-items: center;

    .create-another-label-and-checkbox {
      display: flex;
      margin-left: 2rem;
      cursor: pointer;

      span.label {
        margin-left: 0.5rem;
      }
    }
  }

  .multiple-assignee-user-item {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    flex-wrap: wrap;

    .ant-checkbox-disabled {
      opacity: 0.3;
    }

    .extra-messages {
      display: flex;
      gap: 0.5rem;
      align-items: center;
      flex-wrap: wrap;
      position: relative;
      top: 1px;
      color: $standardText;
      opacity: 0.75;
    }
  }
}
