@import "../../Variables";

.versions-modal {
  width: 22rem !important;
  max-width: 95vw;

  .ant-modal-body {
    padding: 0rem 0rem 0.2rem;
  }

  .version-item {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: darken($neutralDark, 20) !important;
    font-weight: 500;
    font-size: 0.75rem;
    padding: 0.3rem 1rem;
    cursor: pointer;

    &:not(:last-child) {
      border-bottom: 1px solid $neutral;
    }

    &:hover {
      background-color: $hover;
    }

    .size {
      margin-left: 2rem;
    }
  }
}
