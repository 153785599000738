@import "../Variables";

.purchase-orders-page {
  overflow-y: hidden;
  height: calc(100vh - #{$navigationBarHeight});
  display: flex;
  flex-direction: column;
  padding-right: 0.5rem;

  .purchase-orders-filters {
    margin-bottom: 0.5rem;
  }

  .lane .inner-list {
    height: 100%;
  }
}
