@import "../../Variables.scss";

.logged-in-accounts {
  display: flex;
  flex-direction: column;
  border-top: 1px solid $neutral;
  padding: 0;
  max-height: calc(100vh - 270px);
  overflow-y: auto;

  &:hover {
    background-color: unset !important;
  }

  .alternative-user-item {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    width: 100%;
    display: flex;
    align-items: center;
    gap: 0.7rem;
    padding-left: 0.3rem;
    padding-right: 0.3rem;
    cursor: pointer;

    &:hover {
      background-color: $hover !important;
    }

    .avatar {
      .symbol {
        width: 2rem;
        height: 2rem;
      }
    }

    .alternative-user-details {
      display: flex;
      flex-direction: column;
      font-size: 0.85rem;

      .user-email {
        font-size: 0.85rem;
        color: darken($neutralDark, 15);
        display: block;
        margin-top: -0.3rem;
      }
    }

    &:not(:last-child) {
      border-bottom: 1px solid $neutral;
    }

    .sign-out-of-alternate-account-button {
      margin-left: auto;

      .anticon,
      .icon {
        margin: 0 !important;
      }
    }
  }
}
