@import "../../Variables";

.upload-font-modal {
  .ant-space {
    width: 100%;
  }
  .submit-container {
    margin: 0 auto;
    margin-bottom: -2rem;

    button {
      margin-right: 1rem;
      display: block;
      margin: 0 auto;
      margin-bottom: 1rem;
      margin-left: 33%;
      width: 140px;
    }
  }
}
