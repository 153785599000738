@import "../../Variables.scss";

.edit-sheets-modal {
  // width: 900px !important;

  .inner-modal-content {
    display: flex;
    gap: 1rem;
    margin-top: -1rem;

    .sheet-list-header {
      padding: 0.5rem;
      width: 100%;
      display: block;
      border-bottom: 1px solid $border;
      display: flex;
      align-items: center;

      .section-list-title {
        font-weight: bold;
        text-align: left;
        width: 100%;
      }
    }

    .sheet-list {
      margin-top: 1rem;
      margin-bottom: 1rem;
      border: 1px solid $border;
      border-radius: $borderRadius;
      width: 100%;

      .sheet-item {
        display: flex;
        gap: 0.5rem;
        align-items: center;
        padding: 0.5rem;

        &:not(:last-child) {
          border-bottom: 1px solid $border;
        }

        .sheet-name {
          // font-weight: bold;
          display: block;
          width: 100%;
        }

        button {
          flex-shrink: 0;
        }
      }
    }
  }
}
