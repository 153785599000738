@import "../Variables";

.notification-menu {
  margin-left: auto;

  .notification-marker {
    margin-right: 1rem;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    display: flex;
    position: relative;
    cursor: pointer;

    @media (max-width: $mobileBreakpoint) {
      margin-right: 0.5rem;
    }

    .icon-notification {
      fill: $white;
      width: 1.2rem;
      height: 1.1rem;
    }

    .unread-notification-count {
      position: absolute;
      display: flex;
      left: 10px;
      top: -13px;
      color: $white;
      font-size: 11px;
      font-weight: 500;
      padding: 0 0.2rem;
      min-width: 1.5rem;
      min-height: 1.5rem;
      justify-content: center;
      align-items: center;
      border: 3px solid $mainBarBaseColor;
      background-color: $white;
      color: $standardText;
      border-radius: 500px;
    }

    @media (max-width: $mobileBreakpoint) {
      .icon-notification {
        width: 1.5rem;
        height: 1.2rem;
      }
    }

    &.with-badge {
      .icon-notification {
        fill: $white;
      }
    }

    &:hover {
      .icon-notification {
        fill: $accent;
        opacity: 1;
      }

      .unread-notification-count {
        background-color: $accent;
      }
    }
  }
}

.notifications-overlay {
  border-radius: $borderRadius;

  &::before {
    display: none;
  }

  .ant-dropdown-menu {
    max-height: calc(100vh - 70px);
    overflow-y: auto;
    margin-top: 0.65rem;
    margin-right: -0.5rem;
  }

  .ant-dropdown-menu-item-divider {
    margin: 0;
  }

  .no-notifications-message {
    color: $neutralDark;
  }

  .notifications-overlay-title {
    pointer-events: none;
    background-color: $white;
    color: $standardText !important;
    border-top-right-radius: $borderRadius;
    border-top-left-radius: $borderRadius;
    padding-bottom: 0.6rem !important;
    border-bottom: 1px solid $border;
    font-size: 0.8rem;
    font-weight: 500;
    letter-spacing: 0.3px;
    position: sticky;
    z-index: 1;

    .ant-dropdown-menu-title-content {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  .notifications-overlay-title-text {
    font-weight: 500;
  }

  .mark-as-read-button {
    pointer-events: all;
  }

  .notification-item {
    display: flex;

    &.unread {
      .notification-item-content {
        .message-and-date,
        .avatar-container {
          opacity: 1 !important;
          filter: unset !important;
        }
      }
    }
  }

  .ant-dropdown-menu-item {
    padding: 0.5rem 0.5rem;

    a {
      display: flex;
      align-items: center;
    }

    .draughthub-logo-no-author {
      width: 27px;
      height: 30px;
    }

    .unread-notification-marker {
      width: 8px;
      height: 8px;
      background-color: $accent;
      border-radius: 50%;
      background: linear-gradient(180deg, lighten($accent, 10) 0%, darken($accent, 10) 100%);
      display: inline-block;
      position: relative;
      top: 1px;
      margin-left: 0.5rem;

      &.hidden {
        opacity: 0;
      }
    }

    .notification-item-content {
      display: flex;
      gap: 0.5rem;
      width: 100%;
      align-items: center;

      .message-and-date,
      .avatar-container {
        opacity: 0.5;
        filter: saturate(0.3);
      }

      .message-and-date {
        display: flex;
        flex-direction: column;
        font-weight: 500;
        color: $standardText;
        max-width: 600px;

        .date {
          font-size: $extraSmallFontSize;
          color: $lightText;
          display: block;
        }
      }

      .delete-notification-button {
        margin-left: auto;
        display: block;
        width: 2rem;
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1;

        .icon {
          margin-right: 0;
        }
      }
    }
  }
}
