@import "../../Variables";
@import "../../ReportPage/reportHelpers.scss";

.form-editor {
  padding-bottom: 3.2rem;
  position: fixed;
  top: $navigationBarHeight;
  width: 30rem;
  height: 100%;
  right: 0;
  transition: all 400ms ease-in-out;
  z-index: 2;

  &.invisible {
    right: -30rem;
    pointer-events: none;
  }

  .user-input-container {
    height: 100% !important;
    justify-content: center !important;
    flex-direction: row !important;
  }

  .form-fields-card {
    box-shadow: unset !important;
    max-width: 30rem;
    overflow-x: hidden;
    max-height: unset !important;
    border-radius: 0 !important;
    border-right: unset !important;
    border-top: unset !important;
    height: 100% !important;

    .form {
      margin: 0;
      padding: 0;
    }

    .back-button {
      margin-right: 1rem;
    }

    & > .ant-card-body {
      max-height: 100%;
      display: flex;
      flex-direction: column;
      box-shadow: unset !important;
      padding-right: 0;
    }

    .fields {
      height: 100%;
      overflow-y: auto;
      overflow-x: hidden;

      button,
      [class^="ant-"] {
        // opacity: 0.5;
        pointer-events: none;
      }
    }
  }
}

.form-field-edit-overlay {
  position: absolute;
  top: 0rem;
  left: 0;
  width: calc(100% - 2px) !important;
  height: 100%;

  z-index: 20;
  opacity: 0;
  transition: opacity 300ms;
  padding: 0;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $white;
    opacity: 0.85;
    border-radius: $borderRadius;
    border: 1px dashed $accent;
    z-index: 2;
    pointer-events: none;
  }

  .overlay-buttons-container {
    width: 100%;
    height: 100%;
    max-height: 100px;
    padding-top: 0.5rem;

    display: flex;
    gap: 0.5rem;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
  }

  button {
    z-index: 2;
    position: relative;
    opacity: 1 !important;
    pointer-events: all !important;

    .anticon {
      position: relative;
      z-index: 2;
    }
  }

  &:hover {
    opacity: 1;
  }
}
