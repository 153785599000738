@import "../Variables";

.attachments {
  position: relative;

  .card-header .ant-divider {
    display: none;
  }

  .copy-link-button {
    margin-left: 0.5rem;
    margin-right: 0.2rem;
    svg {
      fill: $lightText;
    }
  }

  .attachments-search-bar {
    margin-top: 1rem;
    input {
      font-weight: 500;
    }
  }

  .select {
    width: 2rem;
    flex-shrink: 0;

    &.not-visible {
      visibility: hidden;
      pointer-events: none;
    }
  }

  .pdf-preview {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 3;
    display: flex;
    justify-content: center;
    align-items: top;
    overflow-y: auto;

    .pdf-renderer {
      min-width: $reportWidth;
      width: unset;

      .react-pdf__Document {
        max-width: 90vw;
        max-height: 100vh;
        overflow: auto;
      }
    }

    .modal-icons {
      position: absolute;
      right: 1rem;
      top: 0.5rem;
      font-size: 1.5rem;
      display: flex;
      align-items: center;
      color: $standardText;
      z-index: 2;

      .icon {
        margin-left: 1rem;
        background-color: white;
        border-radius: 4px;
        border-radius: $borderRadius;
        cursor: pointer;

        &:hover {
          opacity: 0.8;
        }
      }
    }

    .pdf-preview-background {
      background-color: rgba(0, 0, 0, 0.8);
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 0;
    }

    .react-pdf__Page__svg {
      box-shadow: unset;
    }
  }

  &.is-in-modal .card {
    border: unset !important;
    box-shadow: unset !important;
    margin-bottom: 0 !important;
    width: calc(100% + 0.5rem) !important;

    .ant-card-body {
      padding: 0 !important;
    }

    .attachments-container.view-type-list {
      max-height: calc(100vh - 450px);
    }
  }

  .card {
    margin-bottom: 1rem !important;

    .ant-card-body {
      padding-right: 0.4rem;
    }

    .card-header {
      .ant-space-item:first-child {
        width: calc(100% - 250px);
      }
    }

    .card-header .ant-space-item:first-child {
      width: 100%;
      margin-right: 0 !important;
    }

    .no-items-in-folder {
      margin: 0 auto;

      .no-attachments-message {
        margin-top: -0.2rem;
        margin-bottom: -0.5rem;
        display: block;
        color: $neutralDark;
      }
    }

    .ant-breadcrumb-link {
      color: $lightText;
    }

    .actions-container {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      gap: 0.5rem;
      justify-content: flex-start;

      button.move-to-trash,
      button.download {
        padding-left: 0.5rem !important;
        padding-right: 0.5rem !important;
      }

      span.move-to-trash,
      span.download {
        button {
          padding-left: 0.5rem !important;
          padding-right: 0.5rem !important;
        }
      }

      .create-folder {
        margin-left: auto !important;

        @media (max-width: 600px) {
          margin-left: unset !important;
        }
      }

      .download {
        margin-right: 0.5rem;
      }
    }

    .attachments-breadcrumbs {
      margin-top: 1rem;
      margin-bottom: 0rem;
      margin-left: -0.4rem;
      overflow-y: hidden;

      ol {
        flex-wrap: wrap;

        & > * {
          flex-shrink: 0;
        }
      }

      ol > li:nth-child(1) {
        margin-left: -0.2rem;
      }

      &.ant-breadcrumb > span:last-child .ant-breadcrumb-separator {
        display: inline-block !important;
      }

      .ant-breadcrumb-link {
        cursor: pointer;
        font-size: 0.8rem;
        padding: 0.2rem 0.4rem;
        display: inline-block;

        &:hover {
          background-color: $hover;
        }
      }

      &.is-trash-open {
        * {
          color: $bad;
        }
      }
    }

    .preloader-container {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 150px;

      .preloader {
        color: $accent;
        font-size: 2rem;
      }
    }

    // .attachments-container.view-type-grid {
    //   .attachment-item {
    //     display: block;
    //     height: 10rem;
    //     box-sizing: border-box;
    //     padding: 0.5rem !important;
    //     position: relative;
    //     border: 1px solid transparent;
    //     border-radius: $borderRadius;
    //     cursor: pointer;
    //     transition: all 200ms;
    //     transition-delay: unset;

    //     &:hover {
    //       .attachment-content {
    //         filter: brightness(0.7) saturate(1.5);
    //       }
    //     }

    //     .attachment-content {
    //       display: block;
    //       width: 100%;
    //       height: 100%;
    //       border: 1px solid $neutral;
    //       border-radius: $borderRadius;
    //       background-color: $background;
    //       position: relative;
    //       background-size: cover;

    //       .name {
    //         position: absolute;
    //         word-wrap: break-word;
    //         top: 1rem;
    //         width: calc(100% - 2rem);
    //         left: 1rem;
    //         font-size: 0.8rem;
    //         color: darken($standardText, 3);
    //         font-weight: 500;
    //       }

    //       .updated-at {
    //         position: absolute;
    //         word-wrap: break-word;
    //         bottom: 1.3rem;
    //         width: calc(100% - 2rem);
    //         left: 1rem;
    //         font-size: 0.8rem;
    //         color: $lightText;
    //         display: inline-block;
    //         width: 6rem;
    //       }

    //       .name,
    //       .updated-at {
    //         color: darken($standardText, 3);
    //         border-radius: $borderRadius;
    //         padding: 0.4rem;
    //         font-weight: 500;
    //       }

    //       .updated-at {
    //         width: 110px;
    //       }
    //     }

    //     &.with-image {
    //       .name,
    //       .created-at {
    //         background-color: rgba($white, 0.7);
    //       }

    //       .delete-attachment {
    //         background-color: rgba($white, 0.7);
    //         // border-color: $standardText;
    //       }
    //     }
    //   }
    // }

    .attachments-container.view-type-list {
      max-height: calc(100vh - 250px);
      min-height: 250px;
      overflow-y: auto;
      overflow-x: auto;
      padding-right: 1rem;

      & > * {
        min-width: 600px;
      }

      .attachment-list-header.list-headers {
        position: sticky;
        top: 0;
        background-color: $white;
        z-index: 1;
        border: unset !important;
        padding-top: 0.8rem;
        padding-bottom: 0.2rem;
        cursor: default !important;

        &:hover {
          background-color: $white !important;
        }

        .attachment-content,
        .ant-checkbox-wrapper {
          z-index: 1;
        }

        .ant-checkbox-wrapper {
          position: relative;
          top: -2px;
        }

        .ant-typography {
          text-transform: uppercase;
          font-size: 0.65rem !important;
          color: $lightText !important;
          font-weight: 500;
          display: block;

          &.select {
            width: 2rem;
            flex-shrink: 0;
          }
        }
      }

      .attachment-item,
      .attachment-list-header {
        margin-left: -0.5rem;
        margin-right: -0.5rem;
        padding: 0.3rem 0.5rem;
        position: relative;
        color: $standardText;
        background-color: transparent;
        cursor: pointer;
        transition: all 300ms;
        font-size: 0.8rem;

        .file-type-icon {
          margin-right: 0.5rem;
          font-size: 1rem;
          color: $accent;
        }

        .attachment-content {
          display: flex;
          align-items: center;
          transition: all 300ms;
          gap: 0.5rem;

          .ant-checkbox-wrapper {
            margin-right: 1rem;

            @media (max-width: 600px) {
              margin-right: 0.5rem;
            }
          }

          .name {
            width: 100%;
            transition: all 300ms;
            color: $standardText;
            display: flex;
            align-items: center;
            word-break: break-all;
            line-height: 1.2;

            &.type-folder {
              font-weight: 500;
            }
          }

          .updated-at,
          .created-at {
            color: $standardText;
            width: 7.5rem;
            text-align: right;
            flex-shrink: 0;
          }

          .file-size {
            width: 4rem;
            text-align: right;

            color: $standardText;
          }

          .ant-result {
            width: 100%;
          }
        }

        &:hover {
          background-color: $hover;

          .attachment-content {
            color: $accent;
          }

          .name {
            color: $accent;
          }
        }

        .delete-attachment {
          position: static;
          left: unset;
          top: unset;
          border: 0;
          transition: all 300ms;

          .anticon {
            color: $lightText !important;
          }

          &:hover {
            background-color: $hover;

            .anticon {
              color: $accent !important;
            }
          }
        }
      }
    }

    .attachment-view-selector {
      display: inline-block;
      margin-left: 0.5rem;

      .anticon {
        display: inline-block;
        color: $accent;
        padding: 0.5rem 0.8rem;
        cursor: pointer;
        border-top: 1px solid $neutral;
        border-bottom: 1px solid $neutral;
        transition: all 300ms;
        background-color: transparent;

        &:first-child {
          border-top-left-radius: 6px;
          border-bottom-left-radius: 6px;
          border-left: 1px solid $neutral;
        }

        &:last-child {
          border-top-right-radius: 6px;
          border-bottom-right-radius: 6px;
          border-right: 1px solid $neutral;
        }

        &:not(:last-child) {
          border-right: 1px solid $neutral;
        }

        &.active {
          background-color: $accent;
          color: $white;
          border-color: $accent;
        }

        &:hover {
          border-color: $accent;
          color: lighten($accent, 10);

          &.active {
            background-color: saturate(lighten($accent, 15), 50);
            border-color: saturate(lighten($accent, 15), 50);
            color: $white;
          }
        }
      }
    }
  }

  .download-attachment-modal {
    .ant-modal-confirm-btns {
      margin-top: -1.5rem !important;
      .ant-btn-primary {
        display: none;
      }
    }

    .action-buttons {
      margin-top: 1rem;
      justify-content: flex-end;
      display: flex;
      position: relative;
      top: 0.5rem;
      margin-right: 5rem;

      button {
        margin-left: 0.5rem;
      }
    }
  }

  .unsupported-attachment-warning {
    margin-top: 1rem;
  }

  &.presentation-only {
    border: unset !important;

    .attachments-container {
      min-height: unset !important;
    }
    .actions-container,
    .list-headers,
    .attachments-breadcrumbs,
    .select,
    .file-size,
    .updated-at,
    .created-at {
      display: none;
    }

    .attachment-item {
      border-radius: $borderRadius;
      margin-left: 0 !important;
    }
  }
}

.restore-from-trash {
  color: $accent !important;

  &.ant-dropdown-menu-item-disabled {
    color: #c5dbe4 !important;
    font-weight: unset !important;
  }
}
