@import "../Variables";

.fee-list {
  width: 1100px !important;
  max-width: 100% !important;
  margin: 0 auto !important;

  .fee {
    display: block;
    border: 1px solid $neutral;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0.3rem 1rem;
    cursor: pointer;

    .value {
      text-align: left;
      font-size: 0.8rem;
      font-weight: 500;
    }

    .label,
    .override {
      font-size: 0.8rem;
      font-weight: 500;
      width: 7rem;
      flex-shrink: 0;
      text-align: center;
      position: relative;
      top: 1px;
      margin-right: 1rem;
    }

    .override {
      color: $bad;
    }

    &:hover {
      background-color: $hover;
    }

    &:not(:last-child) {
      margin-bottom: -1px;
    }

    .delete-fee,
    .edit-fee {
      border: unset;
      background: unset;
      box-shadow: unset;
    }

    .edit-fee {
      margin-left: auto;
    }
  }
}
